export const requireField = (value, type) => type === 'email' ? !value : !value.trim()

export const validateEmail = (email) => {
  const pattern = new RegExp(
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
  );
  let isValid = false;
  if (requireField(email, 'email')) {
    isValid = false;
  } else if (!pattern.test(email)) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

export const validatePhoneNumber = (value) => {
  let isValid = false;
  let phoneNumber = value;
  const pattern = new RegExp(
    /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
  );

  let val = phoneNumber.replace(/\D/g, '');
  val = val.replace(/^(\d{3})(\d{1,2})/, '$1-$2');

  val = val.replace(/^(\d{3})-(\d{3})(.+)/, '($1) $2-$3');
  phoneNumber = val.substring(0, 11);

  if (requireField(phoneNumber)) {
    isValid = false;
  } else if (!pattern.test(val)) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

export const formatPhoneNumber = (phoneNumber) => {
  let val = phoneNumber.replace(/\D/g, '');

  val = val.replace(/^(\d{3})(\d{1,2})/, '$1-$2');
  val = val.replace(/^(\d{3})-(\d{3})(.+)/, '($1) $2-$3');
  return val;
};


export function detectEllipsis(element) {
  const e = element.current || {}
  return (e.offsetHeight < e.scrollHeight);
}