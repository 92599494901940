import React, { useContext } from 'react';
import TripDetailsContext from '../state/context/TripDetailsContext';
import LiveCalendar from '../live-calendar-level-1/components/live-calendar/Level1';
import LargeRateCards from '../rate-cards-widget/components/large';
import NarrowRateCards from '../rate-cards-widget/components/narrow';
import { Typography } from '@material-ui/core';

export default function LiveCalendarLevel2(props) {
  const tripDetailsContext = useContext(TripDetailsContext);
  const isNarrow = /Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 834 || ((screen || {}).width || 0) <= 834
  const { step } = tripDetailsContext;
  return (
    <>
      <div
        style={{
          display: step === 'trip-details' ? 'block' : 'none',
        }}
      >
        <Typography variant="h4" component="div" align="center" style={{ margin: '0 auto' }}>Scroll to View All Rates</Typography>
        <LiveCalendar {...props} center />
      </div>
      {isNarrow ? <NarrowRateCards {...props} /> : <LargeRateCards {...props} />}
    </>
  );
}
