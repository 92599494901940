import moment from 'moment';
import { getBookingAcceptanceDay } from '../../../../utils';

const getIsBetween = (dateFrom, dateTo, dateCheck) => {
  var d1 = dateFrom.split("/");
  var d2 = dateTo.split("/");
  var c = dateCheck.split("/");

  var from = new Date(c[2], parseInt(d1[1]) - 1, d1[0]);  // -1 because months are from 0 to 11
  var to = new Date(c[2], parseInt(d2[1]) - 1, d2[0]);
  var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

  return check >= from && check <= to
}

export const getAvailability = (data) => {
  const scheduleDisable = [];
  const availability = [];
  const tomorrow = moment(new Date()).add(getBookingAcceptanceDay(), "days").format('YYYY/MM/DD')
  data?.forEach((item) => {
    const z = item.date.replace(/-/g, ', ');
    const tmpDate = moment(z, 'YYYY,MM,DD').toDate();
    const tomorrow = moment(new Date()).add(getBookingAcceptanceDay(), "days").format('YYYY/MM/DD')
    /* if (item.reasoncode === 'ALL') {
      availability.push(tmpDate);
    } */
    if (moment(tmpDate).isSameOrAfter(tomorrow) && item.available_rates.length > 0) {
      availability.push(tmpDate);
    } else {
      scheduleDisable.push(tmpDate)
    }
  });
  return { availability, scheduleDisable };
};

export const getAvailabilityScheduledForAllRateCards = ({availableSchedules}) => {
  const scheduleDisable = [];
  const availability = [];
  const tomorrow = moment(new Date()).add(getBookingAcceptanceDay(), "days").format('YYYY/MM/DD')
  availableSchedules.forEach((item) => {
    const z = item.date.replace(/-/g, ', ');
    const tmpDate = moment(z, 'YYYY,MM,DD').toDate();
    var itemDate = moment(z, 'YYYY,MM,DD').format('YYYY/MM/DD')
    if (item.available_rates.length > 0&& moment(itemDate).isSameOrAfter(tomorrow)) {
        availability.push(tmpDate); 
    }else{
      scheduleDisable.push(tmpDate)
    }
  });
  return { availability, scheduleDisable };
};

export const getAvailabilityBasedOnSelectedRateCard = ({ availableSchedules, hourCard, rateCard }) => {
  const scheduleDisable = [];
  const availability = [];
  const tomorrow = moment(new Date()).add(getBookingAcceptanceDay(), "days").format('YYYY/MM/DD')
  availableSchedules.forEach((item) => {
    const z = item.date.replace(/-/g, ', ');
    const tmpDate = moment(z, 'YYYY,MM,DD').toDate();
    var itemDate = moment(z, 'YYYY,MM,DD').format('YYYY/MM/DD')
    /* if (item.reasoncode === 'ALL') {
      availability.push(tmpDate);
    } */
    if (item.available_rates.length > 0) {
      const found = item.available_rates.filter(rate => {
        if (!rateCard) {
          if (hourCard.guide_ratecard_id === rate?.guide_ratecard_id && rate.id === hourCard.id && moment(itemDate).isSameOrAfter(tomorrow)) {
            return true
          }
        } else {
          if (Number(rateCard.id) === rate?.guide_ratecard_id && moment(itemDate).isSameOrAfter(tomorrow)) {
            return true
          }
        }
        return false
      })
      if (found.length > 0) {
        availability.push(tmpDate);
      } else {
        scheduleDisable.push(tmpDate)
      }
    }else{
      scheduleDisable.push(tmpDate)
    }
  });
  return { availability, scheduleDisable };
}

export const handleDateSelect = (data, schedule, hourcard) => {
  const availability = []
  const tomorrow = moment(new Date()).add(getBookingAcceptanceDay(), "days").format('YYYY/MM/DD')
  for (let item of schedule) {
    let z = item.date.replace(/-/g, ", ")
    let tmpDate = moment(z, "YYYY,MM,DD").toDate()
    var itemDate = moment(z, 'YYYY,MM,DD').format('YYYY/MM/DD')
    let found
    if (hourcard) {
      found = item.available_rates.some(rec => rec.id === Number(hourcard.id) && moment(itemDate).isSameOrAfter(tomorrow))
    } else {
      found = item.available_rates.some(rec => rec.guide_ratecard_id === Number(data.id) && moment(itemDate).isSameOrAfter(tomorrow))
    }
    if (found) {
      availability.push(tmpDate)
    }
  }
  return { availability }
}

export const getRateCards = (allRateCards, schedule, type, hour) => {
  const availableRateCards = allRateCards.filter((rateCard) => {
    if (rateCard.is_seasonal) {
      /* get the moment in between dates due to future dates */
      const currentScheduleDate = moment(schedule.date).format('YYYY/MM/DD');
      const startDate = moment(rateCard.startdate).format('YYYY/MM/DD');
      const endDate = moment(rateCard.enddate).format('YYYY/MM/DD');
      const isBetween = getIsBetween(startDate, endDate, currentScheduleDate);
      return isBetween
    }
    return rateCard;
  })
  const rateCardIds = (schedule.available_rates || []).map(
    ({ guide_ratecard_id }) => guide_ratecard_id,
  );
  const uniqueRateCardIds = rateCardIds.filter(
    (v, i, a) => a.indexOf(v) === i,
  );
  const scheduleAvailableRateCards = uniqueRateCardIds.map((id) => {
    let rates = (schedule.available_rates || []).filter(
      (item) => {
        let rate = parseInt(item.guide_ratecard_id, 10) === parseInt(id, 10);
        if (hour) {
          rate = parseInt(item.guide_ratecard_id, 10) === parseInt(id, 10)
            && item.duration_hours === parseInt(hour, 10);
        }
        if (hour === 'custom') {
          rate = parseInt(item.guide_ratecard_id, 10) === parseInt(id, 10)
            && item.is_custom_rate;
        }
        return rate;
      },
    );
    rates = rates.sort((a, b) => a.rate - b.rate);
    return {
      id,
      rates,
    };
  });
  let rateCards = scheduleAvailableRateCards.map(
    ({ id, rates }) => {
      const rateCard = availableRateCards.find(
        (availableRateCard) => parseInt(availableRateCard.id, 10) === parseInt(id, 10),
      );
      if (rateCard && rateCard.rates) {
        delete rateCard.rates;
      }
      return { ...rateCard, rates, scheduleId: schedule.scheduleid };
    },
  );
  if (type) {
    rateCards = rateCards.filter(({ ratetype }) => ratetype.toLowerCase() === type.toLowerCase());
  }
  rateCards = rateCards.filter(({ rates, id }) => rates.length > 0 && id);
  return rateCards;
};

export const getInitialRateCards = (allRateCards, schedule = {}, live) => {
  const rateCards = live ? getRateCards(allRateCards, schedule) : getAllRateCardsWithDisabled(allRateCards, schedule)
  if (rateCards.length > 0) {
    return rateCards
  } else {
    return allRateCards.map(rateCard => ({ ...rateCard, disabled: true }))
  }
};

export const getAllRateCardsWithDisabled = (allRateCards, schedule = {}, type, hour) => {
  const rateCardIds = (schedule?.available_rates || [])?.map(
    ({ id }) => id,
  );
  const uniqueRateCardIds = rateCardIds.filter(
    (v, i, a) => a.indexOf(v) === i,
  );
  let rateCards = allRateCards.map((rateCard) => {
    rateCard.rates.map((rate) => {
      rate.disabled = !(uniqueRateCardIds.includes(rate.id));
      return rate;
    });
    rateCard.rates = rateCard.rates.sort((a, b) => a.rate - b.rate);
    const disabledRates = rateCard.rates.filter(a => a.disabled);
    const notDisabledRates = rateCard.rates.filter(a => !a.disabled);
    rateCard.rates = [...notDisabledRates, ...disabledRates];
    rateCard.scheduleId = schedule.scheduleid;
    return rateCard;
  });
  if (type) {
    rateCards = rateCards.filter(({ ratetype }) => ratetype.toLowerCase() === type.toLowerCase());
  }
  rateCards = rateCards.map((rateCard) => {
    rateCard.disabled = rateCard.rates.every(rate => rate.disabled);
    /* get the moment in between dates due to future dates */
    if (rateCard.is_seasonal) {
      const currentScheduleDate = moment(schedule.date).format('YYYY/MM/DD');
      const startDate = moment(rateCard.startdate).format('YYYY/MM/DD');
      const endDate = moment(rateCard.enddate).format('YYYY/MM/DD');
      const isBetween = getIsBetween(startDate, endDate, currentScheduleDate);
      // rateCard.disabled = !(isBetween) || rateCard.disabled;
    }
    return rateCard;
  })
  rateCards = rateCards.filter(({ rates, id }) => rates.length > 0 && id);
  return rateCards;
};

export default getAvailability;
