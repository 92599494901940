import React, { useState, useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import DayPicker from '../../../common/daypicker/DayPicker'
import getSymbolFromCurrency from 'currency-symbol-map'
import Tooltip from '@material-ui/core/Tooltip';
const InfoIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/black_info.png'
import moment from 'moment';
import GreenRadio from '../../../common/Radio';
import convertTo12Hour, { getDuration, getTimeFormat } from '../../../utils/time';
import { displayMoney } from '../../../utils/payment';
import useStyes from './styles';
import './styles.scss';
import { TYPES } from '../../../common/description'
import { getAllRateCardsWithDisabled, getRateCards, handleDateSelect, getMaxCapacity,TripType, getAvailabilityBasedOnSelectedRateCard } from '../../../utils/guide-details';
import { CircularProgress, styled } from '@material-ui/core';
import GuestCount from '../../../common/filters/GuestCount';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TripCharge from '../../../common/TripCharge';
import RateCardsSelect from '../../../live-calendar-level-1/components/rate-cards/rate-cards-select';
import axios from "axios";
import { getVehicleDetails } from '../narrow/rate-cards';
import { getAvailabilityScheduledForAllRateCards } from '../../../common/personal-details/guide-details';

const additionalChargeIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/images/marlin/24hrs-01.svg';
const boatIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/images/marlin/private-boat-01-01.svg';
const experienceIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/images/marlin/pin_waterbodies-01.svg';
const targetedFishIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/images/marlin/target-fish-01.svg';
const locationPin = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/rcLoc.svg';
const huntingIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/hunting.png';
const checked = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/checked.svg';
const unchecked = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/uncheck.svg';


const RateCards = ({
  handleDayClick,
  rateCards,
  selectedHour,
  handleClick,
  selectedRateCard,
  instantBook,
  selectedDay,
  guestCount,
  setShowCalendar,
  modifiers,
  setModifiers,
  setGuestCountHandler,
  disabled,
  disabledClear,
  disabledDate,
  capacity,
  fromMonth,
  toMonth,
  month,
  setSelectedRateCard,
  setSelectedHour,
  setSelectedDay,
  setRateCards,
  modifiersStyles,
  showCalendar,
  availableSchedules,
  stateRateCards,
  setStep,
  setIsGuestCountMaxed,
  isGuestCountMaxed,
  template,
  rivers,
  isMultiTenantGuide,
  companyGuid,
  setBSAvailability,
  setSelectionType,
  availableSpecies,
  setReferralGuideName,
  referral,
  schrecords,
  setCompanyDetails,userData
}) => {
  const classes = useStyes();
  const [initialDisabled, setInitialDisabled] = useState(false)
  const [readMoreRateCard, setReadMoreRateCard] = useState([]);
  const [expanded, setExpanded] = React.useState('');
  const tripsummary = useRef(null)
  const isBrowser = typeof window !== `undefined`
  const isNarrow = isBrowser && (/Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 768 || ((window.screen || {}).width || 0) <= 768)
  const [showDesc, setShowDec] = useState(isNarrow ? false : true)
  const [cardID, setCardId] = useState('')
  const checkCompany = isMultiTenantGuide && companyGuid && companyGuid != ""
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const checked = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/checked.svg';
  const unchecked = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/uncheck.svg';
  useEffect(() => {
    if (guestCount > 1) {
      setInitialDisabled(true)
    }
  }, [guestCount])

  const handleHour = (item, record) => {
    setSelectedRateCard(record);
    setSelectedHour(item);
  };

  const clearFilter = () => {
    setGuestCountHandler(1);
    setSelectedDay('');
    setSelectedHour('');
    setSelectedRateCard('');
  }

  const list = selectedDay ? rateCards.filter(item => !item.disabled) : rateCards;
  const getBoatDetail = (rateCard) => {
    return (
      <div style={{ fontSize: '0.875rem' }}>
        <img
          className={classes.icon}
          src={boatIcon}
          alt="boat"
          width="20"
          height="20"
        />
        <span className={classes.ratesubtitle} style={{ textTransform: "capitalize", }}>
          {rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type}:{' '}
          <span className={classes.bold}>
            {rateCard.length}{rateCard.length? "' ":null}{(rateCard.manufacturer_name !="Other" && rateCard.manufacturer_name !="Others")?rateCard.manufacturer_name: rateCard.other_manufacturer}{' - '}
            Max {rateCard.maximum} guests
          </span>
        </span>
        
      </div>
    )
  }
  const getCapacityDetail = (rateCard) => {
    return (
      <div style={{ fontSize: '0.875rem' }}>
        <img
          className={classes.icon}
          src={boatIcon}
          alt="boat"
          width="20"
          height="20"
        />
        <span className={classes.ratesubtitle}>
        Capacity:{' '}
        <span className={classes.bold}>
        Max {getMaxCapacity(rateCard)} {getMaxCapacity(rateCard) > 1 ? 'guests' : 'guest'}
                                  {" (base trip price includes "}
                                  {rateCard.guests}
                                  {rateCard.guests > 1 ? " guests)" : " guest)"}
        </span>
      </span>
      </div>
    )
  }

  const getReferralName = (rateCard) => {
    if(rateCard?.is_group) {
    return (referral?.filter(x => x.group_guid == rateCard.guideguid))?.[0]?.company;
    } else {
      return (referral?.filter(x => x.guideguid == rateCard.guideguid))?.[0]?.guidename;
    }
  
}
const getReferralTitle = (rateCard) => {
    if(rateCard?.is_group) {
    return (referral?.filter(x => x.group_guid == rateCard.guideguid))?.[0]?.title
    } else {
      return (referral?.filter(x => x.guideguid == rateCard.guideguid))?.[0]?.title
    }
}
  const displayBoat = (rateCard) => {
    const checkCompany = isMultiTenantGuide && companyGuid && companyGuid != ""
    if (rateCard) {
      return (
        <>
          {!isMultiTenantGuide  &&
            getBoatDetail(rateCard)
          }
          {checkCompany && rateCard.boats &&
            rateCard.boats.length === 1 &&
            getBoatDetail(rateCard)
          }
          {checkCompany && rateCard.boats &&
            rateCard.boats.length > 1 &&
            <div style={{ fontSize: '0.875rem' }}>
              <img
                className={classes.icon}
                src={boatIcon}
                alt="boat"
                width="20"
                height="20"
              />
              <span className={classes.bold}>
                {rateCard.boats.length} boats available
              </span>
            </div>
          }
        </>
      )
    }
  }
  const getAvailTimes = (rateCard, item, i) => {
    return (
      <div
        key={i}
        style={{
          display: 'flex', borderBottom: "1px solid #42642f",
          width: "100%",
          margin: "5px 0px", padding: "10px 5px", justifyContent: 'space-between'
        }}
        className="innercontainer"
      >
        <div style={{ display: 'flex' }}>
          <img className={selectedHour.id === item.id ? checked : unchecked}
            onClick={() => {
              if (selectedDay) {
                const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard })
                setModifiers({
                  ...modifiers,
                  availability,
                  scheduleDisable
                });
                setSelectedHour(item)
                setExpanded('')
              } else {
                const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard })
                setModifiers({
                  ...modifiers,
                  availability,
                  scheduleDisable
                });
                setSelectedHour(item)
                setShowCalendar({ rateCard, selectedHour: item, hourcard: true })
                setExpanded('tripdate')
              }
            }}
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 0,
              marginRight: 10,
              height: 20, width: 20, border: '1px solid grey'
            }} width="20" height="20"/>
          <Typography
            onClick={() => {
              if (selectedDay) {
                const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard })
                setModifiers({
                  ...modifiers,
                  availability,
                  scheduleDisable
                });
                setSelectedHour(item)
                setExpanded('')
                setSelectedRateCard(rateCard)
              } else {
                const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard })
                setModifiers({
                  ...modifiers,
                  availability,
                  scheduleDisable
                });
                setSelectedHour(item)
                setSelectedRateCard(rateCard)
                setShowCalendar({ rateCard, selectedHour: item, hourcard: true })
                setExpanded('tripdate')
              }
            }}
            style={{ cursor: 'pointer' }}
            component="div" variant="body2">
            {convertTo12Hour(item.start_time)} {' for '} {getDuration(item)}
          </Typography>
        </div>
        <Typography
          onClick={() => {
            if (selectedDay) {
              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard })
                setModifiers({
                  ...modifiers,
                  availability,
                  scheduleDisable
                });
              setSelectedHour(item)
              setExpanded('')
            } else {
              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item, rateCard: selectedRateCard })
              setModifiers({
                ...modifiers,
                availability,
                scheduleDisable
              });
              setSelectedHour(item)
              setShowCalendar({ rateCard: selectedRateCard, selectedHour: item, hourcard: true })
              setExpanded('tripdate')
            }
          }}
          style={{ cursor: 'pointer', fontWeight: 'bolder' }}
          component="div" variant="body2">
          {`${(getSymbolFromCurrency(userData?.currency) || '$')}${displayMoney(item.rate)}`}
        </Typography>
      </div>
    )
  }
  const handleNextPage = () => {
    if (isMultiTenantGuide && companyGuid && companyGuid != "" && !selectedRateCard?.is_referral) {
      const date = selectedDay && moment(selectedDay).format("YYYY-MM-DD")
      axios.get(`${apiHost}guide-group/${companyGuid}/availability?date=${date}&ratecard_id=${selectedHour.guide_ratecard_id}&rateid=${selectedHour.id}`)
        .then(response => {
          setBSAvailability(response.data)
          setCompanyDetails(response.data?.group_details)
          const { guides, vehicles, settings } = response.data
          if(!settings?.choose_vehicle && !settings?.choose_guide){
            setSelectionType(null)
            setStep('personal-details')
          }else if (guides?.length === 1 && vehicles.length <= 1) {
            setSelectionType({
              "type": "Guide",
              "guid": guides[0].guideguid,
              "name": guides[0].guidename,
              "data": guides[0]
            })
            setStep('personal-details')
          } else if (vehicles?.length === 1 && guides.length <= 1) {
            setSelectionType({
              "type": "Boat",
              "guid": vehicles[0].guid,
              "name": vehicles[0].name,
              "data": vehicles[0]
            })
            setStep('personal-details')
          } else {
            setStep('selection')
          }
        })
        .catch(err => console.log(err))
    } else {
      setStep('personal-details')
    }
  }
  return (
    <Grid container spacing={2} id="rate-cards-large-n" className={'mobileFlex'}>
      <Grid item sm={12} xs={12} md={8} lg={8}>
        {list.length ? rateCards.map((rateCard, index) => {
          const allDisabled = rateCard.rates.every(item => item.disabled)
          return (
            <Grid container key={`large-rate-card-${rateCard.id}-${index}`} className={classes.rateCardContainer} style={{ marginTop: 10, marginBottom: 10, borderRadius: 5 }}>
              <Grid style={{ padding: 15 }} item xs={12} sm={12} md={template ? 4 : 6} lg={template ? 4 : 6}>
                <CardMedia
                  className={classes.media}
                  image={`${cloudfront}${rateCard.coverpicture}`} // eslint-disable-line no-undef
                />
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={`${classes.instantBookContainer}`}
                >
                  <Grid container>
                    <Grid item md={12} xs={12} className={classes.spacing}>
                      {
                        template ?
                          <Typography
                            variant="body2"
                            component="div"
                            className={classes.availableTrip}
                          >
                            Available Trips
                      </Typography> :
                          <Typography
                            variant="body2"
                            component="div"
                            className={classes.bold}
                          >
                            Available Trips
                      </Typography>
                      }
                      <Grid style={{ marginTop: 5 }} item>
                        {rateCard.rates
                          .filter((item) => !item.disabled)
                          .map((item) => (
                            <Typography
                              variant="body2"
                              component="div"
                              key={`available-trip-${item.id}`}
                            >
                              <img onClick={() => {
                                if (selectedDay && !item.disabled) {
                                  handleClick(item, rateCard)
                                } else {
                                  handleClick(item, rateCard)
                                  tripsummary.current.scrollIntoView()
                                  const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item })
                                  setModifiers({
                                    ...modifiers,
                                    availability,
                                    scheduleDisable,
                                  });
                                  setExpanded('tripdate')
                                  setSelectedDay('')
                                }
                              }} style={{
                                margin: 0,
                                padding: 0,
                                marginRight: 10,
                                borderRadius: 0,
                                position: 'relative', top: 2, cursor: 'pointer',
                                height: 20, width: 20, border: '1px solid grey', borderRadius: 0
                              }} width="20" height="20"
                                src={(selectedHour.id === item.id) ? checked: unchecked}
                              />
                              <span
                                className={`${(item.id === rateCard.rates[0].id &&
                                  rateCard.id !==
                                  selectedRateCard.id) ||
                                  selectedHour.id === item.id
                                  ? classes.selected
                                  : ''
                                  }`}
                                style={{ position: 'relative', bottom: 3 }}
                              >
                                {`${convertTo12Hour(
                                  item.start_time,
                                )} ${getDuration(item)
                                  } - ${(getSymbolFromCurrency(userData?.currency) || '$')}${displayMoney(item.rate)}`}
                                  {rateCard.is_shared_trip ? Boolean(item?.max_occurrence) ? ` - ${item?.max_occurrence}  ${item?.max_occurrence > 1 ? "Seats" : "Seat"}` : '1 Seat' : ''}
                              </span>
                            </Typography>
                          ))}
                        {rateCard.rates
                          .filter((item) => item.disabled)
                          .map((item) => (
                            <Typography
                              variant="body2"
                              component="div"
                              key={`available-trip-disabled-${item.id}`}
                            >
                              <img src={(selectedHour.id === item.id) ? checked : unchecked} onClick={() => {
                                if (selectedDay && !item.disabled) {
                                  handleClick(item, rateCard)
                                } else {
                                  handleClick(item, rateCard)
                                  tripsummary.current.scrollIntoView()
                                  const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: item })
                                  setModifiers({
                                    ...modifiers,
                                    availability,
                                    scheduleDisable,
                                  });
                                  setExpanded('tripdate')
                                  setSelectedDay('')
                                }
                              }} style={{
                                margin: 0,
                                padding: 0,
                                borderRadius: 0,
                                marginRight: 10,
                                position: 'relative', top: 2,
                                height: 20, width: 20, border: '1px solid grey',
                                cursor: 'pointer'
                              }} width="20" height="20"/>
                              <span
                                className={`${(item.id === rateCard.rates[0].id &&
                                  rateCard.id !==
                                  selectedRateCard.id) ||
                                  selectedHour.id === item.id
                                  ? classes.selected
                                  : ''
                                  }`}
                                style={{ position: 'relative', bottom: 3 }}
                              >
                                {`${convertTo12Hour(
                                  item.start_time,
                                )} ${getDuration(item)
                                  } - ${(getSymbolFromCurrency(userData?.currency) || '$')}${displayMoney(item.rate)}`}
                                  {rateCard.is_shared_trip ? Boolean(item?.max_occurrence) ? ` - ${item?.max_occurrence}  ${item?.max_occurrence > 1 ? "Seats" : "Seat"}` : '1 Seat' : ''}
                              </span>
                            </Typography>
                          ))}
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} className={classes.spacing1}>
                      <button
                        // disabled={(rateCard.disabled && selectedDay) || (allDisabled && selectedDay)}
                        onClick={() => {
                          const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: {}, rateCard })
                          setModifiers({
                            ...modifiers,
                            availability,
                            scheduleDisable,
                          });
                          setSelectedRateCard(rateCard)
                          if(rateCard?.rates.length ===1){
                            setSelectedHour(rateCard?.rates?.[0])
                          }else{
                            setSelectedHour({})
                          }
                          if (selectedDay) {
                            if (availability.find(item => new Date(item).getTime() === new Date(selectedDay).getTime())) {
                              if(rateCard?.rates.length > 1){
                              setExpanded('triptime')
                              }
                            } else {
                              setExpanded('tripdate')
                              setSelectedDay('')
                            }
                          } else {
                            setExpanded('tripdate')
                          }
                          const element = document.getElementById('trip-summary-section');
                          const yOffset = -95;
                          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                          window.scrollTo({ top: y, behavior: 'smooth' });
                        }}
                        style={{ width: '100%', borderRadius: 5, height: 40, marginLeft: 0, marginBottom: 10 }}
                        className={'bookNowBtn WidgetBtn'}
                      >
                        {`Starting at ${(getSymbolFromCurrency(userData?.currency) || '$')}
                        ${selectedHour.rate &&
                          selectedRateCard.id === rateCard.id
                          ? displayMoney(selectedHour.rate)
                          : displayMoney(rateCard.rates[0].rate)}`}
                      </button>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ padding: 15 }} item xs={12} sm={12} md={template ? 8 : 6} lg={template ? 8 : 6} className={classes.detailsContainer} >
                {
                  template ?
                    <>
                      <Typography
                        variant="h3"
                        component="div"
                        className={`${classes.boldtitle} RatesFontColor`}
                      >
                        {rateCard.title}
                      </Typography>
                      {rateCard.is_referral ?
                      <Typography variant="body2" className={classes.referral}>
                      <div>{`Affiliated ${getReferralTitle(rateCard)} ${getReferralName(rateCard)}`}</div>
                      </Typography>
                      : null}
                      {rateCard.activity_type === 'fishing' && <Typography
                        variant="h3"
                        component="div"
                        className={`${classes.boldtitle} RatesFontColor`}
                        style={{ paddingBottom: '8px', paddingTop: 8 }}
                      >
                        {rateCard.ratetype}<span style={{ position: 'relative', top: "5px", left: '14px' }}>
                          <Tooltip classes={{ tooltip: classes.tooltip }} title={TYPES[rateCard.ratetype.toLowerCase()]}>
                            <img alt="info" src={InfoIcon} width={16} height={16} />
                          </Tooltip>
                        </span>
                      </Typography>}
                    </> :
                    <Typography
                      variant="body2"
                      component="div"
                      className={classes.boldtitle}
                      style={{
                        paddingBottom: 15
                      }}
                    >
                      {rateCard.title} {rateCard.activity_type === 'fishing' && (rateCard.ratetype)}
                      {rateCard.activity_type === 'fishing' && <span style={{ position: 'relative', top: 7 }}>
                        <Tooltip classes={{ tooltip: classes.tooltip }} title={TYPES[rateCard.ratetype.toLowerCase()]}>
                          <span><img src={InfoIcon} width={20} height={20} /></span>
                        </Tooltip>
                      </span>}
                      {rateCard.activity_type === 'hunting' ?
                        <Typography variant="body2" style={{ marginBottom: 10 }}>
                          <div>{`Hunting - ${rateCard.ratetype}`}</div>
                        </Typography>
                        : rateCard.activity_type === 'tours' || rateCard.activity_type === 'snow_sports'|| rateCard.activity_type === 'water_sports' ||rateCard.activity_type === 'horseback_riding' ||rateCard.activity_type === 'cruises' || (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading') ?
                          <Typography variant="body2" style={{ marginBottom: 10 }}>
                            <div>{`${ (rateCard.activity_type === 'cruises'? '':rateCard.ratetype)}`}</div>
                          </Typography>
                          : null
                        }

                      <Typography variant="body2" style={{ marginBottom: 10, textTransform: 'capitalize' }}>
                        {rateCard.activity_type === 'hunting' ? `${rateCard.land_type} Land Hunt Experience` : `Fishing Experience`}
                      </Typography>
                      {rateCard.activity_type === 'hunting' ||rateCard.activity_type === 'snow_sports'|| rateCard.activity_type === 'water_sports' ||rateCard.activity_type === 'horseback_riding' ||rateCard.activity_type === 'cruises' || (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading') && <Typography variant="body2" style={{ marginBottom: 10, textTransform: 'capitalize' }}>
                        {getVehicleDetails([], rateCard)}
                      </Typography>}
                    </Typography>
                }
                <div>
                  {
                    template ?
                      <div>
                        {
                          isNarrow &&
                          <p onClick={() => {
                            setShowDec(!showDesc)
                            setCardId(rateCard.id)
                          }}
                            style={{ margin: '0 auto', textDecoration: 'underline', fontSize: '18px', cursor: 'pointer', textAlign: 'center' }}
                          >{showDesc && cardID === rateCard.id ? 'Show less' : 'Show more'}</p>
                        }
                        {rateCard.activity_type === 'hunting' ?
                          <Typography variant="body2" style={{ marginBottom: 10 }}>
                            <div>{`Hunting - ${rateCard.ratetype}`}</div>
                          </Typography>
                          : rateCard.activity_type === 'tours' || rateCard.activity_type === 'snow_sports'||rateCard.activity_type === 'horseback_riding'  || rateCard.activity_type === 'water_sports'||rateCard.activity_type === 'cruises' || (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading') ?
                            <Typography variant="body2" style={{ marginBottom: 10 }}>
                              <div>{`${ (rateCard.activity_type === 'cruises'? '':rateCard.ratetype)}`}</div>
                            </Typography>
                            : null
                        }

                        <Typography variant="body2" style={{ marginBottom: 10, textTransform: 'capitalize' }}>
                          {rateCard.activity_type === 'hunting' ? `${rateCard.land_type} Land Hunt Experience` : rateCard.activity_type === "tours" ? `Tour Experience`:rateCard?.activity_type === "water_sports" ? `Water Sports Experience` : rateCard.activity_type === "snow_sports" ? `Snow Sports Experience`:rateCard.activity_type === "horseback_riding" ? `Horseback Riding Experience`:(rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading') ? `Off-roading Experience`:rateCard.activity_type === "cruises" ? `Cruises Experience`:`Fishing Experience`}
                        </Typography>
                        {rateCard.activity_type === 'hunting' || rateCard.activity_type === 'snow_sports' || rateCard.activity_type === 'water_sports'||rateCard.activity_type === 'cruises'||rateCard.activity_type === 'horseback_riding' || (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading' ) && <Typography variant="body2" style={{ marginBottom: 10, textTransform: 'capitalize' }}>
                          {getVehicleDetails([], rateCard)}
                        </Typography>}
                        {
                          isNarrow && showDesc && cardID === rateCard.id &&
                          <div>
                            {rateCard.has_boat ? (
                              <Typography
                                variant="h4"
                                component="div"
                                className={classes.subtitle3}
                              >
                                <div style={{ fontSize: '0.875rem', padding: '10px 0px 16px',textTransform:'capitalize' }}>
                                {rateCard.is_shared
                        ? `Shared ${rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type} Experience`
                        : `Private ${rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type} Experience`}
                                </div>
                                {displayBoat(rateCard)}

                              </Typography>
                            ):
                            (
                              <Typography
                                variant="h4"
                                component="div"
                                className={classes.subtitle3}
                              >
                                {getCapacityDetail(rateCard)}

                              </Typography>
                            )}
                            {rateCard.is_auto_add_trip_extension > 0 && (
                              <Typography
                                variant="body2"
                                component="div"
                                className={classes.subtitle3}
                              >
                                <img
                                  className={classes.icon}
                                  src={additionalChargeIcon}
                                  alt="hourly ad on"
                                  width="20"
                                  height="20"
                                />
                                <span className={classes.ratesubtitle}>
                                  Allows for hourly add-ons
                                </span>
                              </Typography>
                            )}
                            {rateCard.fish_species && rateCard.activity_type === 'fishing' && (
                              <Typography
                                variant="body2"
                                component="div"
                                className={classes.subtitle3}
                              >
                                <img
                                  className={classes.icon}
                                  src={targetedFishIcon}
                                  alt="targetted fish"
                                  width="20"
                                  height="20"
                                />
                                <span className={classes.ratesubtitle} style={{ marginLeft: "15" }}>
                                  Target Fish:{' '}
                                  <span className={classes.bold}>
                                    {rateCard.fish_species.map(({ title }) => title.trim('')).join(', ')}
                                  </span>
                                </span>
                              </Typography>
                            )}
                            {rateCard?.poi_guids?.length > 0 && (
                              <Typography
                                variant="body2"
                                component="div"
                                className={classes.subtitle3}
                              >
                                <img
                                  className={classes.icon}
                                  src={experienceIcon}
                                  alt="hourly ad on"
                                  width="20"
                                  height="20"
                                />
                                <span className={classes.ratesubtitle}>
                                  {rateCard?.poi_guids?.length === 1 ?
                                    `${rateCard?.poi_guids?.length}  Meeting location available` :
                                    `${rateCard?.poi_guids?.length}  Meeting locations available`
                                  }
                                </span>
                              </Typography>
                            )}
                            {rateCard.description && (
                              <Typography variant="body2" component="div" className={classes.subtitle2}>
                                {rateCard.description.length <= 180 &&
                                  rateCard.description}
                                {rateCard.description.length > 180 && (
                                  <>
                                    {readMoreRateCard.includes(
                                      rateCard.id,
                                    ) ? (
                                      <>
                                        {rateCard.description}{' '}
                                        <span
                                          className={classes.showMoreLess1}
                                          onClick={() => {
                                            const remove = readMoreRateCard.filter(
                                              (item) =>
                                                item !== rateCard.id,
                                            );
                                            setReadMoreRateCard(remove);
                                          }}
                                        >
                                          Show Less
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {rateCard.description.substr(0, 179)}...{' '}
                                        <span
                                          className={classes.showMoreLess1}
                                          onClick={() => {
                                            setReadMoreRateCard([
                                              ...readMoreRateCard,
                                              rateCard.id,
                                            ]);
                                          }}
                                        >
                                          Show More
                                        </span>
                                      </>
                                    )}
                                  </>
                                )}
                              </Typography>
                            )}
                          </div>
                        }
                        {
                          !isNarrow &&
                          <div>
                            {rateCard.has_boat ? (
                              <Typography
                                variant="h4"
                                component="div"
                                className={classes.subtitle3}
                              >
                                <div style={{ fontSize: '0.875rem', padding: '10px 0px 16px', textTransform:'capitalize' }}>
                                {rateCard.is_shared
                        ? `Shared ${rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type} Experience`
                        : `Private ${rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type} Experience`}
                                </div>
                                {displayBoat(rateCard)}
                              </Typography>
                            ):
                            (
                              <Typography
                                variant="h4"
                                component="div"
                                className={classes.subtitle3}
                              >
                                {getCapacityDetail(rateCard)}

                              </Typography>
                            )}
                            {rateCard.is_auto_add_trip_extension > 0 && (
                              <Typography
                                variant="body2"
                                component="div"
                                className={classes.subtitle3}
                              >
                                <img
                                  className={classes.icon}
                                  src={additionalChargeIcon}
                                  alt="hourly ad on"
                                  width="20"
                                  height="20"
                                />
                                <span className={classes.ratesubtitle}>
                                  Allows for hourly add-ons
                                </span>
                              </Typography>
                            )}
                            {rateCard.fish_species && rateCard.activity_type === 'fishing' && (
                              <Typography
                                variant="body2"
                                component="div"
                                className={classes.subtitle3}
                              >
                                <img
                                  className={classes.icon}
                                  src={targetedFishIcon}
                                  alt="targetted fish"
                                  width="20"
                                  height="20"
                                />
                                <span className={classes.ratesubtitle} style={{ marginLeft: "15" }}>
                                  Target Fish:{' '}
                                  <span className={classes.bold}>
                                    {rateCard.fish_species.map(({ title }) => title.trim('')).join(', ')}
                                  </span>
                                </span>
                              </Typography>
                            )}
                            {rateCard?.poi_guids?.length > 0 && (
                              <Typography
                                variant="body2"
                                component="div"
                                className={classes.subtitle3}
                              >
                                <img
                                  className={classes.icon}
                                  src={experienceIcon}
                                  alt="hourly ad on"
                                  width="20"
                                  height="20"
                                />
                                <span className={classes.ratesubtitle}>
                                  {rateCard?.poi_guids?.length === 1 ?
                                    `${rateCard?.poi_guids?.length}  Meeting location available` :
                                    `${rateCard?.poi_guids?.length}  Meeting locations available`
                                  }
                                </span>
                              </Typography>
                            )}
                            { (["hunting", "tours","snow_sports","cruises","off_roading","offroading","water_sports","horseback_riding"].includes(rateCard.activity_type)) && rateCard.fish_species_ids?.length > 0 && (
                              <Typography
                                variant="h3"
                                className={classes.subtitle3}
                                style={{ fontSize: 14 }}
                              >
                                <img
                                  className={classes.icon}
                                  src={huntingIcon}
                                />
                                <span className={classes.ratesubtitle} >
                                  {rateCard.activity_type === "tours" ||rateCard.activity_type === "snow_sports" || rateCard.activity_type === 'water_sports'||rateCard.activity_type === 'cruises'||rateCard.activity_type === 'horseback_riding' || (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading') ? <span>Target Species:{' '}</span> : <span>Target Animals:{' '}</span>}
                                  <span className={classes.bold}>
                                    {rateCard.fish_species_ids.map(id => availableSpecies.find(item => item.id === id)?.title?.trim('')).join(', ')}
                                  </span>
                                </span>
                              </Typography>
                            )}
                            {rateCard.description && (
                              <Typography variant="body2" component="div" className={classes.subtitle2}>
                                {rateCard.description.length <= 180 &&
                                  rateCard.description}
                                {rateCard.description.length > 180 && (
                                  <>
                                    {readMoreRateCard.includes(
                                      rateCard.id,
                                    ) ? (
                                      <>
                                        {rateCard.description}{' '}
                                        <span
                                          className={classes.showMoreLess1}
                                          onClick={() => {
                                            const remove = readMoreRateCard.filter(
                                              (item) =>
                                                item !== rateCard.id,
                                            );
                                            setReadMoreRateCard(remove);
                                          }}
                                        >
                                          Show Less
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {rateCard.description.substr(0, 179)}...{' '}
                                        <span
                                          className={classes.showMoreLess1}
                                          onClick={() => {
                                            setReadMoreRateCard([
                                              ...readMoreRateCard,
                                              rateCard.id,
                                            ]);
                                          }}
                                        >
                                          Show More
                                        </span>
                                      </>
                                    )}
                                  </>
                                )}
                              </Typography>
                            )}
                          </div>
                        }
                      </div>
                      :
                      <div>
                        {rateCard.has_boat ? (
                          <Typography
                            variant="body2"
                            component="div"
                            className={classes.subtitle1}
                          >
                            <div style={{ textTransform: "capitalize", }}>
                              <img
                                className={classes.icon}
                                src={experienceIcon}
                              />
                             {rateCard.is_shared
                        ? `Shared ${rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type} Experience`
                        : `Private ${rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type} Experience`}
                            </div>
                            {displayBoat(rateCard)}
                          </Typography>
                        ):
                        (
                          <Typography
                            variant="h4"
                            component="div"
                            className={classes.subtitle3}
                          >
                            {getCapacityDetail(rateCard)}

                          </Typography>
                        )}
                        {rateCard.is_auto_add_trip_extension > 0 && (
                          <Typography
                            variant="body2"
                            component="div"
                            className={classes.subtitle1}
                          >
                            <img
                              className={classes.icon}
                              src={additionalChargeIcon}
                            />
                              Allows for hourly add-ons
                          </Typography>
                        )}
                        {rateCard.fish_species && rateCard.activity_type === 'fishing' && (
                          <Typography
                            variant="body2"
                            component="div"
                            className={classes.subtitle1}
                          >
                            <img
                              className={classes.icon}
                              src={targetedFishIcon}
                            />
                            <span className={classes.bold}>
                              Target Fish: <span>
                                {rateCard.fish_species.map(({ title }) => title.trim('')).join(', ')}
                              </span>
                            </span>
                          </Typography>
                        )}
                        {rateCard.description && (
                          <Typography variant="body2" component="div" style={{ paddingTop: 15 }}>
                            {rateCard.description.length <= 180 &&
                              rateCard.description}
                            {rateCard.description.length > 180 && (
                              <>
                                {readMoreRateCard.includes(
                                  rateCard.id,
                                ) ? (
                                  <>
                                    {rateCard.description}{' '}
                                    <span
                                      className={classes.showMoreLess}
                                      onClick={() => {
                                        const remove = readMoreRateCard.filter(
                                          (item) =>
                                            item !== rateCard.id,
                                        );
                                        setReadMoreRateCard(remove);
                                      }}
                                    >
                                      Show Less
                                      </span>
                                  </>
                                ) : (
                                  <>
                                    {rateCard.description.substr(0, 179)}...{' '}
                                    <span
                                      className={classes.showMoreLess}
                                      onClick={() => {
                                        setReadMoreRateCard([
                                          ...readMoreRateCard,
                                          rateCard.id,
                                        ]);
                                      }}
                                    >
                                      Show More
                                      </span>
                                  </>
                                )}
                              </>
                            )}
                          </Typography>
                        )}
                      </div>
                  }
                </div>
              </Grid>
            </Grid>
          )
        }) : <div style={{ margin: '0 auto', width: 30 }}>
          <CircularProgress size={30} color="inherit" />
        </div>}
      </Grid>
      <Grid ref={tripsummary} id="trip-summary-section" item sm={12} xs={12} md={4} ld={4}>
        {(isGuestCountMaxed) ? <Typography className={classes.redBoundary}>
          You have reached maximum limit of guests in the following trips.
        </Typography> : null}
        <Grid container style={{ marginTop: 10, borderRadius: 5 }} className={template ? `${classes.rateCardContainer} stickyContainer` : `${classes.rateCardContainer} ${classes.boxShadow} stickyContainer`}>
          <Grid item sm={12} xs={12} md={6} ld={6} style={{ borderRight: '1px solid grey' }} >
            <Typography style={{ padding: '5%' }}>Trip Summary  </Typography>
          </Grid>
          <Grid style={{ padding: '2%' }} className={'sizeHolder'} item sm={12} xs={12} md={6} ld={6} >
            <GuestCount setIsGuestCountMaxed={setIsGuestCountMaxed} guestLabel={classes.guestLabel} capacity={capacity} className={`${classes.filter} ${classes.filter1}`} setGuestCount={setGuestCountHandler} guestCount={guestCount} />
          </Grid>

          <Accordion  className={classes.accordinStyle}
           expanded={expanded === 'tripdate'} onChange={handleChange('tripdate')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{
                content: expanded === 'tripdate'?classes.block1:classes.block
              }}
             
            >
              <Typography className={classes.secondaryHeading}>Trip Date</Typography>
              <Typography className={classes.heading}>{selectedDay ? moment(selectedDay).format('MM/DD/YYYY') : 'Add Date'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: '100%' }}>
                <DayPicker
                  month={selectedDay?selectedDay:modifiers?.availability[0]}
                  fromMonth={fromMonth}
                  toMonth={toMonth}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  className={classes.fullWidth}
                  selectedDays={selectedDay}
                  onDayClick={(e, availModifiers) => {
                    handleDayClick(e, availModifiers)
                    if (availModifiers.availability) {
                      if (!selectedRateCard.id) {
                        setExpanded('triptype')
                      } else if(!selectedHour) {
                        setExpanded('triptime')
                      }else{
                        setExpanded('')
                      }
                    }
                  }}
                />
                <div className={classes.btnClear}>
                  <button
                    className={`btn--blue--solid`}
                    disabled={disabledDate}
                    onClick={() => { 
                      const allSCH = schrecords?.schedule
                      const { availability, scheduleDisable } = getAvailabilityScheduledForAllRateCards({ "availableSchedules": allSCH });
                      setModifiers({
                        ...modifiers,
                        availability,
                        scheduleDisable,
                        schedule: schrecords?.schedule
                      });
                      setSelectedDay('');   
                     setSelectedRateCard({});
                    setSelectedHour({}); 
                  }}
                    style={{ padding: "2px 7px", fontSize: "14px" }}
                  >
                    Clear Date
              </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordinStyle} expanded={expanded === 'triptype'} onChange={handleChange('triptype')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{
                content: expanded === 'triptype'?classes.block1:classes.block
              }}
             
            >
              <Typography className={classes.secondaryHeading}>Trip Type </Typography>
              <Typography className={classes.heading}>{selectedRateCard.title ? selectedRateCard.title : 'Add Trip'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: '100%' }}>
                {(selectedDay ? rateCards.filter(item => !item.disabled) : rateCards).map((rateCard, i) => {
                  return (
                    <label
                      style={{
                        width: "100%",
                      }}
                      key={`available-trip-${rateCard.id}`}
                    >
                      <Grid container className={`${rateCard.disabled ? '' : ''} innercontainer`} style={{
                        borderBottom: "1px solid #42642f",margin:"0px"}}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            padding: "8px 5px", display: "flex",
                            width: "100%",
                            margin: "5px 0px"
                          }}
                        >
                          <img
                            src={selectedRateCard.id === rateCard.id ? checked : unchecked}
                            onClick={() => {
                              setSelectedRateCard(rateCard);
                              setSelectedHour({});
                              if (selectedDay) {
                                setExpanded('triptime')
                              } else {
                                setExpanded('tripdate')
                              }
                              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: {}, rateCard })
                              setModifiers({
                                ...modifiers,
                                availability,
                                scheduleDisable,
                              });
                            }}
                            style={{
                              margin: 0,
                              padding: 0,
                              borderRadius: 0,
                              marginRight: 10,
                              minWidth: 20,
                              height: 20, width: 20, border: '1px solid grey'
                            }} width="20" height="20"/>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedRateCard(rateCard);
                              setSelectedHour({});
                              if (selectedDay) {
                                setExpanded('triptime')
                              } else {
                                setExpanded('tripdate')
                              }
                              const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: {}, rateCard })
                              setModifiers({
                                ...modifiers,
                                availability,
                                scheduleDisable,
                              });
                            }}
                          >
                            <Typography style={{ fontWeight: 'bolder' }} variant="body2" component="div" className="Head">
                              {rateCard.title}
                            </Typography>
                            {rateCard.activity_type === 'fishing' && <Typography variant="caption" component="div" className="dsctext">
                              {TripType().find(item => item.key.toLowerCase() === rateCard.ratetype.toLowerCase())?.label} <span style={{ position: 'relative', top: 3 }}>
                                <Tooltip classes={{ tooltip: 'guidesly-tooltip' }} enterTouchDelay={0} title={TYPES[rateCard.ratetype.toLowerCase()]}>
                                  <span><img src={InfoIcon} width={15} height={15} /></span>
                                </Tooltip>
                              </span>
                            </Typography>}
                            {(rateCard.maximum && !rateCard.has_boat && !rateCard?.is_shared_trip) ? <Typography variant="caption" component="div" className="dsctext"> {rateCard.maximum} guest capacity</Typography> : null}
                            {rateCard.has_boat && <Typography variant="caption" component="div" className="dsctext">
                              {rateCard.length}{rateCard.length?"' ":null}{(rateCard.manufacturer_name !="Other" && rateCard.manufacturer_name !="Others")?rateCard.manufacturer_name: rateCard.other_manufacturer}{' -'} {getMaxCapacity(rateCard)} guest capacity
                    </Typography>}
                          </span>
                        </Grid>
                      </Grid>
                    </label>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion  className={classes.accordinStyle} expanded={expanded === 'triptime'} onChange={handleChange('triptime')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{
                content: expanded === 'triptime'?classes.block1:classes.block
              }}
             
            >
              <Typography className={classes.secondaryHeading}>Trip Time</Typography>
              <Typography className={classes.heading}>{selectedHour.start_time ? `${convertTo12Hour(selectedHour.start_time)} ${getDuration(selectedHour)} for ${(getSymbolFromCurrency(userData?.currency) || '$')}${displayMoney(selectedHour.rate)}` : 'Add Start Time'}</Typography>
            </AccordionSummary>
            <AccordionDetails >
              <div style={{ width: '100%' }}>
                {selectedRateCard?.rates && selectedDay === ""? selectedRateCard?.rates.map((item, i) => {
                  return getAvailTimes(selectedRateCard, item, i)
                }) : selectedDay && selectedRateCard ? selectedRateCard?.rates?.filter(item => !item.disabled).map((item, i)=>{
                  return getAvailTimes(selectedRateCard,item, i)
                })
               : (!selectedDay||selectedDay==="") && Object.keys(selectedRateCard).length===0 && rateCards.map(rateCard => rateCard.rates?.map((item, i) => {
                  return getAvailTimes(rateCard, item, i)
                }))}
                {/* {availability.length === 0 ? (
                  <p className="norc">No Trips Available</p>
                ) : null} */}
              </div>
            </AccordionDetails>
          </Accordion>

          <Grid style={{ padding: '0 16px', width: '100%' }}>
            <TripCharge from='tripdetails' boldSubtitle checkCompany={checkCompany} />
            <div>
              <button
                // disabled={disabled}
                style={{ marginLeft: 0, width: '100%', borderRadius: 5, height: 40, marginBottom: 10 }}
                onClick={() => handleNextPage()}
                className={'bookNowBtn instantBook booknowExtra'}
              >
                Continue to Book
            </button>
            </div>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
};

export default RateCards;
