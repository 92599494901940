import React, { useContext, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import TripDetailsContext from '../state/context/TripDetailsContext';
import Tooltip from '@material-ui/core/Tooltip';
import getSymbolFromCurrency from 'currency-symbol-map'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  getAdditionalPersonCharge,
  getTotalCharge, displayMoney
} from '../utils/payment';
import { Box, Modal } from '@material-ui/core';
import { Icon } from 'react-icons-kit'
import { x } from "react-icons-kit/feather";
import useStyles from '../rate-cards-widget/components/large/styles';
const Info = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/black_info.png'
import moment from "moment"

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#333333',
    color: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    padding: 7,
    textAlign: 'left',
  },
}))(Tooltip);

export default function TripCharge({ from, isNarrow, boldSubtitle, tripCalculator, checkCompany }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [addFees, setAddFees] = useState([])
  const [showTooltipAdd, setShowTooltipAdd] = useState(false);
  const [addonTooltip, setAddonTooltip] = useState([])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const tripDetailsContext = useContext(TripDetailsContext);
  const {
    selectedHour,
    selectedRateCard,
    guestCount,
    addOnFees,
    setAddOnFees,
    tipamount,
    tippercentage,
    selectedDay,
    depositAmt,
    referralGuideName,
    depositAmtReferral,
    setDepositAmtReferral,
    bsDepositAmtReferral, setBsDepositAmtReferral,
    addonAmt, addOnCheck, consumerAddOn
  } = tripDetailsContext;

  const additionalCharge = getAdditionalPersonCharge(
    selectedRateCard,
    guestCount,
    selectedHour.rate ||
    (((selectedRateCard || {}).rates || [])[0] || {}).rate,
  );

  const tipAmount = tipamount && tipamount !== 'uponCash' ? Number(tipamount) : 0
  let total;
  let chargeToday;
  let chargeOnDayOfTrip;
  let totalIncludingMatesFees;
  if (selectedHour.rate) {
    total = getTotalCharge(
      selectedRateCard,
      guestCount,
      selectedHour.rate,
      addFees
    );
    let fees = addFees?.map(item => isNaN(item.charge_price) ? 0 : item.charge_price);
    fees = fees.reduce((a, b) => a + b, 0)
    totalIncludingMatesFees = total + fees + tipAmount+addonAmt
    const chargeReferral = depositAmtReferral ? ((total + fees+addonAmt) * (depositAmtReferral / 100)).toFixed(2) : 0
    const bsChargeReferral = bsDepositAmtReferral ? ((total + fees+addonAmt - parseFloat(((total + fees+addonAmt) * 0.2))- chargeReferral) * (bsDepositAmtReferral / 100)).toFixed(2) : 0
    let custom__deposit = checkCompany ?tripDetailsContext.selectedRateCard?.custom_deposit_percentage>0 ? tripDetailsContext.selectedRateCard?.custom_deposit_percentage/100 :depositAmt?(depositAmt/100):0.2 :selectedRateCard && selectedRateCard?.custom_deposit_percentage>0 ? selectedRateCard?.custom_deposit_percentage/100 :0.2
    chargeToday = chargeReferral ?parseFloat(Number((total + fees+addonAmt) * 0.2) + Number(chargeReferral)+Number(bsChargeReferral)).toFixed(2) : ((total + fees+addonAmt) * custom__deposit).toFixed(2);
    chargeOnDayOfTrip = totalIncludingMatesFees - chargeToday;
  }
  useEffect(() => {
    setAddFees(addOnFees)
  }, [addOnFees])
  
  useEffect(() => {
    if (addFees) {
      let newAddonFee = (selectedRateCard.addon_fees || []).filter(item => item.active)
      const addon_fees = newAddonFee.map(item => {
        let itm = item;
        itm.charge_price = item.charge_value ? item.charge_value : (total / 100) * Number(item.charge_percentage)
        itm.charge_price = Number(itm.charge_price)
        return itm;
      })
      setAddOnFees(addon_fees)
    }
  }, [selectedHour, selectedRateCard, total])

  useEffect(() => {
    if (referralGuideName && selectedRateCard) {
      if(selectedRateCard?.is_group) {
      setDepositAmtReferral(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.referral_percentage)
      setBsDepositAmtReferral(selectedRateCard?.group_percentage)
      }
      else {
        setDepositAmtReferral(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.referral_percentage)
        setBsDepositAmtReferral(0)
      }
    }
  }, [referralGuideName, selectedRateCard])
  const onHide = () => {
    setShowMore(!showMore)
  }
  const getMinutes = (time) => {
    let [integerPart, decimalPart] = time.toString().split('.');
    if (decimalPart !== undefined) {
      switch (decimalPart) {
          case '45':
              decimalPart = '75';
              break;
          case '3':
              decimalPart = '5';
              break;
            case '30':
                decimalPart = '50';
                break;
          case '15':
              decimalPart = '25';
              break;
      }
  }
  const modifiedNumber = parseFloat(`${integerPart}.${decimalPart || '00'}`);
  return modifiedNumber;
  }
  useEffect(() => {
		let arr = [];
		if (consumerAddOn && addOnCheck && selectedHour) {
			const filteredData = consumerAddOn.filter(item => addOnCheck[item.id])
			for (let item of filteredData) {
				let tempObj = {};
				tempObj.title = item.title;
				if (item.duration?.is_entire_trip) {
					tempObj.value = parseFloat(item.charge_value);
				} else if (item.duration?.days) {
					if (selectedHour.duration_days) {
						tempObj.value = parseFloat(item.charge_value * selectedHour.duration_days)
					} else {
						tempObj.value = parseFloat(item.charge_value * (getMinutes(parseFloat(selectedHour.duration_hours)) / 24))
					}
				} else if (item.duration?.hours) {
					if (selectedHour.duration_days) {
						tempObj.value = parseFloat(item.charge_value * selectedHour.duration_days * 24)
					} else {
						tempObj.value = parseFloat(item.charge_value * getMinutes(parseFloat(selectedHour.duration_hours)))
					}
				} else {
					if (selectedHour.duration_days) {
						tempObj.value = parseFloat(item.charge_value * selectedHour.duration_days * 48)
					} else {
						tempObj.value = parseFloat(item.charge_value * getMinutes(parseFloat(selectedHour.duration_hours)) * 2)
					}
				}
				arr.push(tempObj);
			}
			setAddonTooltip(arr)
		}
	}, [addOnCheck])
  return (
    <>
      <div style={{ marginTop: 10 }} className={classes.spaceBetween}>
        <Typography variant="subtitle2" component="div" className={`${classes.subtitle2} ${(boldSubtitle || isNarrow) ? classes.bold : ''} `}>
          Base Trip Cost
        </Typography>
        <Typography variant="subtitle2" component="div" className={`${classes.subtitle2} ${classes.AlignEnd} ${(boldSubtitle || isNarrow) ? classes.bold : ''} `}>
          {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((selectedHour.rate || 0).toFixed(2)) || '0.00'}`}
        </Typography>
      </div>
      {
        selectedRateCard && addonAmt != 0  &&
        <div className={`${classes.spaceBetween}`} >
          <Typography variant="subtitle2" className={`${classes.subtitle2}`}>{'Add-Ons '}

            {!isNarrow && <span>
              <HtmlTooltip
                key={'top'}
                placement={'top'}
                open={showTooltipAdd}
                onOpen={() => setShowTooltipAdd(true)}
                onClose={() => setShowTooltipAdd(false)}
                title={
                  <React.Fragment>
                    {
															addonTooltip.length > 0 &&
															addonTooltip.map(item => {
																return (
																	<div style={{ textAlign: 'left' }}>
																		<span>
																			{item.title}
																		</span>
																		<span >
																			{`: ${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney(Number(item.value || 0).toFixed(2))}`}
																		</span>
																	</div>
																)
															})
														}
                  </React.Fragment>
                }
              >
                <img src={Info} style={{ width: 15, height: 15, verticalAlign: 'middle' }} onClick={() => setShowTooltipAdd(!showTooltipAdd)} />
              </HtmlTooltip>
            </span>}
          </Typography>
          <Typography variant="subtitle2" className={`${classes.subtitle2}  ${classes.AlignEnd}`}>
            {/* $ {displayMoney((item.charge_price || 0).toFixed(2))} */}
            {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney(addonAmt?.toFixed(2))}`}
          </Typography>
        </div>
      }
      {from !== 'tripdetails' ? <div className={classes.spaceBetween}>
        <Typography
          variant="subtitle2"
          component="div"
          className={`${classes.subtitle2}`}
        >
           {(tripDetailsContext.tipType !== 'Custom' && tripDetailsContext.tipType !== 'Cash Tip on Trip' && String(tippercentage).includes('%')) ? 'Tip  (' + (tippercentage) + ')' : tripDetailsContext.tipType === "Custom"?`Tip`:`Tip - (${tripDetailsContext.tipType})`}
        </Typography>
        {tripDetailsContext.tipType !== 'Cash Tip on Trip' ? <Typography
          variant="subtitle2"
          component="div"
          className={`${classes.subtitle2}  ${classes.AlignEnd}`}
        >
          {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((Number(tripDetailsContext.tipamount || 0)).toFixed(2))}`}
        </Typography> : null}
      </div> : null}
      {additionalCharge && additionalCharge !== 0 ? (
        <div className={classes.spaceBetween}>
          <Typography
            variant="subtitle2"
            component="div"
            className={`${classes.subtitle2}`}
          >
            Additional Guest Fee
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            className={`${classes.subtitle2}  ${classes.AlignEnd}`}
          >
            {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((additionalCharge || 0).toFixed(2))}`}
          </Typography>
        </div>
      ) : null}
       {
        Object.keys(addOnFees).length > 0 && total &&
        <div className={`${classes.spaceBetween}`} >
          <Typography variant="subtitle2" className={`${classes.subtitle2}`}>{'Fees '}

            {!isNarrow && <span>
              <HtmlTooltip
                key={'top'}
                placement={'top'}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                title={
                  <React.Fragment>
                    {
                      Object.keys(addOnFees).length > 0 &&
                      addOnFees.map(item => {
                        return (
                          <div>
                            <span>
                              {item.title}
                            </span>
                            <span >
                              : {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney((item.charge_price || 0).toFixed(2))}`}
                            </span>
                          </div>
                        )
                      })
                    }
                  </React.Fragment>
                }
              >
                <img src={Info} style={{ width: 15, height: 15, verticalAlign: 'middle' }} onClick={() => setShowTooltip(!showTooltip)} />
              </HtmlTooltip>
            </span>}
          </Typography>
          <Typography variant="subtitle2" className={`${classes.subtitle2}  ${classes.AlignEnd}`}>
            {/* $ {displayMoney((item.charge_price || 0).toFixed(2))} */}
            {` ${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((addOnFees.reduce((accumulator, object) => {
              return accumulator + object.charge_price;
            }, 0) || 0).toFixed(2))}`}
          </Typography>
        </div>
      }
      {(
        <div className={classes.spaceBetween}>
          <Typography
            variant="subtitle2"
            component="div"
            className={`hilightAmount ${classes.subtitle2} ${boldSubtitle ? classes.bold : ''} `}
            style={{
              fontWeight: 'bolder',
              fontSize: '16px', color: '#086edc'
            }}
          >
            {`Total (${tripDetailsContext?.userData?.currency || 'USD'})`}
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            className={`hilightAmount ${classes.subtitle2}  ${classes.AlignEnd} ${boldSubtitle ? classes.bold : ''} `}
            style={{
              fontWeight: 'bolder',
              fontSize: '16px', color: '#086edc'
            }}
          >
            {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney(((totalIncludingMatesFees) || 0).toFixed(2))}`}
          </Typography>
        </div>
      )}
      {
        from ?
          <>
            <Divider className={classes.divider} />
            <div className={classes.spaceBetween} style={{ color: '#086edc' }}>
              <Typography
                variant="subtitle2"
                component="div"
                style={{
                  fontWeight: 'bolder',
                  fontSize: '16px',
                  color: '#086edc'
                }}
                className={`${classes.subtitle2} `}
              >
                Due now
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                className={`${classes.subtitle2} ${classes.AlignEnd}`}
                style={{
                  fontWeight: 'bolder',
                  fontSize: '16px',
                  color: '#086edc'
                }}
              >
                {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((Number(chargeToday) || 0).toFixed(2))}`}
              </Typography>
            </div>
            <div className={classes.spaceBetween}>
              <Typography
                variant="subtitle2"
                component="div"
                style={{
                  fontWeight: 'bolder', fontSize: '16px',
                  color: '#000'
                }}
                className={`${classes.subtitle2} ${boldSubtitle ? classes.bold : ''} `}
              >
                Due on {selectedDay ? moment(selectedDay).format('MMM Do, YYYY') : null}
              </Typography>
              <Typography
                style={{
                  fontWeight: 'bolder',
                  fontSize: '16px',
                  color: '#000'
                }}
                variant="subtitle2"
                component="div"
                className={`${classes.subtitle2} ${classes.AlignEnd} ${boldSubtitle ? classes.bold : ''} `}
              >
                {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((chargeOnDayOfTrip || 0).toFixed(2))}`}
              </Typography>
            </div>
          </> : null
      }
      {
        isNarrow && Object.keys(selectedHour).length > 0 && addFees.length > 0 && total != 0 && <p className={classes.moreInfo} onClick={() => setShowMore(true)}>More info</p>
      }
      <Modal
        open={showMore}
        aria-labelledby="contained-modal-title-vcenter"
        onClose={onHide}
      >
        <Box className={classes.boxCont}>
          <div className={classes.boxHead}>
            <p style={{ fontSize: "16px", color: "black", fontWeight: 700, margin: "0px" }} >Price Details</p>
            <Icon icon={x} onClick={onHide} style={{ cursor: "pointer" }} />
          </div>
          <div style={{padding:"1rem"}}>
          {
            addFees.map((item, i) => {
              if (item.active) {
                return (
                  <div style={{ marginTop: 10 }} className={classes.spaceBetween} key={i}>
                    <Typography variant="subtitle2" component="div" className={`${classes.subtitle2} ${(boldSubtitle || isNarrow) ? classes.bold : ''} `}>
                      {item.description} {item.charge_percentage&&`(${Number(item.charge_percentage)}%)`}
                    </Typography>
                    <Typography variant="subtitle2" component="div" className={`${classes.subtitle2} ${classes.AlignEnd} ${(boldSubtitle || isNarrow) ? classes.bold : ''} `}>
                      {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((item.charge_price || 0).toFixed(2)) || '0.00'}`}
                    </Typography>
                  </div>
                )
              }
            })
          }
          </div>
        </Box>

      </Modal >
    </>
  );
}

