import React, { useState } from 'react'
import { Typography } from '@material-ui/core';

const Comment = ({ item, classes }) => {
    const [on, setOn] = useState(false)
    const handleShow = () => {
        setOn(!on)
    }

    return (
        item.comments.includes("</p>") ?
            <Typography component={'span'} variant='body2' className={classes.subtext}>
                <div
                    className={on === false && item.comments.length > 400 ? `${classes.descLimitedContents} ${classes.comments}` : `${classes.descContents} ${classes.comments}`}
                    dangerouslySetInnerHTML={{ __html: item.comments }} />
                {item.comments.length < 400 ? null :
                    on === false ? <p
                        className={classes.more}
                        onClick={handleShow}>
                        Read More
                        </p> :
                        <p
                            className={classes.more}
                            onClick={handleShow}>
                            Read Less
                        </p>

                }
            </Typography> :
            <Typography component={'span'} variant='body2' className={classes.subtext}>
                <div className={on === false && item.comments.length > 385 ? `${classes.descLimitedContents} ${classes.comments}` : `${classes.descContents} ${classes.comments}`} dangerouslySetInnerHTML={{ __html: item.comments }}></div>
                {item.comments.length < 385 ? null :
                    on === false ? <p
                        className={classes.more}
                        onClick={handleShow}>
                        Read More
                        </p> :
                        <p
                            className={classes.more}
                            onClick={handleShow}>
                            Read Less
                        </p>

                }
            </Typography>
    )

}

export default Comment;
