import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    wordWrap: 'break-word',
    backgroundClip: 'border-box',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '.25rem',
    marginLeft: 20,
    padding: '6px 10px',
    '@media screen and (max-width: 560px)': {
      marginLeft: 0,
    },
  },
  heading: {
    fontSize: 17,
    fontWeight: 600,
    letterSpacing: 1
  },
  details: {
    padding: '10px 0'
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    justifyContent: 'center',
    borderRight: '1px solid rgb(66, 100, 47)'
  },
  guest: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 5,
    marginRight: 10,
  },
  hbar: {
    backgroundColor: '#c0ceb7',
    height: 1,
    marginTop: 5,
    width: '100%',
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 13,
    marginTop: 5,
    justifyContent: 'space-between'
  },
  amtContainer: {
    backgroundColor: '#416326',
    color: '#fff !important',
    padding: 10,
    marginTop: 10
  },
  fontSize: {
    fontSize: 13,
  },

  next: {
    color: 'white !important',
    backgroundColor: '#1a04ec!important',
    border: '4px solid #1a04ec!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: 'white',
      background: '#20b4e4',
      backgroundColor: '#1a04ec!important',
      border: '4px solid #1a04ec!important',
    },
    marginLeft: 10,
    marginTop: 10,
    minWidth: 170,
  },
  previous: {
    color: '#20b4e4',
    border: '1px solid #20b4e4!important',
    boxShadow: '0 6px 10px -6px #000',
    backgroundColor: '#fff !important',
    padding: 9,
    width: 100,
    '&:hover': {
      color: '#20b4e4',
      border: '1px solid #20b4e4!important',
      boxShadow: '0 6px 10px -6px #000',
    },
    marginTop: 10,
  },
  buttonContainer: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  blue: {
    color: '#1a04ec',
  },
  tooltip: {
    fontSize: 16
  }
}));
