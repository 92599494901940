import React from 'react';

const today = new Date(new Date().setHours(0, 0, 0, 0));
const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));

export const initialState = {
  /* guide details */
  /* TO-DO rename to guide details context */
  step: 'trip-details',
  label: 'Date of Trip:',
  month: null,
  fromMonth: tomorrow,
  toMonth: new Date(
    new Date(
      new Date().setFullYear(new Date().getFullYear() + 1),
    ).setHours(0, 0, 0, 0),
  ),
  modifiers: {
    unavailablity: [
      {
        before: today,
        after: today,
      },
    ],
    scheduleDisable: [
      {
        before: today,
        after: today,
      },
    ],
    availability: [],
    schedule: [],
  },
  modifiersStyles: {
    availability: {
      color: 'black',
      borderRadius: '50%',
      fontWeight: 600,
      cursor: 'pointer',
      width: '10px !important',
    },
    scheduleDisable: {
      color: 'lightgrey',
      cursor: 'pointer',
      fontWeight: 'normal',
      width: '10px !important',
      border: '3px solid transparent',
      opacity: 0.5
    },
    // today: {
    //   color: 'lightgrey',
    //   cursor: 'pointer',
    //   fontWeight: 'normal',
    //   width: '10px !important',
    //   border: '3px solid transparent',
    // },
    unavailablity: {
      color: 'lightgrey',
      cursor: 'pointer',
      fontWeight: 'normal',
      width: '10px !important',
      border: '3px solid transparent',
    }
  },
  availableRateCards: [],
  availableSchedules: [],
  rateCards: [],
  availableWaterBodies: [],
  availableMeals: [],
  availableMeetingPoints: [],
  availableTripTypes: [
    {
      id: 1,
      key: 'Back Country',
      value: 'Back Country Fishing',
      label: 'Back Country Fishing',
    },
    {
      id: 2,
      key: 'Near Shore',
      value: 'Near Shore',
      label: 'Near Shore Fishing',
    },
    {
      id: 3,
      key: 'In Shore',
      value: 'In Shore',
      label: 'In Shore',
    },
    {
      id: 4,
      key: 'OffShore',
      value: 'Off Shore',
      label: 'Off Shore',
    },
    {
      id: 5,
      key: 'Reefs',
      value: 'Reefs',
      label: 'Reefs',
    },
    {
      id: 6,
      label: 'Wrecks',
      value: 'Wrecks',
    },
    {
      id: 7,
      label: 'Flats',
      value: 'Flats',
    },
    {
      id: 8,
      label: 'Lake',
      value: 'Lake',
    },
    {
      id: 9,
      label: 'River',
      value: 'River',
    },
    {
      id: 10,
      label: 'Wading',
      value: 'Wading',
    },
    {
      id: 11,
      label: 'Land Use',
      value: 'Land Use',
    },
    {
      id: 12,
      label: 'Spey Fishing',
      value: 'Spey Fishing',
    },
    {
      id: 13,
      label: 'Surf',
      value: 'Surf',
    },
  ],
  availableTripHours: [
    { id: 1, label: '2 Hours', value: 2 },
    { id: 2, label: '4 Hours', value: 4 },
    { id: 3, label: '6 Hours', value: 6 },
    { id: 4, label: '8 Hours', value: 8 },
    { id: 5, label: '10 Hours', value: 10 },
    { id: 6, label: '12 Hours', value: 12 },
    { id: 7, label: 'Others ', value: 'is_custom_rate' },
  ],
  availableTripTimes: [
    { id: 1, label: 'Morning (sunrise to 11.00AM)', value: 'morning' },
    { id: 2, label: 'Afternoon (10:00 AM to 3.00PM) ', value: 'afternoon' },
    { id: 3, label: 'Evening (3:00PM to end of the day)', value: 'evening' },
  ],
  guideName: '',

  /* trip details */
  /* TO-DO: move this to a personal-detail context */
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  preferedWaterbody: {},
  secondFavoriteWaterbody: {},
  meals: {},
  meeetingPoint: {},
  guidesNotes: '',
  preferSelection: false,
  needEquipments: false,
  selectedDay: null,
  error: {
    firstName: 'First Name is required',
    lastName: 'Last Name is required',
    phoneNumber: 'Phone is required',
    email: 'Email is required',
  },
  selectedHour: {},
  selectedRateCard: {},
  selectedTripType: '',
  selectedTripHours: [],
  guestCount: 1,
  selectedTripTime: [],
  addOnFees: [],
  tipamount:'',
  tipType : '',
  isGuestCountMaxed: false,
  selectionType:{type:null,guid:null, name:null},
};
const TripDetailsContext = React.createContext(initialState);

export default TripDetailsContext;
