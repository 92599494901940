import  './styles.scss'

export const options = [
	{
		label: "January",
		id: 1
	},
	{
		label: "February",
		id: 2
	},
	{
		label: "March",
		id: 3
	},
	{
		label: "April",
		id: 4
	},
	{
		label: "May",
		id: 5
	},
	{
		label: "June",
		id: 6
	},
	{
		label: "July",
		id: 7
	},
	{
		label: "August",
		id: 8
	},
	{
		label: "September",
		id: 9
	},
	{
		label: "October",
		id: 10
	},
	{
		label: "November",
		id: 11
	},
	{
		label: "December",
		id: 12
	}
]
export const radiobtns = [
    { label: "Months", id: 1 },
    { label: "Species", id: 2 }
];

export const gradVal = [
	{
		set: [6],
		style: 'ProgressGradiant1'
	},
	{
		set: [5],
		style: 'ProgressGradiant2'
	},
	{
		set: [4],
		style: 'ProgressGradiant1'
	},
	{
		set: [3],
		style: 'ProgressGradiant2'
	},
	{
		set: [2],
		style: 'ProgressGradiant3'
	},
	{
		set: [1],
		style: 'ProgressGradiant4'
	}
]

export const getChartColor = scale => {
	let color = "";
	switch (scale) {
		case 6:
			color = "#4b9339";
			break;
		case 5:
			color = "#68af53";
			break;
		case 4:
			color = "#82c96c";
			break;
		case 3:
			color = "#9de686";
			break;
		case 2:
			color = "#b7fa9f";
			break;
		case 1:
			color = "#caf9b1";
			break;
		default:
			color = "";
	}
	return color;
};

export const getGradiantChartColor = scale => {
	let colorStart = "";
	let colorStop = "";
	switch (scale) {
		case 6:
			colorStart = "#185b9d"
			colorStop = "#42cca2";
			break;
		case 5:
			colorStart = "#266ebf"
			colorStop = "#54dcb6";
			break;
		case 4:
			colorStart = "#185b9d"
			colorStop = "#42cca2";
			break;
		case 3:
			colorStart = "#266ebf"
			colorStop = "#54dcb6";
			break;
		case 2:
			colorStart = "#4285d0"
			colorStop = "#73e7c8";
			break;
		case 1:
			colorStart = "#6aa4e5"
			colorStop = "#a5f1dd";
			break;
	}
	var newColor = [colorStart,colorStop]
	return newColor;
};