/* eslint-disable */
import React, { useContext, useEffect, useRef, useMemo, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { minus, plus } from 'react-icons-kit/fa'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TripDetailsContext from '../../state/context/TripDetailsContext';
import { getAvailabilityBasedOnSelectedRateCard, getAvailabilityScheduledForAllRateCards, getRateCards, getInitialRateCards, getAvailability, getAllRateCardsWithDisabled } from './guide-details';
import {getMaxCapacity} from '../../utils/guide-details'
import { star } from 'react-icons-kit/fa/star'
import convertTo12Hour, { getTimeFormat, getTimeFormat1 } from './time.js';
import { createTheme } from '@material-ui/core/styles'
import {
  requireField,
  validateEmail,
} from '../../utils/validators';
import useStyles from './styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { useLocation } from 'react-router-dom';
import moment from 'moment'
import getSymbolFromCurrency from 'currency-symbol-map'
import PhoneInput, { isValidPhoneNumber, formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Button from '@material-ui/core/Button';
import TripCharge from '../TripCharge';
import { displayMoney, getAdditionalPersonCharge, getTotalCharge,displayMoney1 } from '../../utils/payment';
import { Divider, CircularProgress, TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { TYPES } from '../description';
import { post, get } from '../../utils/axiosHelper';
import GuestCount from '../filters/GuestCount';
const InfoIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/black_info.png'
import DayPicker from '../../common/daypicker/DayPicker'
import './styles.scss'

import Icon from "react-icons-kit"
import { chevronRight } from 'react-icons-kit/feather/chevronRight'
import CheckoutModal from './CheckoutModal';
import ChatModal from './ChatModal';
import CustomSingleDatePicker from './CustomSingleDatePicker';
import LazyLoadComponent from './LazyLoadComponent';
import { getDuration } from '../../utils/time';

const useOptions = () => {
  const fontSize = '16px';
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          // fontFamily: "Source Code Pro, monospace",
          '::placeholder': {
            color: '#aab7c480',
          },
        },
        invalid: {
          iconColor: '#ffc7ee',
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );

  return options;
};

const areaCodesCanada = ["204", "226", "236", "249", "250", "263", "289", "306", "343", "354", "367", "365", "368", "382", "403", "416", "418", "428", "431", "437", "438", "450", "468", "905", "474", "506", "514", "519", "548", "579", "581", "584", "587", "604", "613", "639", "647", "672", "683", "705", "709", "742", "753", "778", "780", "782", "807", "819", "829", "825", "867", "873", "879", "902"]

export default function PersonalDetails({ previous, tripsPage, xAppID, origin, next, isNarrow, capacity, setGuestCountHandler, isLCal, isMultiTenantGuide, companyGuid, }) {
  const tripDetailsContext = useContext(TripDetailsContext);
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    preferedWaterbody,
    setPreferedWaterbody,
    secondFavoriteWaterbody,
    setSecondFavoriteWaterbody,
    meals,
    setMeals,
    meeetingPoint,
    setMeeetingPoint,
    guidesNotes,
    setGuidesNotes,
    preferSelection,
    setPreferSelection,
    needEquipments,
    setNeedEquipments,
    error,
    setError,
    availableWaterBodies,
    availableMeetingPoints,
    availableMeals,
    guestCount,
    setGuestCount,
    selectedDay,
    fromMonth,
    toMonth,
    modifiers,
    modifiersStyles,
    setAvailableRateCards,
    setSelectedRateCard,
    setSelectedHour,
    setShowCalendar,
    setRateCards,
    selectedRateCard,
    selectedHour,
    setSelectedDay,
    referralLocation, setReferralLocation,
    referralCompany, setReferralCompany,
    referralReview, setReferralReview,
    setReferralConfirmationName,
    rateCards,
    availableSchedules,
    addOnFees,
    isGuestCountMaxed,
    tipamount,
    setPaymentCardDetails,
    setModifiers,
    setIsGuestCountMaxed,
    tippercentage,
    setTippercentage,
    selectionType,
    depositAmt,
    referralMainId,
    setConfirmationRate,
    referralGuideName,
    depositAmtReferral,
    cancellationdays,
    setDepositAmtReferral,
    bsDepositAmtReferral, setBsDepositAmtReferral,
    schrecords,
    setSource,
    source,
    companyDetails,
    bsAvailability,
    addOnCheck, setAddonCheck,
    consumerAddOn, setConsumerAddOn,
    addonAmt, setAddonAmt,
  } = tripDetailsContext;
  const classes = useStyles(); const [show, setShow] = useState(false);
  const [list, setList] = useState([])
  const [calendarShow, setCalendarShow] = useState(false);
  const [adult, setAdult] = useState(guestCount ? guestCount : 1);
  const [child, setChild] = useState(0);
  const [guestModal, setGuestModal] = useState(false)
  const customTip = useRef(null);
  const [localTripType, setLocalTripType] = useState(selectedRateCard || null);
  const [localTripTime, setLocalTripTime] = useState(selectedHour || null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const [meeetingPointLocal, setMeeetingPointLocal] = useState(null);
  const [showMP, setShowMP] = useState(false)
  const [showTip, setShowTip] = useState(false)
  const elements = useElements();
  const [localTip, setLocalTip] = useState({});
  const options = useOptions();
  const [referralId,setReferralId ] = useState('')
  const [paymentStarted, setPaymentStarted] = useState(false);
  const [zip, setZip] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = React.useState('');
  const [status, setStatus] = useState(false)
  const [showTripDetails, setShowTripDetails] = React.useState(false)
  const [dateSelectedInThisFlow, setDateSelectedInThisFlow] = React.useState(false)
  const [zipCode, setZipCode] = useState("");
  const [inCorrect, setInCorrect] = useState(false);
  const [inValidPhone, setInValidPhone] = useState(false);
  const [dateSelection, setDateSelection] = useState(false)
  const [timeSelection, setTimeSelection] = useState(false)
  const [enable, setEnable] = useState(false)
  const [totalCost, setTotalCost] = useState(0);
  const isBrowser = typeof window !== `undefined`
  const guestCountRef = useRef()
  const [deviceVersion, setDeviceVersion] = useState("");
  const isMobile = isBrowser && (/Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 568 || ((window.screen || {}).width || 0) <= 568)
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardNumber, setCardNumber] = useState(false)
  const [cardExpiry, setCardExpiry] = useState(false)
  const [cardCVC, setCardCVC] = useState(false)
  const [cardExpiryError, setCardExpiryError] = useState("");
  const [cardCVCError, setCardCVCError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [showTripTimeModal, setShowTripTimeModal] = useState(false)
  const [selectedRefComp, setSelectedRefComp] = useState(null)
  const [showTripTypeModal, setShowTripTypeModal] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [cancellationDate, setCancellationDate] = useState('')
  const [errorMsg, setErrorMsg] = useState("");
  const [rateLocation, setRateLocation] = useState([]);
  const [referral, setReferral] = useState({});
  const [meetingspotOptions, setMeetingSpotOptions] = useState([])
  // let location = useLocation();
  // let guideId = location.pathname.split("/")

  // guideId = guideId[guideId.length - 1]
  const checked = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/checked.svg';
  const unchecked = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/uncheck.svg';
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    setList(rateCards.filter(item => !item.disabled))
  }, [rateCards])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCancel = () => {
    // setSelectedRateCard(selectedRateCard)
    setLocalTripType(selectedRateCard)
    setShowTripTypeModal(!showTripTypeModal)
  }

  const handleCalendar = (e) => {
    setShow(!show)
    const ariaLabel = (e.target.attributes['aria-label'] || {}).value || ''
    if (ariaLabel !== 'Next Month' && ariaLabel !== 'Previous Month') {
      setCalendarShow(!calendarShow)
    }
    if (!selectedDay) {
      // context.setSelectedRateCardTitle(null)
    }
  }
  const groupCount = {
    "Adults": { count: adult, setCount: setAdult, min: 0 },
    "Children": { count: child, setCount: setChild, min: 0 },
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleApply = () => {
    if ((adult + child) > 0) {
      setGuestCount(adult + child)
    }
    setGuestModal(!guestModal)
  }
  const allMeetPoint= selectedRateCard && selectedRateCard?.poi_guids?selectedRateCard?.poi_guids:rateLocation?.poiguid;
 
  useEffect(() => {
    if (isBrowser) {
      setDeviceVersion(String(navigator.appVersion).substring(0, 3));
    }
    return () => setSource("");
  }, [])
  useEffect(() => {
    !selectedDay && setShow(true);
    if (source === 'mobile' && selectedDay && isEmpty(selectedRateCard)) {
      setShowTripTypeModal(true);
    }
  }, []);
  useEffect(() => {
		if(meeetingPoint&&Object.keys(meeetingPoint)?.length>0){
			setMeeetingPointLocal(meeetingPoint)
		}
	}, [meeetingPoint])
  useEffect(() => {
    if (tripDetailsContext.selectedRateCard&& Object.keys(tripDetailsContext.selectedRateCard)?.length > 0) {
      const filtered = context.schrecords?.poi?.filter(item => tripDetailsContext.selectedRateCard?.poi_guids?.includes(item.poiguid))
			setMeetingSpotOptions(filtered)
      if(!showTripTimeModal&&selectedDay && filtered?.length>=1&&selectedHour&&Object.keys(selectedHour)?.length > 0){
        setMeeetingPointLocal(filtered[0])
        setMeeetingPoint(filtered[0])
      }
    }else{
      const filtered = []
			if (context.schrecords?.ratecards.length > 0) {
				for (let item of context.schrecords?.ratecards) {
					for (let rec of item.poi_guids) {
						const r = context.schrecords?.poi?.filter(s => s.poiguid === rec)
						if (r.length > 0) {
							filtered.push(r[0])
						}
					}
				}
			}
			const uniq = [...new Set(filtered)];
      uniq.sort((a, b) => a.poi_order - b.poi_order);
			setMeetingSpotOptions(uniq)
    }
    
  }, [tripDetailsContext.selectedRateCard])
  useEffect(() => {
		if(selectedRateCard) {
			let addOns= selectedRateCard?.consumer_addon_fees
			let filteredAddOns = addOns?.filter(item=> {return item.active !== false;})
			setConsumerAddOn(filteredAddOns)
			let objTemp={}
			filteredAddOns?.forEach(obj => {
				objTemp[obj.id] = false;
			  })
			setAddonCheck(objTemp)
		}

	},[selectedRateCard])
  const getMinutes = (time) => {
    let [integerPart, decimalPart] = time.toString().split('.');
    if (decimalPart !== undefined) {
      switch (decimalPart) {
          case '45':
              decimalPart = '75';
              break;
          case '3':
              decimalPart = '5';
              break;
            case '30':
                decimalPart = '50';
                break;
          case '15':
              decimalPart = '25';
              break;
      }
  }
  const modifiedNumber = parseFloat(`${integerPart}.${decimalPart || '00'}`);
  return modifiedNumber;
  }
  useEffect(() => {
		let total = 0.00;
		const filteredData = consumerAddOn?.filter(item => addOnCheck[item.id])
		if(addOnCheck && filteredData?.length>0) {
			for (let item of filteredData ) {
					if(item.duration?.is_entire_trip) {
						total+=parseFloat(item.charge_value)
					} else if (item.duration?.days) {
						if(selectedHour.duration_days) {
							total+=parseFloat(item.charge_value*selectedHour.duration_days)
						} else {
							total+=parseFloat(item.charge_value*(getMinutes(parseFloat(selectedHour.duration_hours))/24))
						}
					}else if (item.duration?.hours) {
						if(selectedHour.duration_days) {
							total+=parseFloat(item.charge_value*selectedHour.duration_days*24)
						} else {
							total+=parseFloat(item.charge_value*getMinutes(parseFloat(selectedHour.duration_hours)))
						}
					} else {
						if(selectedHour.duration_days) {
							total+=parseFloat(item.charge_value*selectedHour.duration_days*48)
						} else {
							total+=parseFloat(item.charge_value*getMinutes(parseFloat(selectedHour.duration_hours))*2)
						}
					}

			}
			setAddonAmt(total);
		}
    else {
      setAddonAmt(0)
    }

	},[addOnCheck])
  useEffect(()=>{
    const availRC = []
		// checking if only meeting point is selected without date and trip.
		if (meeetingPoint && Object.keys(context.schrecords).length > 0 && (!selectedDay || selectedDay === "") && Object.keys(tripDetailsContext.selectedRateCard).length === 0) {
			for (let item of context.schrecords.ratecards) {
				for (let rec of item.poi_guids) {
					if (rec === meeetingPoint?.poiguid) {
							// based on meeting point filtering ratecard rates
							availRC.push({...item,"disabled":false})
					}else{
            availRC.push({...item,"disabled":true})
          }
				}
			}
			setRateCards(availRC)
			//setShowTripTypeModal(true)
		}
  },[meeetingPoint])
  const getUA = () => {
    if (isBrowser) {
      let device = "Unknown";
      const ua = {
        "Generic Linux": /Linux/i,
        "Android": /Android/i,
        "BlackBerry": /BlackBerry/i,
        "Bluebird": /EF500/i,
        "Chrome OS": /CrOS/i,
        "Datalogic": /DL-AXIS/i,
        "Honeywell": /CT50/i,
        "iPad": /iPad/i,
        "iPhone": /iPhone/i,
        "iPod": /iPod/i,
        "macOS": /Macintosh/i,
        "Windows": /IEMobile|Windows/i,
        "Zebra": /TC70|TC55/i,
      }
      Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
      return device;
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const checkCompany = isMultiTenantGuide && companyGuid && companyGuid != ""


  useEffect(() => {
    if (selectedRateCard) {
      setConfirmationRate(selectedRateCard)
    }
  }, [selectedRateCard])

  useEffect(() => {
    if(selectedRateCard && referralGuideName) {
      setReferral(referralGuideName.filter(a => a.guideguid == selectedRateCard.guideguid))
    }

  },[selectedRateCard, referralGuideName])


  useEffect(() => {
    if (error.paymentError) {
      window.scroll(0, 0)
      setTimeout(() => {
        setError({
          ...error,
          paymentError: ''
        })
      }, 5000);
    }
  }, [error.paymentError])

  const context = useContext(TripDetailsContext)
  const handleRateNTime = (item, rec) => {
    setDateSelection(false)
    setTimeSelection(true)
    setShow(true)
    const allSCH = context.schrecords?.schedule
    const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ "availableSchedules": allSCH, "hourCard": rec, item })
    setModifiers({
      ...modifiers,
      availability,
      scheduleDisable,
    });
    setLocalTripType(item)
    // setSelectedRateCard(item)
    setLocalTripTime(rec)
  }
  const handleCancelTime = () => {
    // setSelectedHour(selectedHour)
    setLocalTripTime(selectedHour)
    setShowTripTimeModal(!showTripTimeModal)
  }
  const validatePhone = (e) => {
    let phone = e;

    let isValid;
    if (phone) {
      isValid = isValidPhoneNumber(phone) ? true : false
    }

    if (phone === "" || !isValid) {
      return true;
    }

    else {
      return false
    }
  }

  useEffect(() => {
    if (referralGuideName && selectedRateCard) {
      if(selectedRateCard?.is_group) {
      setDepositAmtReferral(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.referral_percentage)
      setBsDepositAmtReferral(selectedRateCard?.group_percentage)
      setReferralId(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.guideguid)
      setReferralCompany(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.company)
      setReferralLocation(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.display_location)
      setReferralReview(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.rating)
      setReferralConfirmationName(`${referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.title} ${referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.guidename}`)
      setSelectedRefComp(referralGuideName.filter(a => a.group_guid === selectedRateCard.guideguid)?.[0]?.group_guid)
      }
      else {
        setDepositAmtReferral(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.referral_percentage)
        setBsDepositAmtReferral(0)
        setReferralId(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.guideguid)
        setReferralCompany(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.company)
        setReferralLocation(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.display_location)
        setReferralReview(referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.rating)
      setReferralConfirmationName(`${referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.title} ${referralGuideName.filter(a => a.guideguid === selectedRateCard.guideguid)?.[0]?.guidename}`)
      setSelectedRefComp(null)
      }
    }
  }, [referralGuideName, selectedRateCard])
  useEffect(() => {
    if (!selectedDay) {
      if (source === "rates") {
        setSelectedRateCard({});
        setSelectedHour({});
        setLocalTripType({});
        setLocalTripTime({});
      };

      let rateCards = []
      for (let item of schrecords?.ratecards) {
        item.disabled = false
        rateCards.push(item)
      }
      rateCards = rateCards.filter(item => guestCount <= item.maximum && item.rates)
      setRateCards(rateCards);
      setAvailableRateCards(rateCards);
      setSelectedDay('');

    }
    if (!isNarrow) {
      const allSCH = schrecords?.schedule
      const { availability, scheduleDisable } = getAvailabilityScheduledForAllRateCards({ "availableSchedules": allSCH });
      setModifiers({
        ...modifiers,
        availability,
        scheduleDisable,
        // availableSchedule: availability
        schedule: schrecords?.schedule
      });
    }
    getCancellationDate()
  }, [selectedDay])
  useEffect(() => {
    getCancellationDate()
  }, [selectedRateCard])

  const handleShowDatePicker = (data) => {
    setDateSelection(true)
    setShow(true)
    const allSCH = schrecords?.schedule
    const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ "availableSchedules": allSCH, hourCard: {}, "rateCard": data })
    setModifiers({
      ...modifiers,
      availability,
      scheduleDisable,
    });
    setLocalTripType(data)

  }
  const handleClearData = () => {
    setEnable(false)
    setCancellationDate('')
    setTimeSelection(false)
    setDateSelection(false)
    setLocalTripTime({})
    setLocalTripType({})
    setSelectedHour({})
    setMeeetingPointLocal(null)
    setMeeetingPoint(null)
  }
  const handleMeetingPoints = () => {
		if ((selectedRateCard && !selectedRateCard?.poi_guids?.includes(meeetingPoint?.poiguid)) || !meeetingPoint) {
			if (selectedRateCard?.poi_guids?.length === 1) {
				const filtered = context.schrecords?.poi?.filter(rec => rec.poiguid === selectedRateCard?.poi_guids[0])
				if (filtered.length > 0) {
					setMeeetingPoint(filtered[0])
					// setShowMP(true)
				}
			} else {
				setMeeetingPoint(meetingspotOptions?.[0])
			}
		}
	}
  const handleCheckRC=()=>{
    if ( selectedRateCard && Object.keys(selectedRateCard).length === 0) {
      // after date selection if user jumps to direct meeting point selection. based on meetingpoint selected filtering ratecards to be displayed under triptype
      const availRC = []
      for (let item of list) {
        for (let rec of item.poi_guids) {
					if (rec === meeetingPoint?.poiguid) {
							// based on meeting point filtering ratecard rates
							availRC.push({...item,"disabled":false})
					}else{
            availRC.push({...item,"disabled":true})
          }
				}
      }
      const uniq = [...new Set(availRC)];
      setRateCards(availRC)
      setShowTripTypeModal(true)
    }
  }
  const commonFlow = (e, rc) => {
    setSelectedDay(e);
    const selectedSchedule = availableSchedules.find(
      (schedule) =>
        schedule.date === moment(e).format('YYYY-MM-DD'),
    );
    let rateCards1 = getAllRateCardsWithDisabled(JSON.parse(JSON.stringify(rateCards)), selectedSchedule)
    rateCards1 = rateCards1.filter(item => guestCount <= item.maximum && item.rates)
    setRateCards(rateCards1);
    // setRateCards(
    //   rateCards1.filter(item => guestCount <= item.maximum && item.rates)
    // );

    // const filterdata = rateCards1.filter(item => guestCount <= item.maximum && item.rates)
    const final = rateCards1.filter(item => rc?.id === item?.id)
    setSelectedRateCard(final[0])
    setShow(false)
    setShowTripTypeModal(false)
    setDateSelectedInThisFlow(true)
    setDateSelection(false)
    getCancellationDate()
  }
  const handleSave = () => {
    // SaveRatecard(selectedData.ratecard, selectedData.check)
    setSelectedRateCard(localTripType || {});
    if (!localTripType.poi_guids.includes(meeetingPoint?.poiguid)) {
      setMeeetingPointLocal(null)
      setMeeetingPoint(null)
    }
    setSelectedHour({});
    if ((adult + child) > localTripType.maximum) {
      groupCount["Adults"].setCount(localTripType.maximum)
      groupCount["Children"].setCount(0)
      setGuestCount(localTripType.maximum)
    }
    // const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: {}, localTripType })
    // setModifiers({
    //   ...modifiers,
    //   availability,
    //   scheduleDisable,
    // });
    setShowTripTypeModal(false)
    setShowTripTimeModal(true)
    getCancellationDate()
  }
  const handleDateAfterTypeSelection = (e, dayPickerModifiers = {}) => {
    if (dayPickerModifiers.availability) {
      commonFlow(e, localTripType)
      setTimeSelection(false)
      setShowTripTimeModal(true)
    }
    if (
      dayPickerModifiers.scheduleDisable ||
      dayPickerModifiers.unavailablity
    ) {
      return null;
    }
    return null;
  }
  const handleRateAndTimeSelection = (e, dayPickerModifiers = {}) => {
    if (dayPickerModifiers.availability) {
      commonFlow(e, localTripType)
      setTimeSelection(false)
      setSelectedHour(localTripTime)
      setShowTripTimeModal(false)
    }
    if (
      dayPickerModifiers.scheduleDisable ||
      dayPickerModifiers.unavailablity
    ) {
      return null;
    }
    return null;
  }

  const handleSaveTime = () => {
    // const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ availableSchedules, hourCard: localTripTime, selectedRateCard })
    // setModifiers({
    //   ...modifiers,
    //   availability,
    //   scheduleDisable
    // });
    setSelectedHour(localTripTime || {})
    if (!localTripType.poi_guids.includes(meeetingPoint?.poiguid)) {
      setMeeetingPointLocal(null)
      setMeeetingPoint(null)
    }
    setSelectedRateCard(localTripType)
    setShowTripTimeModal(false)
    handleMeetingPoints()
  }

  const setTripDetailsContext = (type, value) => {
    switch (type) {
      case 'firstName': {
        setFirstName(value);
        setError({
          ...error,
          [type]: requireField(value),
        });
        break;
      }
      case 'lastName': {
        setLastName(value);
        setError({
          ...error,
          [type]: requireField(value),
        });
        break;
      }
      case 'email': {
        setEmail(value);
        setError({
          ...error,
          [type]: validateEmail(value),
        });
        break;
      }
      case 'phoneNumber': {
        setPhoneNumber(formatPhoneNumberIntl(value));
        setError({
          ...error,
          [type]: validatePhone(value),
        });
        break;
      }
      case 'preferedWaterbody':
        setPreferedWaterbody(value);
        break;
      case 'secondFavoriteWaterbody':
        setSecondFavoriteWaterbody(value);
        break;
      case 'meals':
        setMeals(value);
        break;
      case 'meeetingPoint':
        setMeeetingPointLocal(value);
        setMeeetingPoint(value);
        break;
      case 'guidesNotes':
        setGuidesNotes(value);
        break;
      case 'preferSelection':
        setPreferSelection(value);
        break;
      case 'needEquipments':
        setNeedEquipments(value);
        break;
      default:
        break;
    }
  };

  const handleDayClick = (e, dayPickerModifiers = {}) => {
    e.setHours(0, 0, 0, 0);
    let rateCards1 = []
    let selectedSchedule
    if (dayPickerModifiers.availability) {
      setSelectedDay(e);
      selectedSchedule = availableSchedules.find(
        (schedule) =>
          schedule.date === moment(e).format('YYYY-MM-DD'),
      );
      rateCards1 = getAllRateCardsWithDisabled(JSON.parse(JSON.stringify(schrecords?.ratecards)), selectedSchedule)
      rateCards1 = rateCards1.filter(item => guestCount <= item.maximum && item.rates)
      // setRateCards(
      //   rateCards1.filter(item => guestCount <= item.maximum && item.rates)
      // );
      setRateCards(rateCards1)
    }
    const filter = (selectedSchedule?.available_rates || []).filter(item => Number(selectedRateCard.id) === item.guide_ratecard_id)
    if (filter.length > 0) {
      const final = rateCards1.filter(item => selectedRateCard.id === item.id)
      setSelectedRateCard(final[0])
      const filterRates = (selectedSchedule?.available_rates || []).filter(item => Number(selectedRateCard.id) === item.guide_ratecard_id && Number(selectedHour.id) === item.id)
      if (filterRates.length > 0) {
        setShowTripTimeModal(false)
      } else {
        setSelectedHour({})
        setShowTripTimeModal(true)
      }
      setShowTripTypeModal(false)
      setDateSelectedInThisFlow(true)
    } else {
      setSelectedRateCard({});
      setSelectedHour({});
      setLocalTripType({})
      setLocalTripTime({})
      setShowTripTypeModal(true)
      setDateSelectedInThisFlow(true)
    }

    if (
      dayPickerModifiers.scheduleDisable ||
      dayPickerModifiers.unavailablity
    ) {
      return null;
    }
    return null;
  };

  const handleDatepickerClose = () => {
    setShow(!show)
    setShowTripTypeModal(true)
    setLocalTripType(selectedRateCard)
    setLocalTripTime(selectedHour)
  }
  const totalCharge = getTotalCharge(
    selectedRateCard,
    guestCount,
    selectedHour.rate,
    addOnFees
  );

  let fees = addOnFees.map(item => isNaN(item.charge_price) ? 0 : item.charge_price);
  fees = fees.reduce((a, b) => a + b, 0)

  let totalTripCost = selectedHour?.rate ? fees + totalCharge : 0

  const handlePredefined = (data) => {
    if (selectedHour?.rate) {
      // tripDetailsContext.setTipamount((totalTripCost * data).toFixed(2))
      // tripDetailsContext.setTipType("Predefined")
      setLocalTip({
        tipamount: (totalTripCost * data).toFixed(2), tiptype: "Predefined", "tipPercent": `${data * 100}%`,
        "tipCost": totalTripCost,
      })
      // setTippercentage(`${data * 100}%`)
      setTotalCost(totalTripCost)
    } else {
      // tripDetailsContext.setTipamount((0).toFixed(2))
      // tripDetailsContext.setTipType("Predefined")
      setLocalTip({ tipamount: (0).toFixed(2), tiptype: "Predefined", "tipPercent": `${0 * 100}%`, "tipCost": totalTripCost, })
      // setTippercentage(`${0 * 100}%`)
      // tripDetailsContext.setTipamount(0)
      setTotalCost(0)
    }
  }

  // useEffect(() => {
  //   if (totalTripCost) {
  //     tripDetailsContext.setTipamount(((totalTripCost * tippercentage.split('%')[0]) / 100).toFixed(2))
  //   }
  // }, [tippercentage])
  useEffect(() => {
    // handlePredefined(0.20)
    // tripDetailsContext.setTipamount(((totalTripCost * 20) / 100).toFixed(2))
    tripDetailsContext.setTipamount("uponCash")
    tripDetailsContext.setTipType("Cash Tip on Trip")
    setLocalTip({ tipamount: 'uponCash', tiptype: "Cash Tip on Trip" })
    if (isBrowser) {
      window.scrollTo(0, 0)
    }
    getCancellationDate()
  }, [])
  const processPaymentToken = async (paymentToken, paymentMethod) => {
    const formData = {};

    const {
      data: { token },
      status,
    } = await post(
      'auth/loginguest',
      {
        email: tripDetailsContext.email,
        firstname: tripDetailsContext.firstName,
        lastname: tripDetailsContext.lastName || '',
        phone: tripDetailsContext.phoneNumber,
      },
      {
        headers: {
          'x-app-id': xAppID,
        },
      },
    );
    const W_id = tripDetailsContext.selectedRateCard?.waiver ? tripDetailsContext.selectedRateCard?.waiver?.id : null
    const vals = {
      guideguid: selectedRateCard.guideguid,
      referral_guideguid: selectedRateCard.is_referral ? referralMainId : null,
      date: moment(new Date()).format('YYYY-MM-DD'),
      tripdate: moment(
        new Date(tripDetailsContext.selectedDay),
      ).format('YYYY-MM-DD'),
      starttime: moment(tripDetailsContext.selectedHour.start_time, ["HH:mm:ss"]).format("h:mm a"),
      triptype: tripDetailsContext.selectedRateCard.ratetype,
      tripcost: tripDetailsContext.selectedHour.rate,
      bookingrate: `${tripDetailsContext.selectedHour.rate}`,
      guidegear: true,
      notes: tripDetailsContext.guideNotes,
      currency: tripDetailsContext?.userData?.currency?.toString()?.toLowerCase() || 'usd',
      secondperson: tripDetailsContext.guestCount > 1,
      river1: tripDetailsContext.preferedWaterbody?.riverid,
      river1name: tripDetailsContext.preferedWaterbody?.rivername,
      river2: tripDetailsContext.secondFavoriteWaterbody?.riverid,
      river2name:
        tripDetailsContext.secondFavoriteWaterbody?.rivername,
      customer_firstname: tripDetailsContext.firstName,
      customer_lastname: tripDetailsContext.lastName,
      customer_phone: tripDetailsContext.phoneNumber,
      customer_phone: String(tripDetailsContext.phoneNumber).replace(/\D+/g, "").slice(-10),
      customer_country_phone_code: '+' + parsePhoneNumber(String(tripDetailsContext.phoneNumber)).countryCallingCode,
      customer_country_iso_code: parsePhoneNumber(String(tripDetailsContext.phoneNumber))?.country,
      customer_email: tripDetailsContext.email,
      customer_zipcode: zip,
      guestcount: tripDetailsContext.guestCount,
      mealguid: tripDetailsContext.meals.guid === 'select' ? null : tripDetailsContext.meals.guid,
      poiguid: tripDetailsContext.meeetingPoint?(tripDetailsContext.meeetingPoint || {})?.poiguid:null,
      meal: tripDetailsContext.meals.guid === 'select' ? null : tripDetailsContext.meals.name,
      meetingPoint: tripDetailsContext.meeetingPoint?(tripDetailsContext.meeetingPoint || {}).name:null,
      fishingType: tripDetailsContext.selectedRateCard.rateType,
      rateCardId: tripDetailsContext.selectedRateCard.id,
      hourCardId: tripDetailsContext.selectedHour.id,
      scheduleId: tripDetailsContext.selectedRateCard.scheduleId,
      origin,
      guide_addon_fees: tripDetailsContext.addOnFees,
      cash_tip_upon_trip: tripDetailsContext.tipamount && tripDetailsContext.tipamount === 'uponCash',
      tip_amount: tripDetailsContext.tipamount && tripDetailsContext.tipamount !== 'uponCash' ? (Number(tripDetailsContext.tipamount)).toFixed(2) : 0,
      tip_type: tripDetailsContext.tipType != "" ? tripDetailsContext.tipType : null,
      is_multi_tenant: isMultiTenantGuide && companyGuid && companyGuid != "" ? true : false,
      waiver_id: W_id,
      consumer_add_on : addOnCheck&& Object.keys(addOnCheck).filter(key => addOnCheck[key]),
      consumer_addon_charge: parseFloat(addonAmt)
    };

    const tipAmount = tipamount && tipamount !== 'uponCash' ? Number(tipamount) : 0
    const total = getTotalCharge(
      tripDetailsContext.selectedRateCard,
      tripDetailsContext.guestCount,
      tripDetailsContext.selectedHour.rate,
    );

    let fees = tripDetailsContext.addOnFees.map(item => isNaN(item.charge_price) ? 0 : item.charge_price);
    fees = fees.reduce((a, b) => a + b, 0)

    const totalIncludingMatesFees = total + fees + tipAmount+addonAmt
    const deviceData = {
      device: getUA(),
      deviceVersion: deviceVersion,
      deviceFamily: isMobile ? "Mobile" : "Web",
      userLocation: null,
      client_ip: paymentToken?.client_ip

    }
    const isSharedTrip = tripDetailsContext.selectedRateCard && tripDetailsContext.selectedRateCard?.is_shared_trip
    let custom__deposit =  checkCompany ? 
                          tripDetailsContext.selectedRateCard?.custom_deposit_percentage>0 ? tripDetailsContext.selectedRateCard?.custom_deposit_percentage/100 : (depositAmt / 100): 
                          tripDetailsContext.selectedRateCard?.custom_deposit_percentage>0 ? tripDetailsContext.selectedRateCard?.custom_deposit_percentage/100  : 0.2
    const chargeToday =  ((total + fees+addonAmt) * (tripDetailsContext.selectedRateCard?.is_referral ? 0.2 : custom__deposit)).toFixed(2);
    const chargeReferral = depositAmtReferral ? ((total + fees+addonAmt) * (depositAmtReferral / 100)).toFixed(2) : 0
    const bsChargeReferral = bsDepositAmtReferral ? ((total + fees+addonAmt - parseFloat(((total + fees+addonAmt) * 0.2))- chargeReferral) * (bsDepositAmtReferral / 100)).toFixed(2) : 0
    formData.cash_tip_upon_trip = tripDetailsContext.tipamount && tripDetailsContext.tipamount === 'uponCash'
    formData.tip_amount = tripDetailsContext.tipamount && tripDetailsContext.tipamount !== 'uponCash' ? (Number(tripDetailsContext.tipamount)).toFixed(2) : 0;
    formData.paymentToken = paymentToken;
    formData.payment_method = paymentMethod;
    formData.charge_amount = chargeReferral ? parseFloat(Number(chargeToday) + Number(chargeReferral)+Number(bsChargeReferral)).toFixed(2) : chargeToday;
    formData.total_amount = totalIncludingMatesFees;
    formData.trip = vals;
    formData.deviceData = deviceData;
    if(selectedRateCard?.is_referral && selectedRefComp && selectedRefComp!='') {
      formData.trip.bs_affiliate_data={
        "group_guid" : selectedRefComp
      }
    }
    if (isMultiTenantGuide && companyGuid && companyGuid != "") {
      if (selectionType?.type === "Guide") {
        formData.trip.guideguid = selectionType?.guid
      } else if (selectedRateCard.is_referral) {
        formData.trip.guideguid = selectedRateCard.guideguid
      } else {
        formData.trip.boatguid = selectionType?.guid
        delete formData.trip.guideguid
      }
      formData.trip.group_guid = companyGuid
    } else {
      delete formData.trip.boat_guid
      delete formData.trip.group_guid
    }
    tripDetailsContext.setUserToken(token)
    if (status === 200) {
      post('payment/charge_token_v2', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((responseData) => {
          if (responseData.data.trip_guid) {
            tripDetailsContext?.setTripguid(responseData.data.trip_guid)
            tripDetailsContext?.setWaiver(responseData.data.waiver_data)
            next();
            // Make sure we're in the browser
            // if (isBrowser) {
            //     router.push('/payment-confirmation');
            //   return; 
            // }
            tripDetailsContext.setBookingID(responseData.data.booking_ref)
            setLoading(false);
          } else {
            setError({
              ...error,
              paymentError: 'Something went wrong. Please try again',
            });
            setLoading(false);
          }
          setPaymentStarted(false);
        })
        .catch((err) => {
          setError({
            ...error,
            paymentError:
              'Something went wrong. Please try again',
          });
          setLoading(false);
          setPaymentStarted(false);
        });
    } else {
      setError({
        ...error,
        paymentError: 'Something went wrong. Please try again',
      });
      setLoading(false);
      setPaymentStarted(false);
    }
  };
  const handleFirstNameChange = (e) => {
    let fn = e.target.value
    fn=fn.replace(/[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-/'"|]/g,'');
    if (fn === "" || fn.trim().length === 0) {
      tripDetailsContext.setRequiredFn(true)
    } else {
      tripDetailsContext.setRequiredFn(false)
    }
    setTripDetailsContext('firstName', fn)
  }


  const handleLastNameChange = (e) => {
    let ln = e.target.value
    ln=ln.replace(/[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-/'"|]/g,'');
    tripDetailsContext.setRequiredLn(false)
    if (ln.trim().length === 0) {
      tripDetailsContext.setRequiredLn(true)
    } else {
      tripDetailsContext.setRequiredLn(false)
    }
    setTripDetailsContext('lastName', ln)
  }
  const validateEmail = (e) => {
    const email = e;
    var pattern = new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
    if (email.length === 0) {
      setIsValidEmail(false)
      tripDetailsContext.setRequiredEmail(false)
    }
    else if (!pattern.test(email)) {
      setIsValidEmail(true)
      tripDetailsContext.setRequiredEmail(true)
      return;
    }
    else {
      tripDetailsContext.setRequiredEmail(false)
      setIsValidEmail(false)
    }
  }

  const handleEmailChange = (e) => {
    const email = e.target.value
    setTripDetailsContext('email', email)

    if (email === "") {
      tripDetailsContext.setRequiredEmail(true)
    } else {
      tripDetailsContext.setRequiredEmail(false)
    }
    validateEmail(email)
  }
  const handlePayment = async () => {
    const tripDetail = selectedDay === null || Object.keys(selectedRateCard).length === 0 || Object.keys(selectedHour).length === 0
    const check = firstName === "" || !firstName || lastName === "" || !lastName || email === "" || !email || phoneNumber === "" || !phoneNumber || inCorrect || isValidEmail
    const hasError = (cardNumberError !== "" || !cardNumber) || (cardExpiryError !== "" || !cardExpiry) || (cardCVCError !== "" || !cardCVC) || (zipCodeError !== "" || !zip)
    if (hasError || check || tripDetail || isGuestCountMaxed) {
      if(isGuestCountMaxed) {
        setStatus(false)
        document.getElementById('tripData')?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
      if (!isGuestCountMaxed && tripDetail) {
        setStatus(true)
        if (selectedDay === null)
          setErrorMsg('Trip Detail section is not complete, please select trip date')
        else
          if (selectedRateCard.title === null || selectedRateCard.title === undefined)
            setErrorMsg('Trip Detail section is not complete, please select trip type')
          else
            setErrorMsg('Trip Detail section is not complete, please select trip time and length')
        document.getElementById('tripData')?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
      if (check && !tripDetail && !isGuestCountMaxed) {
        if (!firstName || firstName === "") {
          tripDetailsContext.setRequiredFn(true)
        }
        if (!lastName || lastName === "") {
          tripDetailsContext.setRequiredLn(true)
        }
        if (!email || email === "") {
          tripDetailsContext.setRequiredEmail(true)
        }
        if (!tripDetailsContext.phone || tripDetailsContext.phone === "") {
          tripDetailsContext.setRequiredPhone(true)
        }
        setStatus(true)
        setErrorMsg('My Info section is not complete')
        document.getElementById('myInfo')?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
      if (!check && !tripDetail && !isGuestCountMaxed && hasError) {
        if (cardNumberError === "" && !cardNumber) {
          setCardNumberError('Please enter valid card number')
        }
        if (cardExpiryError === "" && !cardExpiry) {
          setCardExpiryError('Please enter valid expiration date')
        }
        if (cardCVCError === "" && !cardCVC) {
          setCardCVCError('Please enter valid cvv number')
        }
        if (zipCodeError === "" && !zipCode) {
          setZipCodeError('Please enter zip code')
        }
        setStatus(true)
        setErrorMsg('Payment details is not complete')
        document.getElementById('paymentdetails')?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
    } else {
      const cardElement = elements.getElement(CardNumberElement);
      setStatus(false)
      setError({ ...error, paymentError: '' });
      setLoading(true);
      setPaymentStarted(true);

      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });


      if (payload.error !== undefined) {
        /* setErrorTitle(`${payload.error.code}`); */
        setError({ ...error, paymentError: payload.error.message });
        setLoading(false);
        setPaymentStarted(false);
      } else {
        setPaymentCardDetails(payload.paymentMethod.card)
        const cardToken = await stripe.createToken(cardElement);
        await processPaymentToken(cardToken.token, 'CARD');
      }
    }
  };
  const validatePhone1 = (e) => {
    let phone = e;
    let isValid = phone && isValidPhoneNumber(phone) ? true : false

    if (phone === "" || !isValid) {
      tripDetailsContext.setRequiredPhone(false);
      setInCorrect(true)
      setInValidPhone(true)
      return;
    }
    else {
      tripDetailsContext.setRequiredPhone(false);
      setInCorrect(false)
      setInValidPhone(false)
    }
  }

  // useEffect(() => {
  //   handlePredefined(0.20)
  //   if (isBrowser) {
  //     window.scrollTo(0, 0)
  //   }
  // }, [])
  let total;
  let chargeToday;
  let chargeOnDayOfTrip;

  if (selectedHour.rate) {
    total = getTotalCharge(
      selectedRateCard,
      guestCount,
      selectedHour.rate,
      addOnFees
    );

    const sharedTrip = selectedRateCard && selectedRateCard?.is_shared_trip
    let customdeposit =  selectedRateCard?.custom_deposit_percentage ? selectedRateCard?.custom_deposit_percentage/100 :  0.2
    chargeToday = checkCompany ? ((total) * (depositAmt / 100)).toFixed(2) : ((total) * customdeposit).toFixed(2);

    chargeOnDayOfTrip = total - chargeToday;
  }

  const getCancellationDate = async () => {
    if (selectedDay) {
      const startdate = moment(selectedDay);
      const date = isMultiTenantGuide? cancellationdays:selectedRateCard?.allowed_cancellation_days
      const newDate = moment(selectedDay).subtract(date, "days")
      const enable = moment(newDate).isBefore(startdate) && moment(newDate).isAfter(moment())
      setEnable(enable)
      setCancellationDate(moment(newDate).format("MMM Do, YYYY"));
    } else {
      setEnable(false)
    }
  }
  const renderGuests = (title, subTitle) => {
    return (
      <div key={title} className={classes.guests__container} style={{ padding: "10px 0px", borderBottom: "none" }}>
        <div className={classes.guests__text}>
          <div style={{ fontSize: "14px", fontWeight: 'normal' }}>{title}</div>
          <div style={{ fontSize: "12px", fontWeight: 'normal' }}>{subTitle}</div>
        </div>
        <div className={classes.guests__button}>
          <button type="button" id="group-size-decrement"
            className={classes.guests__button__icon}
            disabled={(adult + child) === 1 ? true : false}
            style={(adult + child) === 1 ? { opacity: "0.5" } : null}
            onClick={() => groupCount[title].count > groupCount[title].min && groupCount[title].setCount(groupCount[title].count - 1)}>
            <Icon icon={minus} size={12} />
          </button>
          <span className={classes.form__guest__count}>{groupCount[title].count}</span>
          <button type="button" id="group-size-increment"
            disabled={(capacity) <= (adult + child) ? true : false}
            style={(capacity) <= (adult + child) ? { opacity: "0.5" } : null}
            className={classes.guests__button__icon}
            onClick={() => groupCount[title].setCount(groupCount[title].count + 1)}>
            <Icon icon={plus} size={12} />
          </button>
        </div>
      </div>
    )
  }

  var activateBtn = paymentStarted || error.cardNumber !== "" || error.cvv !== "" || error.cardExpiry !== "" || zip === ""

  const disabled = !(firstName && lastName && email && phoneNumber) || !isValidPhoneNumber(phoneNumber) || !(total && chargeToday && chargeOnDayOfTrip) || !selectedDay
  const renderListItems = (item, rec, i, j) => {
    return (
      <div
        key={j}
        style={{
          display: 'flex', borderBottom: "1px solid #42642f",
          width: "100%",
          margin: "5px 0px 15px", paddingBottom: 8,
        }}
      >
        <img onClick={() => {
          if (!item.disabled) {
            if (selectedDay) {
              setLocalTripTime(rec)
              setLocalTripType(item)
            } else {
              handleRateNTime(item, rec)
            }
          }
        }} src={localTripTime?.id === rec?.id ? checked : unchecked} style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          marginRight: 10,
          height: 20, width: 20, border: '1px solid grey'
        }} width="20" height="20"/>
        <div
          onClick={() => {
            if (!item.disabled) {
              if (selectedDay) {
                setLocalTripTime(rec)
                setLocalTripType(item)
              } else {
                handleRateNTime(item, rec)
              }
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          {convertTo12Hour(rec.start_time)} {getDuration(rec)} {' for '} {displayMoney(rec.rate)} {' '} {(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}
        </div>
      </div>
    )
  }
  return (
    <Grid container className={classes.container}>
      {
        isNarrow ?
          <>
            {selectedRateCard && Object.keys(selectedRateCard).length ? <div className="ImageContainerCheckout" style={{ background: "white" }}>
              {selectedRateCard.coverpicture ? <img src={`${cloudfront}${selectedRateCard.coverpicture}`} /> : null}
              <div style={{ width: "calc(100% - 124px)", position:"relative" }}>
                <div>
                {selectedRateCard?.title ? <h6>{selectedRateCard?.title}</h6> : null}
                <p>{selectedRateCard.is_referral ? `${referralCompany} (Booking Partner of ${schrecords?.company})`: schrecords?.company}</p>
                </div>
                {selectedRateCard.is_referral ?
                <p className="location"><span>{referralReview && referralReview != "0.00" && <><Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(referralReview).toFixed(1)}</>}</span><span className={referralReview && referralReview != "0.00" && "techlabel"}>{referralLocation ? referralLocation : ''}</span></p>:
                 <p className="location"><span>{schrecords.rating && schrecords.rating != "0.00" && <><Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(schrecords.rating).toFixed(1)}</>}</span><span className={schrecords.rating && schrecords.rating != "0.00" && "techlabel"}>{schrecords?.display_location ? schrecords?.display_location : ''}</span></p>}
              </div>
            </div> :
              <div className="ImageContainerCheckout" style={{ background: "white" }}>
                <div style={{ width: "100%" }}>
                  <p>{`${schrecords?.company}`}</p>
                  <p className="location">
                  <span>{selectedRateCard.is_referral ? (referralReview && referralReview != "0.00" && <><Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(referralReview).toFixed(1)}</>) : (schrecords.rating && schrecords.rating != "0.00" && <><Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(schrecords.rating).toFixed(1)}</>)}</span>
                  {selectedRateCard.is_referral ? <span className={referralReview && referralReview != "0.00" && "techlabel"}>{referralLocation ? referralLocation : ''}</span>:<span className={schrecords.rating && schrecords.rating != "0.00" && "techlabel"}>{schrecords?.display_location ? schrecords?.display_location : ''}</span>}
                  </p>
                </div>
              </div>}
          </> : null
      }
      <Grid container>
        <Grid item xs={12} sm={12} md={isLCal ? 6 : 6} lg={isLCal ? 6 : 6}>
          {isMultiTenantGuide && <div>
            <p className={classes.breadcrumb}>
              <span className={classes.bdtext} onClick={() => tripsPage()}>Trips</span>
              {bsAvailability?.settings?.choose_vehicle || bsAvailability?.settings?.choose_guide && <>
                <Icon icon={chevronRight} size={14} className={classes.rightIcon} />
                <span className={classes.bdtext} onClick={() => previous()}>Guide or Boat</span>
              </>
              }
              <Icon icon={chevronRight} size={14} className={classes.rightIcon} />
              <span style={{ color: "gray" }}>Checkout</span>
            </p>
          </div>}
          <div style={{ marginBottom: 5, marginTop: 5, display: 'flex', justifyContent: 'space-between' }} >
            <Typography style={{ fontSize: 18, fontWeight: 'bolder' }} id="tripData">
              Trip Details
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={isLCal ? 6 : 6} lg={isLCal ? 6 : 6}>
          <Grid container>
            <Grid style={{ borderRadius: 5 }} item xs={12} sm={12} md={12} lg={12}>
              <>
                <Grid style={{ borderRadius: 5, padding: "10px 0px" }} className={`${classes.rateCardContainer} mtrateCardContainer`} item xs={12} sm={12} md={12} lg={12}>
                  <div className={`${classes.TripDateHolder} mtTripDateHolder`}>
                    <div>
                      <p>Date </p>
                      <span className={classes.TripDate}>{selectedDay ? moment(selectedDay).format("MMM DD, YYYY") : "Choose Trip Date"}</span>

                    </div>
                    <div>
                      <span className={classes.EditText} onClick={handleCalendar} >Edit</span>
                    </div>
                  </div>
                  <div className={`${classes.TripDateHolder} mtTripDateHolder`}>
                    <div>
                      <p>Guests </p>
                      {/* <span className={classes.TripDate}>1 guest</span> */}
                      <span className={classes.TripDate}>{guestCount > 1 ? `${guestCount} guests` : `${guestCount} guest`}</span>

                    </div>
                    <div>
                      <span className={classes.EditText} onClick={() => setGuestModal(!guestModal)} >Edit</span>
                    </div>
                  </div>
                  <div className={`${classes.TripDateHolder} mtTripDateHolder`}>
                    <div>
                      <p>Trip Type </p>
                      <span className={classes.TripDate}>{selectedRateCard.title ?? 'Add Trip'}</span>
                    </div>
                    <div>
                      <span className={classes.EditText} onClick={() => setShowTripTypeModal(!showTripTypeModal)}>Edit</span>
                    </div>
                  </div>
                  <div className={`${classes.TripDateHolder} mtTripDateHolder`}>
                    <div>
                      <p>Start Time & Length  </p>
                      <span className={classes.TripDate}>{selectedRateCard?.id && selectedHour?.start_time ? <span>{convertTo12Hour(selectedHour.start_time)}{' '}
                        ({getDuration(selectedHour)} trip) {' '}-{' '}
                        {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney(selectedHour?.rate)}`}</span> : 'Add Trip Time'}</span>
                    </div>
                    <div>
                      <span className={classes.EditText} onClick={() => setShowTripTimeModal(!showTripTimeModal)}>Edit</span>
                    </div>
                  </div>
                  {meetingspotOptions?.length>0 && 
                 <div className={`${classes.TripDateHolder} mtTripDateHolder`}>
              <div>
                  <p>Meeting Location</p>
                  <span className={classes.TripDate}>{meeetingPoint?.description ? `${meeetingPoint?.description}` : 'Add Meeting Point'}</span>
                </div>
                <div>
                  <span className={classes.EditText} onClick={() => setShowMP(!showMP)}>Edit</span>
                </div>
              </div>}
                  <Grid container style={{ padding: '5px 25px', }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div
                        style={{ display: 'flex', paddingTop: 5, paddingBottom: 5, marginTop: 5, marginBottom: 5 }}
                      >
                        <img src={needEquipments ? checked : unchecked}
                          onClick={() => {
                            setTripDetailsContext('needEquipments', !needEquipments)
                          }}
                          style={{
                            margin: 0,
                            padding: 0,
                            borderRadius: 0,
                            marginRight: 10,
                            height: 20, width: 20, border: '1px solid grey'
                          }} width="20" height="20"/>
                        <span className='mtSelection'>
                          Need guide equipment
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Grid>
            {consumerAddOn?.length>0 && 
            <Grid style={{ marginTop: 15, marginBottom: 15 }} item xs={12} sm={12} md={12} lg={12} id="myInfo">
              <Typography style={{ marginBottom: 5, marginTop: 5, fontWeight: 'bolder', fontSize: 18 }}>Optional Add-Ons</Typography>
              <Grid item style={{ borderRadius: 5,padding:25}} className={classes.rateCardContainer}>
              {consumerAddOn.map((item,index) => {
										return(
											<div key={index} className={classes.CustomInput} >
												<img
												src={addOnCheck[item.id] ? checked :unchecked}
												onClick={() => setAddonCheck({...addOnCheck,[item.id] : !addOnCheck[item.id]})}
												style={{
													padding: 0,
													margin: 0,
													borderRadius: 0,
													marginRight: 10,
													height: 19, width: 19, border: '1px solid grey',
													cursor: 'pointer'
												}} width="19px" height="19px" />
												<span style={{marginRight:5}}>{item.title}</span><span style={{marginRight:5}}>{`- ${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney(item.charge_value)}`}</span><span>{item?.duration?.is_entire_trip ? "Per Entire Trip" : item?.duration?.days ? "Per Day" : item?.duration?.hours ? "Per Hour" : "Per 30 Minutes"}</span>
											</div>)
										})
									}
              </Grid>
            </Grid>}
            <Grid style={{ marginTop: 15, marginBottom: 15 }} item xs={12} sm={12} md={12} lg={12} id="myInfo">
              <Typography style={{ marginBottom: 5, marginTop: 5, fontWeight: 'bolder', fontSize: 18 }}>My Info</Typography>
              <Grid item style={{ borderRadius: 5 }} className={classes.rateCardContainer}>
                <div style={{ padding: '0 25px ', margin: '0px 0', borderBottom: '1px solid #c7cbce' }} >
                  <div className={"RowData"}>
                    <input
                      type="text"
                      onChange={(e) => handleFirstNameChange(e)}
                      value={firstName}
                      minLength="3"
                      maxLength="20"
                      id="first-name"
                      className={`${"inputField"} mtinputField`}
                      autocomplete="off"
                    />
                    <span className={firstName ? `${"floatingLabel1"} floatingLabel1 mtinputField` : `${"floatingLabel"} floatingLabel`}>First Name</span>
                    <span className={"ErrorCont"}>
                      {tripDetailsContext.requireFn ? <span className={"errorMsg"}>First Name is required*</span> : null}
                    </span>
                  </div>
                </div>
                <div style={{ padding: '0 25px ', margin: '0px 0', borderBottom: '1px solid #c7cbce' }} >
                  <div className={"RowData"}>
                    <input
                      type="text"
                      onChange={(e) =>
                        handleLastNameChange(e)
                      }
                      value={lastName}
                      minLength="3"
                      maxLength="20"
                      id="last-name"
                      className={`${"inputField"} mtinputField`}
                      autocomplete="off"
                    />
                    <span className={lastName ? `${"floatingLabel1"} floatingLabel1 mtinputField` : `${"floatingLabel"} floatingLabel`}>Last Name</span>
                    <span className={"ErrorCont"}>
                      {tripDetailsContext.requireLn ? <span className={"errorMsg"}>Last Name is required*</span> : null}
                    </span>
                  </div>
                </div>
                <div style={{ padding: '0 25px ', margin: '0px 0', borderBottom: '1px solid #c7cbce' }} >
                  <div className={"RowData"}>
                    <input
                      type="text"
                      onChange={(e) =>
                        handleEmailChange(e)
                      }
                      value={email}
                      id="email"
                      className={`${"inputField"} mtinputField`}
                      autocomplete="off"
                    />
                    <span className={email ? `${"floatingLabel1"} floatingLabel1 mtinputField` : `${"floatingLabel"} floatingLabel`}>Email</span>
                    <span className={"ErrorCont"}>
                      {tripDetailsContext.requireEmail ? <span className={"errorMsg"}>Email is required*</span> : null}
                    </span>
                  </div>
                </div>
                <div style={{ padding: '0 25px ', margin: '12px 0' }} >
                  <div className={'labelHolder'} >
                    <Typography style={{ minWidth: 90 }}>Phone Number<span className={'redAsterisk'}>{` *`}</span></Typography>
                    <FormHelperText className={`${classes.errorMsg} errorLabels`} id="phone-number">
                      {tripDetailsContext.requirePhone ? <span>Phone is required*</span> : null}
                      {inCorrect ? <span >Please provide a valid Phone number*</span> : null}
                    </FormHelperText>
                  </div>
                  <FormControl
                    className={classes.form}
                    error={error.phoneNumber}
                  >
                    <PhoneInput
                      type="tel"
                      placeholder="(000) 000-0000"
                      defaultCountry={"US"}
                      countryCallingCodeEditable={false}
                      value={phoneNumber ? phoneNumber : ""}
                      initialValueFormat="national"
                      onChange={(e) => {
                        validatePhone1(e)
                        setTripDetailsContext('phoneNumber', e)
                      }}
                      minLength={8}
                      maxLength={15}
                      required
                      className={`${classes.Phone_Input} phonenumberPlaceholder`}
                    />

                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <Grid style={{ marginTop: 15, marginBottom: 15 }} item xs={12} sm={12} md={12} lg={12}>

              <div style={{ width: "100%" }}>
                <p className="mtColor" style={{ marginBottom: 5, marginTop: 5, }}> <strong>Tips & Discounts</strong></p>
                <div className={`mtInformation`} style={{ padding: "10px 0px" }}>
                  <div className={classes.TripDateHolder} >
                    <div>
                      <p>Pre Tip </p>
                      <span className={classes.TripDate}>
                        {!isNaN(tripDetailsContext.tipamount) && tripDetailsContext.tipamount && Number(tripDetailsContext.tipamount) !== 0 ? `${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${tripDetailsContext.tipamount} (${tripDetailsContext.tipType === 'Custom' ? Number.parseFloat(tripDetailsContext.tipamount * 100 / totalTripCost).toFixed(2) : Math.round(tripDetailsContext.tipamount * 100 / totalTripCost)}%)` : `${tripDetailsContext.tipType === 'Cash Tip on Trip' ? 'Cash Tip on Trip' : tripDetailsContext.tipType === 'Custom' ? `${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}0` : 'Add Tip Amount'}`}
                      </span>
                    </div>
                    <div>
                      <span className={classes.EditText} onClick={() => setShowTip(!showTip)}>Edit</span>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid style={{ marginTop: 15, marginBottom: 15 }} item xs={12} sm={12} md={12} lg={12} id="paymentdetails">
              <Typography style={{ marginBottom: 5, marginTop: 5, fontWeight: 'bolder', fontSize: 18 }}>Card Details</Typography>
              <div style={{ borderRadius: 5 }} className={classes.rateCardContainer}>
                <div style={{ padding: '12px 25px ', borderBottom: '1px solid #c7cbce' }}>
                  <Typography>Card Number <span className={'redAsterisk'}>*</span></Typography>
                  <FormControl
                    className={classes.form}
                    error={Boolean(error.cardNumber)}
                  >
                    <CardNumberElement
                      options={options}
                      onChange={event => {
                        if (event.error) {
                          setCardNumberError(event.error.message);
                          setCardNumber(false);
                        } else if (event.empty) {
                          setCardNumberError('Please enter valid card number');
                          setCardNumber(false);
                        } else if (event.complete) {
                          setCardNumber(true);
                          setCardNumberError('');
                        } else {
                          setCardNumberError('');
                          setCardNumber(false);
                        }
                      }}
                      className={classes.stripeElement}
                    />
                    {
                      cardNumberError === "" ? null : <p className='cardErrors'>{cardNumberError}</p>
                    }
                  </FormControl>
                </div>

                <div style={{ padding: '12px 25px ', borderBottom: '1px solid #c7cbce' }}>
                  <Typography>Expiration Date <span className={'redAsterisk'}>*</span></Typography>
                  <FormControl
                    className={classes.form}
                    error={Boolean(error.cardExpiry)}
                  >
                    <CardExpiryElement
                      options={options}
                      onChange={event => {
                        if (event.error) {
                          setCardExpiryError(event.error.message);
                          setCardExpiry(false);
                        } else if (event.empty) {
                          setCardExpiryError('Please enter valid expiration date');
                          setCardExpiry(false);
                        } else if (event.complete) {
                          setCardExpiry(true);
                          setCardExpiryError('');
                        } else {
                          setCardExpiryError('');
                          setCardExpiry(false);
                        }
                      }}
                      className={classes.stripeElement}
                    />
                    {
                      cardExpiryError === "" ? null : <p className='cardErrors'>{cardExpiryError}</p>
                    }
                  </FormControl>
                </div>

                <div style={{ padding: '12px 25px ', borderBottom: '1px solid #c7cbce' }} >
                  <Typography>CVC/CVV <span className={'redAsterisk'}>*</span></Typography>
                  <FormControl className={classes.form} error={Boolean(error.cvv)}>
                    <CardCvcElement
                      options={options}
                      onChange={event => {
                        if (event.error) {
                          setCardCVCError(event.error.message);
                          setCardCVC(false);
                        } else if (event.empty) {
                          setCardCVCError('Please enter valid cvv number');
                          setCardCVC(false);
                        } else if (event.complete) {
                          setCardCVC(true);
                          setCardCVCError('');
                        } else {
                          setCardCVCError('');
                          setCardCVC(false);
                        }
                      }}
                      className={classes.stripeElement}
                    />
                    {
                      cardCVCError === "" ? null : <p className='cardErrors'>{cardCVCError}</p>
                    }
                  </FormControl>
                </div>
                <div style={{ padding: '12px 25px ', }}>
                  <Typography>Billing Zip Code<span className={'redAsterisk'}>*</span></Typography>
                  <FormControl
                    className={classes.form}
                  >
                    <TextField

                      value={zip}
                      variant="standard"
                      placeholder='12345'
                      className={`${classes.placeholder} inputPlaceholder`}
                      onChange={(e) => {
                        setZip(e.target.value)
                        if (e.target.value === "") {
                          setZipCodeError("Please enter zip code");
                        } else {
                          setZipCodeError('');
                        }
                      }
                      }
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ style: { height: "30px", padding: 0 }, className: classes.placeholder }}
                    />
                    {
                      zipCodeError === "" ? null : <p className='cardErrors'>{zipCodeError}</p>
                    }
                  </FormControl>
                </div>
              </div>
            </Grid>

            {
              enable && selectedDay != null &&

              <Grid style={{ marginTop: 15, marginBottom: 15 }} item xs={12} sm={12} md={12} lg={12}>
                <div style={{ borderRadius: 5 }} className={classes.rateCardContainer}>
                  <div style={{ padding: '0 25px ', margin: '12px 0', }}>
                    <div className='Cancellation'><h1>Cancellation Policy</h1></div>

                    {/* <p className='CancellationDescription'><b  >Free cancellation before {selectedDay ? moment(selectedDay).subtract(1, 'days').format("MMM DD, YYYY") : "Choose Trip Date"}.</b> Cancel before this guide’s cancellation window to get a full refund.</p> */}
                    <p className={classes.CancellationDescription}><b  >
                      Free cancellation before {cancellationDate}. </b>{schrecords?.cancellation_text? schrecords?.cancellation_text:`Cancel before this guide’s cancellation window to get a full refund.`}</p>


                  </div>

                </div>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={isLCal ? 6 : 6} lg={isLCal ? 6 : 6}>
          {(isGuestCountMaxed) ? <Typography style={{ width: 'auto', 'marginBottom': 10 }} className={classes.redBoundary}>
            You have reached maximum limit of guests in the following trips.
          </Typography> : null}
          {(error.paymentError) ? <Typography style={{ width: 'auto', 'marginBottom': 10, position: isNarrow ? 'static' : 'sticky', top: '15px' }} className={`${classes.redBoundary}  ${classes.boxShadow}`}>
            {error.paymentError}
          </Typography> : null}
          {
            !isNarrow && status && <div className={classes.redBoundary} style={{ width: "auto", margin: "0px 0px 10px 10px" }}>
              <div style={{ margin: "0px" }}><h3>There was a problem</h3></div>
              <p style={{ margin: "0px" }}>{errorMsg}</p>
            </div>
          }
          {isNarrow ? <Typography style={{ marginBottom: 5, marginTop: 5, fontWeight: 'bolder', fontSize: 18 }}>Checkout</Typography> : null}
          <div style={{ position: isNarrow ? 'static' : 'sticky', top: '15px' }}
          >
            <div style={{
              marginLeft: isNarrow ? 0 : '10px', borderRadius: 5,
              top: '15px'
            }} className={`${classes.rateCardContainer} ${classes.boxShadow}`}>
              {!isNarrow ?
                <Grid container style={{ padding: "20px 5% 10px ", borderBottom: "1px solid #0000001f" }}>
                  {selectedRateCard && Object.keys(selectedRateCard).length ? <div className="ImageContainerCheckout">
                    {selectedRateCard.coverpicture ? <img src={`${cloudfront}${selectedRateCard.coverpicture}`} /> : null}
                    <div style={{ width: "calc(100% - 124px)", position:"relative" }}>
                    <div>
                      {selectedRateCard?.title ? <h6>{selectedRateCard?.title}</h6> : null}
                      <p>{selectedRateCard.is_referral ? `${referralCompany} (Booking Partner of ${schrecords?.company})` :isMultiTenantGuide? `${companyDetails?.group_name}`:`${schrecords?.company}`}</p>
                      </div>
                      {selectedRateCard.is_referral ?
                        <p className="location"><span>{referralReview && referralReview != "0.00" && <><Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(referralReview).toFixed(1)}</>}</span><span className={referralReview && referralReview != "0.00" && "techlabel"}>{referralLocation ? referralLocation : ''}</span></p> :
                        isMultiTenantGuide ?
                          <p className="location">
                            <span>{selectionType?.data?.avg_rating && selectionType?.data?.avg_rating != "0.00" && selectionType?.data?.avg_rating != "0" && <>
                              <Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(selectionType?.data?.avg_rating).toFixed(1)}</>}
                            </span>
                            <span className={selectionType?.data?.avg_rating && selectionType?.data?.avg_rating != "0.00" && selectionType?.data?.avg_rating != "0" && "techlabel"}>{selectionType?.data?.display_location ? selectionType?.data?.display_location : ''}</span>
                          </p> :
                          <p className="location"><span>{schrecords.rating && schrecords.rating != "0.00" && <><Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(schrecords.rating).toFixed(1)}</>}</span><span className={schrecords.rating && schrecords.rating != "0.00" && "techlabel"}>{schrecords?.display_location ? schrecords?.display_location : ''}</span></p>}
                    </div>
                  </div> :
                    <div>
                      <p>{isMultiTenantGuide? `${companyDetails?.group_name}`:`${schrecords?.company}`}</p>
                      <p className="location"><span>{selectedRateCard.is_referral ? (referralReview && referralReview != "0.00" && <><Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(referralReview).toFixed(1)}</>) : (schrecords.rating && schrecords.rating != "0.00" && <><Icon icon={star} size={14} style={{ marginRight: "5px" }} />{Number(schrecords.rating).toFixed(1)}</>)}</span>
                        {selectedRateCard.is_referral ? <span className={referralReview && referralReview != "0.00" && "techlabel"}>{referralLocation ? referralLocation : ''}</span> : <span className={schrecords.rating && schrecords.rating != "0.00" && "techlabel"}>{schrecords?.display_location ? schrecords?.display_location : ''}</span>}
                      </p>
                    </div>}
                </Grid>
                : null
              }
              <div style={{ padding: '0 5%' }}>
                <p className='Price'>Price Details</p>
                <TripCharge from='summary' boldSubtitle tippercentage={tippercentage} isNarrow={isNarrow} checkCompany={checkCompany} addonAmt={addonAmt} addOnCheck={addOnCheck} consumerAddOn={consumerAddOn} />
              </div>
            </div>
            {
              isNarrow && status && <div className={classes.redBoundary} style={{ width: "auto", margin: "10px 0px 10px 10px" }}>
                <div style={{ margin: "0px" }}><h3>There was a problem</h3></div>
                <p style={{ margin: "0px" }}>{errorMsg}</p>
              </div>
            }
            <button
              // disabled={activateBtn || disabled}
              onClick={handlePayment}
              className={'bookNowBtn booknowExtra instantBook'}
              style={{ margin: "15px 10px 10px", height: 80, width: '98%', borderRadius: 5 }}
            >
              {loading ? <CircularProgress size={23} color="inherit" /> : 'Confirm & Pay'}
            </button>
            <div className='mtContBook' style={{ marginLeft: isNarrow ? 0 : '18px', marginTop: "1.2rem", }}>
              <p

                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginTop: "-0.5rem"
                }}>By selecting the button above, I agree to the <b>Guide’s cancellation policy</b>.</p>
            </div>
          </div>

        </Grid>
        <CheckoutModal show={showTip} onHide={() => {
        setLocalTip({
         ...localTip, "tipamount": tripDetailsContext.tipamount != 'uponCash' ? Number((tripDetailsContext.tipamount)).toFixed(2) : 'uponCash',
         "tiptype": tripDetailsContext.tipType
        })
        setShowTip(false)
        }} size="md"
          style1={classes.Gmodal1} header={"Pre Tip"}>
          <>
            <div style={{ maxHeight: "310px", overflowY: "auto", overflowX: "hidden", paddingBottom: "15px" }} >
              <div className={classes.fullWidth}>
                <div
                  style={{ display: 'flex', paddingTop: 5, paddingBottom: 5 }}
                  id="tip18"
                >
               <img src={localTip.tipamount === (localTip.tipCost * 0.18).toFixed(2) ? checked : unchecked}
                    onClick={() => {
                      handlePredefined(0.18)
                      setExpanded('')
                    }} style={{
                      margin: 0,
                      padding: 0,
                      borderRadius: 0,
                      marginRight: 10,
                      height: 17, width: 17, border: '1px solid grey'
                    }} width="17" height="17" />

                  <div
                    onClick={() => {
                      handlePredefined(0.18)
                      setExpanded('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='triptypeTitle'
                  >
                    {`18% - ${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney((totalTripCost * 0.18).toFixed(2))}`}
                  </div>
                </div>

                <div
                  style={{ display: 'flex', paddingTop: 5, paddingBottom: 5 }}
                  id="tip20"
                >
                   <img src={localTip.tipamount === (localTip.tipCost * 0.20).toFixed(2) ? checked : unchecked}
                    onClick={() => {
                      handlePredefined(0.20)
                      setExpanded('')
                    }} style={{
                      margin: 0,
                      padding: 0,
                      borderRadius: 0,
                      marginRight: 10,
                      height: 17, width: 17, border: '1px solid grey'
                    }} width="17" height="17" />

                  <div
                    onClick={() => {
                      handlePredefined(0.20)
                      setExpanded('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='triptypeTitle'
                  >
                    {`20% - ${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney((totalTripCost * 0.20).toFixed(2))}`}
                  </div>
                </div>

                 

                <div
                  style={{ display: 'flex', paddingTop: 5, paddingBottom: 5 }}
                  id="tip25"
                >
                  <img src={localTip.tipamount === (localTip.tipCost * 0.25).toFixed(2) ? checked : unchecked}
                    onClick={() => {
                      handlePredefined(0.25)
                      setExpanded('')
                    }} style={{
                      margin: 0,
                      padding: 0,
                      borderRadius: 0,
                      marginRight: 10,
                      height: 17, width: 17, border: '1px solid grey'
                    }} width="17" height="17" />

                  <div
                    onClick={() => {
                      handlePredefined(0.25)
                      setExpanded('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='triptypeTitle'
                  >
                    {`25% - ${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney((totalTripCost * 0.25).toFixed(2))}`}
                  </div>
                </div>

                <div
                  style={{ display: 'flex', paddingTop: 5, paddingBottom: 5 }}
                  id="custom-tip-amount"
                >
                    <img src={(localTip.tipamount !== (localTip.tipCost * 0.18).toFixed(2) && localTip.tipamount !== (localTip.tipCost * 0.20).toFixed(2) && localTip.tipamount !== (localTip.tipCost * 0.25).toFixed(2) && localTip.tipamount !== 'uponCash' && localTip.tipamount !== '') ? checked : unchecked} onClick={() => {
                    customTip.current.focus()
                    setLocalTip({ tipamount: customTip.current.value, tiptype: "Custom" })
                  }} style={{
                    margin: 0,
                    padding: 0,
                    borderRadius: 0,
                    marginRight: 10,
                    height: 17, width: 17, border: '1px solid grey',
                    position: 'relative',
                    top: 10
                  }} width="17" height="17" />
                  <div className='triptypeTitle'>
                    Custom Tip - <input ref={customTip} className={`${classes.customTipValue} customTipValue`} onChange={(e) => {
                      setLocalTip({ tipamount: e.target.value > 0 ? Number(e.target.value) : 0, tiptype: "Custom" })
                    }} type="number" placeholder={"Enter a custom amount"} style={{ height: "30px", marginTop: "5px" }} />
                  </div>
                </div>

                <div
                  style={{ display: 'flex', paddingTop: 5, paddingBottom: 5 }}
                  id="cash-tip-upon-trip"
                >
              <img src={localTip.tipamount === 'uponCash' ? checked : unchecked} onClick={() => {
                    setLocalTip({ tipamount: 'uponCash', tiptype: "Cash Tip on Trip" })
                  }} style={{
                    margin: 0,
                    padding: 0,
                    borderRadius: 0,
                    marginRight: 10,
                    height: 17, width: 17, border: '1px solid grey'
                  }} width="17" height="17" />
                  <div
                    onClick={() => {
                      setLocalTip({ tipamount: 'uponCash', tiptype: "Cash Tip on Trip" })
                    }}
                    style={{ cursor: 'pointer' }}
                    className='triptypeTitle'
                  >
                    Cash Tip on Trip
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.displayButtons}>
              <p onClick={() => {
              setLocalTip({
                ...localTip, "tipamount": tripDetailsContext.tipamount != 'uponCash' ? Number((tripDetailsContext.tipamount)).toFixed(2) : 'uponCash',
                "tiptype": tripDetailsContext.tipType
              })
              setShowTip(false)
              }} >Cancel</p>
              <button onClick={() => {
                tripDetailsContext.setTipamount(localTip.tipamount)
                tripDetailsContext.setTipType(localTip.tiptype)
                setTippercentage(localTip.tipPercent)
                setShowTip(false)
              }}
                style={{ padding: "0px 20px", fontSize: "14px", height: "30px" }}
                className={`gradientBtn bookNowBtn`} >Save</button>
            </div>
          </>
        </CheckoutModal>
        <CheckoutModal show={guestModal} onHide={() => setGuestModal(!guestModal)} size="md"
          style1={classes.Gmodal1} header={"Guests"}>
          <>
            <p style={{ fontSize: "12px" }}>This trip has a maximum of {capacity} total {capacity === 1 ? 'guest' : 'guests'} allowed.</p>
            <div >
              {renderGuests("Adults", "Ages 13 or above")}
              {renderGuests("Children", "Ages 2-12")}
            </div>
            <div className={classes.displayButtons}>
              <p onClick={() => setGuestModal(!guestModal)} >Cancel</p>
              <button onClick={handleApply} className={`gradientBtn bookNowBtn`}
                style={{ padding: "0px 20px", fontSize: "14px", height: "30px" }}>Save</button>
            </div>
          </>
        </CheckoutModal>
        <ChatModal show={show} onHide={() => handleDatepickerClose()} size="xl" header="Message" style1={classes.dynamicModal}>
          <div style={{ padding: "5%" }}>
            <CustomSingleDatePicker handleDayClick={dateSelection ? handleDateAfterTypeSelection : timeSelection ?
              handleRateAndTimeSelection : handleDayClick} modifiers={modifiers} setCalendarShow={setCalendarShow}
              show={show} setShow={setShow} handleClear={handleClearData}
              setMeeetingPoint={setMeeetingPoint} setMeeetingPointLocal={setMeeetingPointLocal}
            // setLocalTripTime={setLocalTripTime} setLocalTripType={setLocalTripType} 
            />
          </div>
        </ChatModal>
        <CheckoutModal show={showTripTypeModal} onHide={() => handleCancel()} size="md"
          style1={classes.Gmodal1} header={"Trip Type"}>
          <>
            <div style={{ maxHeight: "310px", overflowY: "auto", overflowX: "hidden" }} >
              <div style={{ width: '100%' }}>
                {list.map((rateCard, i) => {
                  return (
                    <label
                      key={i}
                      style={{
                        borderBottom: "1px solid #42642f",
                        width: "100%",
                        margin: "5px 0px"
                      }}
                    >
                      <Grid container className={`${rateCard.disabled ? '' : ''} innercontainer`}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            padding: "0px 5px 10px", display: "flex", borderBottom: "1px solid #42642f",
                            width: "100%",
                            margin: "5px 0px"
                          }}
                        >
                          <img src={localTripType?.id === rateCard?.id ? checked : unchecked} onClick={() => {
                            if (selectedDay && selectedDay != "") {
                              setLocalTripType(rateCard)
                            } else {
                              handleShowDatePicker(rateCard)
                            }
                          }} style={{
                            padding: 0,
                            borderRadius: 0,
                            margin: 0,
                            marginRight: 10,
                            height: 20, width: 20, border: '1px solid grey'
                          }} width="20" height="20" />
                          <span>
                            <span
                              onClick={() => {
                                if (selectedDay && selectedDay != "") {
                                  setLocalTripType(rateCard)

                                } else {
                                  handleShowDatePicker(rateCard)
                                }
                              }}
                              style={{ fontWeight: 'bolder', cursor: 'pointer' }} variant="body2" className="Head">
                              {rateCard.title}
                            </span>
                            {
                              rateCard.ratetype ?
                                <div className="dsctext triptypeOther">
                                    {rateCard.ratetype}
                                    {
                                      rateCard.activity_type === "fishing" ?
                                      <span style={{ position: 'relative', top: 2, left: 2 }}>
                                      <Tooltip classes={{ tooltip: 'guidesly-tooltip' }} enterTouchDelay={0} title={TYPES[rateCard.ratetype?.toLowerCase()]}>
                                        <span><LazyLoadComponent>
                                          <img src={InfoIcon} width={12} height={12} />
                                        </LazyLoadComponent></span>
                                      </Tooltip>
                                      </span> : null
                                    }
                                </div> : null
                            }
                            {(rateCard.maximum && !rateCard.is_shared_trip) ? <Typography variant="caption" className="dsctext"> Max {rateCard.maximum} {rateCard.maximum > 1 ? 'guests' : 'guest'}</Typography> : null}
                            {rateCard.has_boat && <Typography variant="caption" className="dsctext">
                              {rateCard.length}{rateCard.length?"' ":null}{(rateCard.manufacturer_name !="Other" && rateCard.manufacturer_name !="Others")?rateCard.manufacturer_name: rateCard.other_manufacturer}{'-'} {getMaxCapacity(rateCard)} Max capacity.
                            </Typography>}
                          </span>
                        </Grid>
                      </Grid>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className={classes.displayButtons} style={{ border: '0px' }}>
              <p onClick={handleCancel} >Cancel</p>
              <button
                onClick={handleSave}
                disabled={localTripType && Object.keys(localTripType).length > 0 ? false : true}
                style={true ? { padding: "0px 20px", fontSize: "14px", height: "30px" } :
                  { opacity: 0.7, height: "30px", pointerEvents: "none" }}
                className={`gradientBtn bookNowBtn`} > Save</button>
            </div>
          </>
        </CheckoutModal>
        <CheckoutModal show={showTripTimeModal} onHide={() => handleCancelTime()} size="md"
          style1={classes.Gmodal1} header={"Start Time & Length"}>
          <>

            <div style={{ maxHeight: "310px", overflowY: "auto", overflowX: "hidden" }} >
              {selectedRateCard?.rates?.map((item, i) => {
                if (!item.disabled) {
                  return (
                    <div
                      key={i}
                      style={{
                        display: 'flex', borderBottom: "1px solid #42642f",
                        width: "100%",
                        margin: "5px 0px 15px", paddingBottom: 15,
                        cursor: item.disabled ? 'not-allowed' : 'pointer',
                        backgroundColor: item.disabled ? '#d3d3d3' : ''
                      }}
                    >
                      <img onClick={() => {
                        if (!item.disabled) {
                          if (selectedDay) {
                            setLocalTripTime(item)
                          } else {
                            handleRateNTime(selectedRateCard, item)
                          }
                        }
                      }} src={localTripTime?.id === item?.id ? checked : unchecked} style={{
                        padding: 0,
                        margin: 0,
                        borderRadius: 0,
                        marginRight: 10,
                        height: 20, width: 20, border: '1px solid grey'
                      }} width="20" height="20"/>
              
                      <div 
                        onClick={() => {
                          if (!item.disabled) {
                            if (selectedDay) {
                              setLocalTripTime(item)
                            } else {
                              handleRateNTime(selectedRateCard, item)
                            }
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                        className='triptypeTitle'
                      >
                        {convertTo12Hour(item.start_time)} {getDuration(item)} {' for '} {(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}{displayMoney(item.rate)}
                      </div>
                    </div>
                  );
                }
              })}
              {
                Object.keys(selectedRateCard).length === 0 && (
                  <>
                    {
                      list?.filter(item => guestCount <= item.maximum && item.rates).map((item, i) => {
                        return (
                          <div key={i} style={{
                            padding: "0px 5px 0px",
                            width: "100%",
                            margin: "5px 0px 0px"
                          }}>
                            <p style={{ margin: "0px 0px 10px", fontWeight: 600 }}>{item.title}</p>
                            {
                              item.rates.map((rec, j) => {
                                if (selectedDay && selectedDay != "" && !rec.disabled) {
                                  return renderListItems(item, rec, i, j)
                                } else if (!selectedDay || selectedDay === "") {
                                  return renderListItems(item, rec, i, j)
                                }
                              })
                            }
                          </div>
                        )
                      }
                      )
                    }
                  </>
                )}
            </div>
            <div className={classes.displayButtons} style={{ border: '0px' }}>
              <p onClick={handleCancelTime} >Cancel</p>
              <button onClick={handleSaveTime} disabled={((Object.keys(localTripType).length > 0) && !(localTripType.rates.filter(a=>a.id == localTripTime.id).length ==0)) ? false : true}
                style={true ? { padding: "0px 20px", fontSize: "14px", height: "30px" } : { opacity: 0.7, height: "30px", pointerEvents: "none" }}
                className={`gradientBtn bookNowBtn`} >Save</button>
            </div>
          </>
        </CheckoutModal>
        <CheckoutModal show={showMP} onHide={() => {
          setMeeetingPointLocal(meeetingPoint)
          setShowMP(false)
        }} size="md"
          style1={classes.Gmodal1} header={"Meeting Location"}>
          <>
            <div style={{ maxHeight: "310px", overflowY: "auto", overflowX: "hidden" }} >
              <div className={classes.fullWidth}>
                  {meetingspotOptions?.map((item, i) => {
                   return (
                    <div
                      key={i}
                      style={{ display: 'flex', borderBottom: '1px solid black', paddingTop: 5, paddingBottom: 5 }}
                    >
                      <img src={meeetingPointLocal?.poiguid === item?.poiguid ? checked : unchecked} onClick={() => {
                           setMeeetingPointLocal(item)
                        setExpanded('')
                      }} style={{
                        margin: 0,
                        padding: 0,
                        borderRadius: 0,
                        marginRight: 10,
                        height: 20, width: 20, border: '1px solid grey'
                      }} width="20" height="20"/>
                      <div 
                        onClick={() => {
                          setMeeetingPointLocal(item)
                          setExpanded('')
                        }}
                        style={{ cursor: 'pointer' }}
                        className='triptypeTitle'
                      >
                        {item?.description}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.displayButtons} style={{ border: '0px' }}>
              <p onClick={() => {
                setMeeetingPointLocal(meeetingPoint)
                setShowMP(false)
              }} >Cancel</p>
              <button onClick={() => {
                setTripDetailsContext(
                  'meeetingPoint',
                  meeetingPointLocal
                )
                setShowMP(false)
                handleCheckRC()
              }}
                style={{ padding: "0px 20px", fontSize: "14px", height: "30px" }}
                className={`gradientBtn bookNowBtn`} >Save</button>
            </div>
          </>
        </CheckoutModal>
      </Grid>
    </Grid>
  );
}
