import moment from "moment";
import React, {useContext, useState, useEffect} from "react"
import useStyles from './styles';
import Icon from "react-icons-kit";
import {star} from 'react-icons-kit/fa/star'
import axios from "axios";
import TripDetailsContext from "../../state/context/TripDetailsContext";
// import defaultSelection from "../../../assets/defaultSelection.png"

const Selection =({previous,next,companyGuid})=>{
    const Styles = useStyles();
    const [guides, setGuides]=useState(null)
    const [boats, setBoats]=useState(null)
    const [isloaded, setIsLoaded]=useState(false)
    const tripDetailsContext = useContext(TripDetailsContext); 
    const isBrowser = typeof window !== `undefined`
    const {
        guestCount,
        selectedDay,
        selectedRateCard,
        selectedHour,
        selectionType,
        setSelectionType,
        setDepositAmt,
        bsAvailability, 
        setGuestCount,
      } = tripDetailsContext;
      useEffect(()=>{
        if (isBrowser) {
			window.scrollTo({
				top: 0,
			})
		}
      },[])
    //   useEffect(()=>{
    //     getAvailableGuidesBoats()
    //   },[selectedHour])
      useEffect(()=>{
        setGuides(bsAvailability.guides)
        setBoats(bsAvailability.vehicles)
        setDepositAmt(bsAvailability.deposit_percentage || 20)
        setIsLoaded(true)
      },[bsAvailability])
    //   const getAvailableGuidesBoats =()=>{
    //     const date = selectedDay && moment(selectedDay).format("YYYY-MM-DD")
    //     axios.get(`${apiHost}guide-group/${companyGuid}/availability?date=${date}&ratecard_id=${selectedHour.guide_ratecard_id}&rateid=${selectedHour.id}`)
    //     .then(response=>{
    //         setGuides(response.data.guides)
    //         setBoats(response.data.boats)
    //         setDepositAmt(response.data.deposit_percentage || 20)
    //         setIsLoaded(true)
    //     })
    //     .catch(err=>console.log(err))
    //   }

      const handleDefaultSelect =()=>{
        if(guides&&guides.length>0){
            setSelectionType({
                "type":"Guide",
                "guid":guides[0].guideguid, 
                "name":guides[0].guidename,
                "data":guides[0]
            })
            next();
        }else if(boats&&boats.length>0){
            setSelectionType({
                "type":"Boat",
                "guid":boats[0].guid, 
                "name":boats[0].name,
                "data":boats[0]
            })
            next();
        }
      }
      const handleSelect =(type,item, name, guid)=>{
        setSelectionType({
            "type":type,
            "guid":guid, 
            "name":name,
            "data":item,
        })
          if (type === "Boat" && guestCount > item?.guest_capacity) {
              setGuestCount(item?.guest_capacity)
          }
        next()
      }
      const getDiff = (date) => {
        var a = moment(new Date());
        var b = moment(date);
    
        var years = a.diff(b, 'year');
        b.add(years, 'years');
    
        var months = a.diff(b, 'months');
        b.add(months, 'months');
    
        return years === 1 ? `${years} Yr` : years > 1 ? `${years} Yrs` : years === 0 && months > 1 ? `${months} Months` : `${months} Month`
    }
    const getCorrectedImagePath = (image) => {
        if (image) {
            var correctPath = image.charAt(0) === '/' ? image.slice(1) : image
            return correctPath;
        }
    }

      const date = selectedDay&&moment(selectedDay).format("MMMM DD, YYYY")
      const startTime =selectedHour&& moment(selectedHour?.start_time, ["HH:mm:ss"]).format("h:mm a")
    return(
        <div style={{minHeight:"420px"}}>
           <div className={Styles.header}>
                <p className={Styles.headText}><span style={{fontWeight:600}}>You have selected:</span>{` ${selectedRateCard.title} on ${date} at ${startTime} for ${guestCount} ${guestCount===1?"Guest":"Guests"} `}</p>
           </div>
           <div>
                {isloaded && bsAvailability?.settings?.choose_guide && guides.length>0 && <div>
                    <p> Choose A Guide</p>
                    <div className={Styles.horizontalScroll}>
                       { guides.length>1&&<div className={Styles.Card}  style={selectionType?.guid===null?{borderColor:"#1a04ec"}:{borderColor:"#086edc"}}>
                            <div className={Styles.innerCont}>
                                    <img src={`${cloudfront}${getCorrectedImagePath(`/web/static/images/1d6a47ebd6437d82c4c9ca83da0af711.png`)}`}
                                    alt={"defalt"}
                                    className={`${Styles.Pic} ${Styles.picdefault}`}
                                    style={selectionType?.guid===null?{borderColor:"#1a04ec"}:{borderColor:"#086edc"}}
                                />
                                
                                {/* <div className={Styles.textCont}>
                                    <p className={Styles.title}>{item.guidename}</p>
                                    {item.experience_years && <p className={Styles.text}>{getDiff(item.experience_years)} Experience</p>}
                                    {item.rating && <p className={Styles.text}>{item.rating} <Icon icon={star} size={11} className={Styles.Icon} /> </p>}
                                </div> */}
                            </div>
                            <button className={Styles.guideBtn} 
                                onClick={()=>handleDefaultSelect()}
                                style={selectionType?.guid===null?{backgroundColor:"#1a04ec"}:{backgroundColor:"#086edc"}}>
                                 Select First Available
                            </button>
                        </div>}
                        {
                            guides.map((item, i) => {
                                return (
                                    <div className={Styles.Card} key={i} style={selectionType?.guid===item.guideguid?{borderColor:"#1a04ec"}:null}>
                                        <div className={Styles.innerCont}>
                                            {item.guidepic && <img src={`${cloudfront}${getCorrectedImagePath(item.guidepic)}`}
                                                alt={item.guidename}
                                                className={Styles.Pic}
                                            />}
                                            {
                                                !item.guidepic && <div  className={Styles.noPic}>
                                                    <p className={Styles.lgtext}>{item.guidename?.charAt(0).toUpperCase()}</p>
                                                    </div>
                                            }
                                            <div className={Styles.textCont}>
                                                <p className={Styles.title}>{item.guidename}</p>
                                                {item.experience_date && <p className={Styles.text}>{getDiff(item.experience_date)} Experience</p>}
                                                {item.avg_rating && <p className={Styles.text}>{item.avg_rating} <Icon icon={star} size={11} className={Styles.Icon} /> </p>}
                                            </div>
                                        </div>
                                        <button className={Styles.guideBtn} 
                                        onClick={()=>handleSelect("Guide",item,item.guidename, item.guideguid )}
                                        style={selectionType?.guid===item.guideguid?{backgroundColor:"#1a04ec"}:null}>
                                           {selectionType?.guid===item.guideguid?"Selected Guide":"Select Guide"}</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>}
                {isloaded && bsAvailability?.settings?.choose_vehicle &&  boats.length > 0 && <div>
                    <p> {isloaded && bsAvailability?.settings?.choose_guide && guides.length>0 && "Or"} Choose A Boat</p>
                    <div className={Styles.horizontalScroll}>
                        {
                            boats.map((item, i) => {
                                const boatImg = item.vehicle_docs.filter(rec=>rec.doc_type === "boat_profile_picture")
                                const Coverpic = item?.coverpicture ? item?.coverpicture :boatImg?.length > 0 ? boatImg?.[0]?.doc_path : null; 
                                return (
                                    <div className={Styles.Card} key={i} style={selectionType?.guid===item.guid?{ borderColor: "#ec5e00" }:{ borderColor: "#e77327" }}>
                                        <div className={Styles.innerCont}>
                                            {Coverpic && <img src={`${cloudfront}${getCorrectedImagePath(Coverpic)}`}
                                                alt="guide1"
                                                className={Styles.Pic}
                                            />}
                                            {
                                                !Coverpic && <div  className={Styles.noPic}>
                                                    <p className={Styles.lgtext}>{item.name?.charAt(0).toUpperCase()}</p>
                                                    </div>
                                            }
                                            <div className={Styles.textCont}>
                                                <p className={Styles.title}>{item.name ? item.name : item.manufacturer_name}</p>
                                                <p className={Styles.text}>Guests Capacity {item.guest_capacity} </p>
                                                <p className={Styles.text}>Length {item.length}ft</p>
                                            </div>
                                        </div>
                                        <button className={Styles.guideBtn}
                                         onClick={()=>handleSelect("Boat",item, item.name, item.guid)} 
                                         style={selectionType?.guid===item.guid?{ backgroundColor: "#ec5e00" }:{ backgroundColor: "#e77327" }}>
                                          {selectionType?.guid===item.guid?"Selected Boat":"Select Boat"}</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>}
           </div>
        </div>
    )
}
export default Selection