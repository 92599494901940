import React, { useEffect, useState } from 'react'
import { Typography, CardContent, Grid } from '@material-ui/core'
import { get } from '../../widgets/utils/axiosHelper'
import useStyles from './style'
import moment from 'moment'
import './style.scss'
import PaginationComponent from './PaginationComponent'
import LazyLoadImage from '../../widgets/common/LazyLoadImage'
import DesktopReport from './FullReport'
import _ from 'lodash'

const Reports = (props) => {
    const classes = useStyles()
    const [reports, setReports] = useState([])
    const [report, setReport] = useState()
    const [isMobile, setIsMobile] = useState(/Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 1199 || ((screen || {}).width || 0) <= 1199)
    const paginationCount = isMobile ? 10 : 15;
    const [pagination, setPagination] = useState({ "limit": paginationCount, "active": 1, "numPages": Math.ceil(reports.length / paginationCount)})
    const TempImage ="https://guidesly-assets.s3.us-east-2.amazonaws.com/email/consumer/images/marketplace_web_layout_mp.png"
    useEffect(() => {
        const url = `journal/reports/guide/${props.id}`;
        get(url)
            .then((res = {}) => {
                let reports = []
                const { status, data = [] } = res
                if (status === 200) {
                    const fishing_reports = data.fishing_reports.filter(item => item.show_on_lcal && (item?.publication_status === 'draft' ? false : true))
                    const catches = data.catches.filter(item => item.show_on_lcal)
                    fishing_reports.forEach(item => reports.push({ ...item, type: 'report', id: item.id ? parseInt(item.id) : item.id }))
                    // catches.forEach(item => reports.push({ ...item, type: 'catch-report', id: item.id ? parseInt(item.id) : item.id }))
                    // reports.forEach(item => {
                    //     if (item.catches && item.catches.data) {
                    //         item.catches.data.forEach(item1 => reports.push({ ...item1, type: 'catch', id: item.id ? parseInt(item1.id) : item1.id, pictures: item.pictures.data ? item.pictures : null }))
                    //     }
                    // })
                    reports = _.uniqBy(reports, 'id')
                    reports = reports.map(item => ({...item, startdate: item.startdate || item.mainstartdate, coverpicture: item.coverpicture || item.maincoverpicture, title: item.title || item.maintitle }))
                    // reports = reports.sort((a, b) => new Date(b.startdate) - new Date(a.startdate))
                    reports = _.orderBy(reports, 'enddate', 'desc')
                    setReports(reports)
                    setPagination({ ...pagination, "numPages": Math.ceil(reports.length / paginationCount) })
                }
            }).catch((e) => {
                console.log('API Error: reports', e)
            });
    }, []);

    const indexOfLastImage = pagination.active * pagination.limit;
	const indexOfFirstImage = indexOfLastImage - pagination.limit;
	const currentImage = reports.slice(indexOfFirstImage, indexOfLastImage);
    const onErrorCallBack = (e) => {
		e.target.src = TempImage
	}
    if (report) {
        return <DesktopReport previous={() => setReport()} report={report} />
    }

    return (
        <div>
            <div
                className={classes.bannerContainer}
            >
                <Typography variant="h4">Fishing Reports</Typography>
            </div>
            {/* {
                reports.map(report => {
                    const reportLength = 10
                    return <Grid key={report.tripid} container className={classes.marginTop}>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Typography variant="body2" className={classes.date}>{moment(
                                report.startdate
                            ).format("LL")}</Typography>
                        </Grid>
                        <Grid className={`${classes.paddingSides} ${classes.displayFlex}`} item xs={12} sm={12} md={reportLength} lg={reportLength}>
                            <Grid id={report.id} container>
                                {report.coverpicture && <Grid className={classes.paddingSides} item xs={12} sm={12} md={5} lg={5} >
                                    <LazyLoadImage visible className={classes.className} image={`${cloudfront}${report.coverpicture}`} alt={report.title} />
                                </Grid>}
                                <Grid item xs={12} sm={12} md={report.coverpicture ? 7 : 12} lg={report.coverpicture ? 7 : 12}>
                                    <Typography className={classes.title}>{report.title}</Typography>
                                    {report.description && <Typography style={{ textAlign: 'justify'}} variant="body2" className={classes.paddingBottom}>{report.description}</Typography>}
                                    <Typography variant="body2" onClick={() => setReport(report)} className={`${classes.paddingBottom} ${classes.readmore}`}>More Info</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                })
            } */}
            <div>
                <Grid container spacing={3} className={'ReportContainer'}>
                    {
                        currentImage.map(report => {
                            return (
                                <Grid className={'ReportHolder'} item xs={12} sm={6} md={6} lg={4} key={report.id} onClick={() => setReport(report)}>
                                    <div className={'ReportCard'}>
                                        {
                                            report.coverpicture && 
                                            <div onClick={() => setReport(report)}>
                                                <img src={`${cloudfront}${report.coverpicture}`} alt="img" className={'CoverImage'} onError={(e) => onErrorCallBack(e)}  />
                                            </div> 
                                        }
                                    </div>
                                    <div className={'ReportCard'}>   
                                        <Typography variant="h6" component="div" className={'ReportTitle'}>{report.title}</Typography>
                                        <Typography variant="body2" className={'RiverName'}>{report.location_waterbodyname}</Typography>
                                        <Typography variant="body2" className={'Date'}>{moment(report.startdate ? report.startdate  : report.enddate).format("LL")}</Typography>
                                        {report.description && <Typography variant="body2" className={'CardDescription'} dangerouslySetInnerHTML={{ __html: report.description }}></Typography>}
                                        <Typography variant="body2" onClick={() => setReport(report)} className={'ReadMore'} >Read more...</Typography>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                {
                    reports.length > paginationCount &&
                    <PaginationComponent pagination={pagination} setPagination={setPagination} />
                }
            </div>
        </div>
    );
}

export default Reports