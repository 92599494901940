import moment from 'moment'
export const convertTo12Hour = (time = '') => {
  return moment(time, 'hh:mm:ss').format('h:mm A')
};

export const getTimeFormat = (rec = '') => {
  let showTime = ""
  const time = rec?.split(" ")[0]
  const hr = moment(time, "h.mm").hours()
  const min = moment(time, "h.mm").minutes()
  if (min > 0) {
    showTime = hr === 1 ? `${hr} hr ${min} min` : `${hr} hrs ${min} min`
  } else {
    showTime = hr === 1 ? `${hr} hr` : `${hr} hrs`
  }
  return showTime
}
export const getTimeFormat1 = (time) => {
	let showTime = ""
	// const time = rec.title != null && rec.title.split(" ")[0]
	const hr = moment(time, "h.mm").hours()
	const min = moment(time, "h.mm").minutes()
	if (min > 0) {
		showTime = hr === 1 ? `${hr} hour ${min} min` : `${hr} hour ${min} min`
	} else {
		showTime = hr === 1 ? `${hr} hour` : `${hr} hour`
	}
	return showTime
}
export default convertTo12Hour;
