import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    header:{
        padding: "12px 15px",
        backgroundColor: "#d4d4d4",
        borderRadius: "5px",
    },
    headText:{
        margin:0,
        fontSize:"14px",
        color:"black",
    },
    horizontalScroll:{
        display: "flex" ,
        flexWrap: "nowrap" ,
        height: "100% ",
        listStyle: "none" ,
        margin: 0 ,
        minWidth: "100% ",
        overflow: "auto hidden" ,
        paddingLeft: 0 ,
    },
    Card:{
        padding: "10px",
        minWidth: "250px",
        maxWidth: "250px",
        height: "280px",
        borderRadius: "15px",
        backgroundColor: "white",
        boxSizing: "border-box",
        border:" 3px solid #086edc",
        margin:"10px 10px 5px",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.5)",
        '&:first-child':{
            margin: "10px 10px 5px 0px",
        },
       '&:last-child':{
            margin: "10px 0px 5px 10px",
        }
    },
    innerCont:{
        padding:"5px",  
        position:"relative",
    },
    Pic:{
        width:"100%",
        height:"180px",
        minHeight:"180px",
        objectFit:"cover",
        borderRadius: "10px",
        backgroundColor:" rgba(255, 255, 255, 0)",
    },
    noPic:{
        width:"100%",
        height:"180px",
        minHeight:"180px",
        objectFit:"cover",
        borderRadius: "10px",
        backgroundColor:"#d7d7d7",
        textAlign:"center",
        display:"flex",
    },
    lgtext:{
        margin:"auto",
        fontSize:"6vw",
        color:"white",

    },
    textCont:{
        backgroundImage: "linear-gradient(-90deg,rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0.6) 45%, rgba(0, 0, 0, 0.6) 99%)",
        position:"absolute",
        bottom:"20px",
        width:"80%",
        padding: "10px 0px 10px 10px",
    },
    guideBtn:{
        width:"-webkit-fill-available",
        height:"40px",
        margin:"10px 5px",
        fontSize:"14px",
        color:"white",
        backgroundColor:"#086edc",
        border:"none",
        borderRadius:"5px",
        cursor:"pointer",
    },
    title:{
        margin:"0px",
        fontSize:"18px",
        color:"White",
    },
    text:{
        color:"white",
        fontSize:"13px",
        margin:"0px",
        display:"flex",
        alignItems:"center"
    },
    Icon:{
        color:"white",
        display:"flex !important",
        marginLeft:"2px"
    },
    picdefault:{
        border:" 3px solid ",
        width: "97%",
    }


}))