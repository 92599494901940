import React, { useEffect, useRef } from "react"
import {Link} from 'react-router-dom';
import useStyles from './styles';

const DropdownOptions = ({ filename, setOpen, urls }) => {
	const node = useRef();
	const classes = useStyles()
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [])

	const handleClickOutside = e => {
		if (node.current.contains(e.target)) {
			return;
		}
		// outside click
		setOpen(false)
	};
	return (
		<div className={classes.dropdown} onClick={() => { setOpen(false) }} role="presentation" ref={node} >
			<Link href={urls.ics} style={{ display: 'block' }} download={false} target="_blank"   >
				Apple Calendar
     		 </Link>
			<Link href={urls.google} style={{ display: 'block' }} target="_blank"  >
				Google
     		 </Link>
			<Link href={urls.ics} style={{ display: 'block' }} download={false} target="_blank" >
				Outlook
     		 </Link>
			<Link href={urls.yahoo} style={{ display: 'block' }} target="_blank" >
				Yahoo
     		 </Link>
		</div>
	)
}
export default DropdownOptions
