import React from "react"
import { Box, Modal } from '@material-ui/core';
import { Icon } from 'react-icons-kit'
import { x } from "react-icons-kit/feather";
const CheckoutModal = ({ show, size, onHide, header, children, style1, }) => {
	return (
		<Modal
			open={show}
			aria-labelledby="contained-modal-title-vcenter"
			onClose={onHide}
		>
			<Box className={style1 ? style1 : null}>
				<div>
					<Icon icon={x} onClick={onHide} style={{ cursor: "pointer" }} />
					<p style={{ padding: "15px 0px 0px", fontSize: "16px" , color:"black", fontWeight:700, marginTop: "0px"}} >{header}</p>
				</div>
				{children}
			</Box>

		</Modal >
	)
}
export default CheckoutModal