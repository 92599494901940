import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  Elements,
} from '@stripe/react-stripe-js';
import Payment from './PersonalDetials copy';

const PaymentProvider = ({ previous, next, tripsPage,xAppID, origin, isMultiTenantGuide,companyGuid,isNarrow, ...rest }) => {
  const [lstripe, setStripe] = useState(null);

  useEffect(() => {
    setStripe(loadStripe(stripeKey)); // eslint-disable-line no-undef
  }, []);

  return (
    <Elements stripe={lstripe}>
      <Payment previous={previous} next={next} tripsPage={tripsPage} xAppID={xAppID} 
      origin={origin} isNarrow={isNarrow}   isMultiTenantGuide={isMultiTenantGuide}
      companyGuid={companyGuid} {...rest} />
    </Elements>
  );
};

PaymentProvider.propTypes = {
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  xAppID: PropTypes.string.isRequired,
};

export default PaymentProvider;
