import React, { useEffect, useState, Suspense } from 'react'
import { Typography, CardContent, Card } from '@material-ui/core'
import { get } from '../../widgets/utils/axiosHelper'
import useStyles from './style'
import DesktopReviews from './desktop-reviews'
import MobileReviews from './mobile-reviews'
import CustomReviews from './custom-reviews'
import { fakeData, reviewNO } from './fakeData'
const Reviews = (props) => {
    const classes = useStyles()

    const [visible, setVisible] = useState(15)
    const handleLoadmore = () => {
        setVisible(reviews.length)
    }

    const [reviews, setReviews] = useState([])
    const [allReviews, setAllReviews] = useState([])
    const [guideDetails, setGuideDetails] = useState({});

    const sortbyDate = (data) => {
        var all_reviews = []
        return all_reviews = data.sort(function compare(a, b) {
            var dateA = new Date(a.created_at);
            var dateB = new Date(b.created_at);
            return dateB - dateA;
        });
    }

    useEffect(() => {
        const url = `guide/details?id=${props.id}`;
        get(url)
            .then((res = {}) => {
                const { status, data: { reviews = [] } = {} } = res
                if (status === 200 && reviews.length > 0) {
                    setAllReviews(reviews)
                    // let allReviews = reviews.filter(review => !!review.comments && review.show_on_lcal)
                    let allReviews = reviews.filter(review => review.show_on_lcal && ( !!review.comments || (review.media_list && review.media_list.length > 0) ) );
                    // (item.comments && item.comments != "") || (item.media_list && item.media_list.length > 0)
                    const pinnedReviews = sortbyDate(allReviews.filter(item => item.is_pinned === true))
                    const unPinnedReviews = sortbyDate(allReviews.filter(item => item.is_pinned === false))
                    const filterReviews = [...pinnedReviews, ...unPinnedReviews]
                    setReviews(filterReviews);
                    const { data: { firstname, lastname, guidename, guidepic } } = res;
                    setGuideDetails({ firstname, lastname, guidename, guidepic });
                }
            }).catch((e) => {
                console.log('API Error: reviews', e)
            });
    }, []);

    const isNarrow = /Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 834 || ((screen || {}).width || 0) <= 834

    let avgReview = [];
    let averageValue;
    allReviews &&
    allReviews.map((item) => {
        if (item.overall) {
          avgReview.push(Number(item.overall));
        }
      });
    const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
    averageValue = average(avgReview).toFixed(2);

    return (
        <>
            <div className={classes.headline}><h1> {guideDetails.guidename} Customer Reviews</h1></div>
            <CustomReviews reviews={reviews} ratingAvg={averageValue ? averageValue:reviewNO} allReviews={allReviews} />
            <Card classes={classes.cardRoot}>
                <CardContent className={classes.root}>
                    <Typography
                        variant="h5"
                        component="h2"
                        className={classes.title}
                    >
                        {'Customer Reviews'}
                        <span className={`${classes.subtext} ${classes.paddingLeft}`}>
                            ({reviews.length})
                        </span>
                    </Typography>
                    <Suspense fallback="...Loading">
                        {!isNarrow ? <DesktopReviews cloudfront={cloudfront} classes={classes} reviews={reviews.slice(0, visible)} guideDetails={guideDetails} /> : <MobileReviews cloudfront={cloudfront} classes={classes} reviews={reviews.slice(0, visible)} guideDetails={guideDetails} />}
                    </Suspense>
                    {
                        visible >= 15 && reviews.length != visible && reviews.length >= visible ?
                            <p className={classes.allReviews} onClick={handleLoadmore}>{`see all reviews >`}</p>
                            : null
                    }
                </CardContent>
            </Card>
        </>
    );
}

export default Reviews
