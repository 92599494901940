import React, {useState} from 'react'
import moment from "moment";
import "./style.scss"

export default function GuideRely({item, classes, cloudfront, guideDetails}) {
    const [show, setShow] = useState(true);
    const [more, setMore] = useState(false);
    const getName = (name) => {
        var fullName = name.split(' ')
        if (fullName[1]) {
            return `${fullName[0]} ${fullName[1][0]}`
        }
        return `${fullName[0]}`
    }
    const name = guideDetails?.guidename ? getName(guideDetails?.guidename) : '';
    const guideImg = `${cloudfront}${guideDetails?.guidepic}`
    console.log(name, guideImg)
    return (
        <div className="reply__container">
            <div className="reply__header">
                <div className="reply__text">
                    Guide Reply
                </div>
                <div className="reply__titleButton" onClick={() => setShow(!show)}>
                    <span className="reply__titleButton_text" >{show ? "Hide Reply" : "Show Reply"}</span>
                    <span className="reply__titleButton_icon">{show ? <>&lt;</> : <>&gt;</>}</span>
                </div>
            </div>
            <div className={`"reply__body" ${show ? "reply__expanded" : "reply__hidden"}`} >
                <div className="reply__title" >
                    <div className="reply__guide">
                        <div className="reply__guide__img">
                            {
                                guideDetails?.guidepic !== null ?
                                    <div className={classes.horizontalAlignCenter}>
                                        <img src={guideImg} className={`${classes.img}`} alt={'image'} loading="eager" width="100" height="100"/>
                                    </div>
                                    :
                                    <div className={`${classes.img}`} style={{
                                        background: "#224041", backgroundRepeat: "no-repeat", backgroundPosition: "top", backgroundSize: "cover", textAlign: "center", borderRadius: '5px'
                                    }}>
                                        <span className="head_shot" >{`${guideDetails?.firstname?.split("")[0].toUpperCase()} ${guideDetails?.lastname?.split("")[0].toUpperCase()}`}</span>
                                    </div>
                            }
                        </div>
                        <div className="reply__guide__details">
                            <div className="reply__guide__name">
                                <span className={`${classes.subtext} ${classes.name} RatesFontColor`}>{name}</span>
                            </div>
                            {item.created_at && <div className="reply__guide__time">
                                <div className={`${classes.subtext} RatesFontColor`}>
                                    {`${moment(item.reply_update_time).format('MMMM D, YYYY')}`}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="reply__comment">
                    <div className={more ? "reply__comment__textFull" : "reply__comment__textShort"} dangerouslySetInnerHTML={{ __html: item.reply_comment }}/>
                </div>
                <div className="reply__readMore" onClick={() => setMore(!more)}>
                    {more ? "Read Less " : "Read More "}<span className="reply__readMore_icon">&#62;&#62;</span>
                </div>
            </div>
        </div>
    )
};
