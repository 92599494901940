import axios from 'axios';

export const get = (URL) => {
  const url = `${apiHost}${URL}`; // eslint-disable-line no-undef
  return axios
    .get(url)
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const post = (URL, body, headers = {}) => {
  const url = `${apiHost}${URL}`; // eslint-disable-line no-undef
  return axios
    .post(url, body, { ...headers })
    .then((response) => response)
    .catch((error) => console.log(error));
};
