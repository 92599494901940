import React from 'react'
import ThemeProvider from '../../widgets/theme';
import Reports from './reports'

const ReviewContainer = (props) =>
  <ThemeProvider {...props} >
    <Reports {...props} />
  </ThemeProvider>

export default ReviewContainer
