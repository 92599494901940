import React from "react";
import Slider from "react-slick"
import './style.scss'
function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={`${className} Reviewnext`}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={`${className} Reviewprev`}
			onClick={onClick}
		/>
	);
}

function Pass(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={`${className}`}
			onClick={onClick}
		/>
	);
}

const SlickCarousel = ({ data }) => {
	var settings = {
		dots: true,
		infinite: false,
		appendDots: dots => <ul>{dots}</ul>,
		customPaging: i => (
			<button className="slick__dots--custom">
			</button>
		),
		slidesToShow: 3,
		slidesToScroll: 3,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1700,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	}
	return (
		<div >
			<Slider className={'fishSlider'} {...settings}>{data}</Slider>
		</div>
	)
}
export default SlickCarousel
