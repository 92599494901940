import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  'Addbutton': {
    'box-sizing': 'border-box',
    cursor: 'pointer',
    display: 'inline',
    'line-height': 'inherit',
    margin: 0,
    outline: 'none',
    padding: '4px 10px',
    'font-weight': 'bold',
    'background-color': '#1a04ec',
    color: 'white',
    border: '1px solid #1a04ec',
    padding: 6,
    cursor: 'pointer',
    'text-align': 'center',
    height: 40,
    'box-shadow': '0 8px 10px -6px black',
    'border-radius': 5,
    'font-size': 14,
    width: '100%',
    '&:focus': {
      'background-color': '#1a04ec',
    },
    '&:hover': {
      'background-color': '#1a04ec',
    }
  },
  'dropdown': {
    'background-color': 'white',
    'box-sizing': 'border-box',
    'text-align': 'left',
    'white-space': 'nowrap',
    width: '100%',
    'z-index': 1,
    display: 'flex',
    'margin-top': '1rem',
    'flex-direction': 'column',
    'font-size': 14,
    cursor: 'pointer'
  },
  'dropdown a': {
    color: '#007bff',
    display: 'block',
    'text-decoration': 'none'
  },
  'dropdown a:hover': {
    'background-color': 'white',
    'text-decoration': 'underline',
  }
}));
