import { CircularProgress, Grid, Typography, Button, Tooltip } from '@material-ui/core'
import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import TripDetailsContext from '../../state/context/TripDetailsContext'
import { getAdditionalPersonCharge, getTotalCharge, displayMoney } from '../../utils/payment'
import convertTo12Hour, { getDuration, getTimeFormat } from '../../utils/time'
import useStyles from './tripsummarystyles'
import { TYPES } from '../description';
import getSymbolFromCurrency from 'currency-symbol-map'

const InfoIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/black_info.png'
const TripSummaryView = ({ handlePayment, previous, loading, activateBtn }) => {
    const classes = useStyles()
    const tripDetailsContext = useContext(TripDetailsContext);
    const {
        selectedHour,
        selectedRateCard,
        guestCount,
        addOnFees,
        selectedDay,
        setAddOnFees,
        tipamount
    } = tripDetailsContext;

    const additionalCharge = getAdditionalPersonCharge(
        selectedRateCard,
        guestCount,
        selectedHour.rate ||
        (((selectedRateCard || {}).rates || [])[0] || {}).rate,
    );

    const tipAmount = tipamount && tipamount !== 'uponCash' ? Number(tipamount) : 0 

    let total;
    let chargeToday;
    let chargeOnDayOfTrip;
    let totalIncludingMatesFees;

    if (selectedHour.rate) {
        total = getTotalCharge(
            selectedRateCard,
            guestCount,
            selectedHour.rate,
            addOnFees
        );

        let fees = addOnFees.map(item => isNaN(item.charge_price) ? 0 : item.charge_price);
        fees = fees.reduce((a, b) => a + b, 0)

        totalIncludingMatesFees = total + fees + tipAmount

        chargeToday = ((total + fees) * 0.20).toFixed(2);

        chargeOnDayOfTrip = totalIncludingMatesFees - chargeToday;
    }

    useEffect(() => {
        const addon_fees = addOnFees.map(item => {
            let itm = item;
            itm.charge_price = item.charge_value ? item.charge_value : (total / 100) * item.charge_percentage
            itm.charge_price = parseInt(itm.charge_price, 10)
            return itm;
        })
        setAddOnFees(addon_fees)
    }, [selectedHour, selectedRateCard, total])

    return (
        <Grid className={classes.card}>
            <Typography className={classes.heading}>Trip Summary</Typography>
            <Grid className={classes.details} container>
                <Grid className={classes.left} item xs={6}>
                    <Typography component="div" className={classes.guest}>
                        {`${guestCount} Guest`}
                    </Typography>
                    <Typography component="div" className={classes.guest}>
                        {moment(selectedDay).format('MMM Do, YYYY')}
                    </Typography>
                </Grid>
                <Grid style={{ paddingLeft: 10 }} item xs={6}>
                    <Typography component="div" className={classes.guest}>
                        {selectedRateCard.title}
                    </Typography>
                    <Typography className={classes.fontSize} component="div">
                        {`${getDuration(selectedHour)
                            } at ${convertTo12Hour(
                                selectedHour.start_time,
                            )}`}
                    </Typography>
                    <Typography className={classes.fontSize} component="div">
                        {selectedRateCard.ratetype} <span style={{ position: 'relative', top: 7 }}>
                            <Tooltip classes={{ tooltip: classes.tooltip }} enterTouchDelay={0} title={TYPES[selectedRateCard.ratetype.toLowerCase()]}>
                                <img src={InfoIcon} />
                            </Tooltip>
                        </span>
                    </Typography>
                    <Typography className={classes.fontSize} component="div">
                        {selectedRateCard.guests} person capacity
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.hbar}>
            </div>
            <Typography className={classes.cardRow}>
                <span>
                    Trip Cost
                </span>
                <span>
                    {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((selectedHour.rate || 0).toFixed(2)) || '0.00'}`}
                </span>
            </Typography>
            {
                tripDetailsContext.tipamount && tripDetailsContext.tipamount > 0 ?
                <Typography className={classes.cardRow}>
                    <span>
                        Tip
                    </span>
                    <span>
                        {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney(tripDetailsContext.tipamount)}`}
                    </span>
                </Typography> : null
            }
            {additionalCharge && additionalCharge !== 0 ? (
                <Typography className={classes.cardRow}>
                    <span
                        variant="subtitle2"
                        component="div"
                        className={`${classes.subtitle2} ${classes.bold}`}
                    >
                        Additional Guest Fee
                    </span>
                    <span
                        variant="subtitle2"
                        component="div"
                        className={`${classes.subtitle2} ${classes.bold}`}
                    >
                        {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((additionalCharge || 0).toFixed(2))}`}
                    </span>
                </Typography>
            ) : null}
            {addOnFees.length > 0 && total && addOnFees.map(item => <Typography className={classes.cardRow}>
                <span variant="subtitle2" component="div" className={`${classes.subtitle2} ${classes.bold}`}>
                    {item.title}
                </span>
                <span variant="subtitle2" component="div" className={`${classes.subtitle2} ${classes.bold}`}>
                    {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((item.charge_price || 0).toFixed(2))}`}
                </span>
            </Typography>)}
            <div className={classes.hbar}></div>
            <div className={classes.amtContainer}>
                <Typography className={classes.cardRow}>
                    <span>
                        Total Trip Costs
                    </span>
                    <span>
                        {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney(((totalIncludingMatesFees) || 0).toFixed(2))}`}
                    </span>
                </Typography>
                <Typography className={classes.hbar}></Typography>
                <Typography className={classes.cardRow}>
                    <span>
                        Charged Today (20% Deposit)
                    </span>
                    <span>
                    {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${chargeToday ? displayMoney(chargeToday) : '0.00'}`}
                    </span>
                </Typography>
                <Typography className={classes.cardRow}>
                    <span>
                       {tripDetailsContext.tipamount && tripDetailsContext.tipamount != 0 && tripDetailsContext.tipamount != 'uponCash' ? 'Charged on Day of Trip (+ tip)' : 'Charged on Day of Trip'}
                    </span>
                    <span>
                        {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')} ${displayMoney((chargeOnDayOfTrip || 0).toFixed(2))}`}
                    </span>
                </Typography>
            </div>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttonContainer}>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.previous}
                    onClick={previous}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={`${classes.next} ${activateBtn || (tripDetailsContext.tipamount === 'Custom' || !tripDetailsContext.tipamount) ? 'disabled' : ''}`}
                    onClick={handlePayment}
                    disabled={activateBtn || (tripDetailsContext.tipamount === 'Custom' || !tripDetailsContext.tipamount)}
                >
                    {loading ? <CircularProgress size={23} color="inherit" /> : 'Confirm Payment'}
                </Button>
            </Grid>
            {/* <Typography className={classes.fontSize} style={{ textAlign: 'center', marginTop: 10 }}>
                -Free cancellation up to 24 hours of trip-
                </Typography> */}
        </Grid>
    )
}

export default TripSummaryView