import React from 'react';
import PropTypes from 'prop-types';
import ReactDayPicker from 'react-day-picker';
import Helmet from 'react-helmet';
import { daypickerStyles } from './styles';

const DayPicker = ({
  month,
  fromMonth,
  toMonth,
  modifiersStyles,
  modifiers,
  className,
  selectedDay,
  onDayClick,
  numberOfMonths = 1,
}) => (
  <div>
    <div>
      <Helmet>
        <style>{daypickerStyles}</style>
      </Helmet>
      <ReactDayPicker
        month={month}
        fromMonth={fromMonth}
        toMonth={toMonth}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        className={className}
        selectedDays={selectedDay}
        onDayClick={onDayClick}
        numberOfMonths={numberOfMonths}
      />
    </div>
  </div>
);

DayPicker.propTypes = {
  month: PropTypes.any,
  fromMonth: PropTypes.instanceOf(Date).isRequired,
  toMonth: PropTypes.instanceOf(Date).isRequired,
  modifiers: PropTypes.shape({
    availability: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    scheduleDisable: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    // today: PropTypes.instanceOf(Date).isRequired,
    unavailabity: PropTypes.arrayOf(
      PropTypes.shape({
        after: PropTypes.instanceOf(Date),
        before: PropTypes.instanceOf(Date),
      }),
    ),
  }).isRequired,
  modifiersStyles: PropTypes.shape({
    availability: PropTypes.shape({}),
    scheduleDisable: PropTypes.shape({}),
    today: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string.isRequired,
  selectedDay: PropTypes.any,
  onDayClick: PropTypes.func.isRequired,
};

export default DayPicker;
