import moment from "moment"
import { isBrowser } from "./constants";

export const NavPaths = (title, snippets = {}, isFooter) => {
    let links = [
        { id: 1, name: "Home", url: typeof window !== "undefined" && window.location.pathname === '/' ? '#' : '/' },
        { id: 2, name: "Trips & Rates", url: '/' + (snippets.s110_rates_url || '').toLowerCase() },
        { id: 3, name: "Gallery", url: '/' + (snippets.s130_photos_url || '').toLowerCase() },
        { id: 4, name: "Reviews", url: '/' + (snippets.s120_reviews_url || '').toLowerCase() },
        { id: 5, name: `${snippets.s20_categoryname1} Reports`, url: '/' + (snippets.s140_reports_url || '').toLowerCase() },
        { id: 6, name: `Target ${snippets.s22_categoryname3}`, url: '/' + (snippets.s150_species_url || '').toLowerCase() },
        { id: 7, name: `Meet the ${snippets.s6_captain_or_guide}`, url: '/' + (snippets.s160_meet_url || '').replace(/ /g, '-').toLowerCase() },
    ];

    if (isFooter) {
        links = [
            ...links,
            { id: 8, name: `FAQ - Fishing`, url: '/' + (snippets.s170_faq1_url || '').replace(/ /g, '-').toLowerCase() },
            /* { id: 9, name: `FAQ - Policies`, url: (snippets.s180_faq2_url || '')..replace(/ /g, '-').toLowerCase() }, */
            { id: 10, name: `Contact Us`, url: '/' + (snippets.s190_contactus_url || '').replace(/ /g, '-').toLowerCase() },
        ]
    }

    return links
}

export const MTNavPaths = (title,territorySubmenu, aboutusSubmenu,snippets = {}) => {
    let links = [
        { id: 1, name: "Trips & Rates", url: '/' + (snippets.s110_rates_url || '').toLowerCase() },
        { id: 2, name: "Territories", url:"",
            subnav: territorySubmenu },
        { id: 3, name: "Gallery", url: '/' + (snippets.s130_photos_url || '').toLowerCase() },
        { id: 4, name: "Reports & Reviews", url: '/' + ('reports-reviews' || '').toLowerCase() },
        {
            id: 5, name: `About Us`, url: '',
            subnav: aboutusSubmenu
        },
    ];

    return territorySubmenu.length>0?links:links.filter(rec=>rec.id !=2)
}

export const Convto12 = (time) => {
    if (time) {
        return moment(time, ["HH:mm:ss"]).format("h:mm A")
    }
};
export const setGuideData = user => (window.localStorage.gatsbyUser = JSON.stringify(user))
export const getGuide = () =>
    isBrowser && window.localStorage.gatsbyUser
        ? JSON.parse(window.localStorage.gatsbyUser)
        : {}

export const GuideData = async () => {
    let newGuideDetails = {}
    return newGuideDetails;
}
export const getCorrectedImagePath = (image) => {
    if (image) {
        var correctPath = image.charAt(0) === '/' ? image.slice(1) : image
        return correctPath;
    }
}

export const getOS = () => {
    let isMac;
    const isBrowser = typeof window !== `undefined`
    if (isBrowser && window.navigator.userAgent.indexOf('Mac OS X') != -1) {
        isMac = true
    } else {
        isMac = false
    }
    return isMac
}
export const TripType = () => {
    return [
        {
            key: "Shore",
            value: "Shore",
            label: "Shore Fishing",
            description:
                "Surf casting, wade fishing, or any fishing on land without a boat"
        },
        {
            key: "Inshore",
            value: "Inshore",
            label: "Inshore Fishing",
            description:
                "Fishing in inshore or nearshore waters, within a few miles from land"
        },
        {
            key: "Offshore",
            value: "Offshore",
            label: "Offshore Fishing",
            description:
                "Fishing the open ocean out of sight of land.  From around ten to hundreds of miles offshore."
        },
        {
            key: "Wrecks",
            value: "Wrecks",
            label: "Wrecks/Reefs Fishing",
            description:
                "Fishing the structure provided by wrecks for bottom fish or pelagics"
        },
        {
            key: "Flats",
            value: "Flats",
            label: "Flats/Shallows Fishing",
            description:
                "Fishing the shallow flats in as little as a foot of water, usually in a skiff."
        },
        {
            key: "BackCountry",
            value: "BackCountry",
            label: "Backcountry Fishing",
            description: "Fishing inshore islands, saltwater creeks, and flats"
        },
        {
            key: "Lake",
            value: "Lake",
            label: "Lakes/Reservoirs/Ponds Fishing",
            description: "Fishing on a lake"
        },
        {
            key: "River",
            value: "River",
            label: "River Fishing",
            description: "Fishing on a river"
        }
    ];
};
export const getGuideTraits = (data) => {
    const guidetraitsArr = []
    if (Object.keys(data).length > 0) {
        if (data.s1) {
            guidetraitsArr.push("Master Guide")
        }
        if (data.s2) {
            guidetraitsArr.push("Technical Fishing")
        }
        if (data.s3) {
            guidetraitsArr.push("Fly Fishing")
        }
        if (data.s4) {
            guidetraitsArr.push("Deep Sea Fishing")
        }
        if (data.s5) {
            guidetraitsArr.push("Drift Fishing")
        }
        if (data.s6) {
            guidetraitsArr.push("Good with Large Groups")
        }
        if (data.s7) {
            guidetraitsArr.push("Good with New Anglers")
        }
        if (data.s8) {
            guidetraitsArr.push("Good with Families")
        }
        if (data.s9) {
            guidetraitsArr.push("Good with Kids")
        }
        if (data.s10) {
            guidetraitsArr.push("Nature / Wildlife Views")
        }
        if (data.s11) {
            guidetraitsArr.push("Saltwater Fishing")
        }
        if (data.s12) {
            guidetraitsArr.push("Freshwater Fishing")
        }
        if (data.s13) {
            guidetraitsArr.push("Bass Fishing")
        }
        if (data.s14) {
            guidetraitsArr.push("I Tie My Own Flies")
        }
        if (data.s15) {
            guidetraitsArr.push("Live Bait")
        }
    }
    return guidetraitsArr
}
export const getGuideGears = (data) => {
    const gearArr = []
    if (Object.keys(data).length > 0) {
        if (data?.gear && data?.gear[0].camera === true) {
            gearArr.push("Camera")
        }
        if (data?.gear && data?.gear[0].fishing_poles && data.gear[0].fishing_poles === true) {
            gearArr.push("Fishing Poles")
        }
        if (data?.gear && data?.gear[0].flies === true) {
            gearArr.push("Flies")
        }
        if (data?.gear && data?.gear[0].fly_box === true) {
            gearArr.push("Fly Box")
        }
        if (data?.gear && data?.gear[0].fly_lines === true) {
            gearArr.push("Fly Lines")
        }
        if (data?.gear && data?.gear[0].insect_repellant === true) {
            gearArr.push("Insect Repellant")
        }
        if (data?.gear && data?.gear[0].leader_tippet === true) {
            gearArr.push("Leader Tippet")
        }
        if (data?.gear && data?.gear[0].live_bait === true) {
            gearArr.push("Live Bait")
        }
        if (data?.gear && data?.gear[0].lures === true) {
            gearArr.push("Lures")
        }
        if (data?.gear && data?.gear[0].medical_supplies === true) {
            gearArr.push("Medical Supplies")
        }
        if (data?.gear && data?.gear[0].reels === true) {
            gearArr.push("Reels")
        }
        if (data?.gear && data?.gear[0].waders === true) {
            gearArr.push("Waders")
        }
        if (data?.gear && data?.gear[0].rods === true) {
            gearArr.push("Rods")
        }
        if (data?.gear && data?.gear[0].sunscreen === true) {
            gearArr.push("Sunscreen")
        }
    }
    return gearArr
}

export const getGuideGears1 = (data) => {
    const gearArr = []
    if (Object.keys(data).length > 0) {
        if (data?.gears && data?.gears?.camera === true) {
            gearArr.push("Camera")
        }
        if (data?.gears && data?.gears?.fishing_poles && data?.gears?.fishing_poles === true) {
            gearArr.push("Fishing Poles")
        }
        if (data?.gears && data?.gears?.flies === true) {
            gearArr.push("Flies")
        }
        if (data?.gears && data?.gears?.fly_box === true) {
            gearArr.push("Fly Box")
        }
        if (data?.gears && data?.gears?.fly_lines === true) {
            gearArr.push("Fly Lines")
        }
        if (data?.gears && data?.gears?.insect_repellant === true) {
            gearArr.push("Insect Repellant")
        }
        if (data?.gears && data?.gears?.leader_tippet === true) {
            gearArr.push("Leader Tippet")
        }
        if (data?.gears && data?.gears?.live_bait === true) {
            gearArr.push("Live Bait")
        }
        if (data?.gears && data?.gears?.lures === true) {
            gearArr.push("Lures")
        }
        if (data?.gears && data?.gears?.medical_supplies === true) {
            gearArr.push("Medical Supplies")
        }
        if (data?.gears && data?.gears?.reels === true) {
            gearArr.push("Reels")
        }
        if (data?.gears && data?.gears?.waders === true) {
            gearArr.push("Waders")
        }
        if (data?.gears && data?.gears?.rods === true) {
            gearArr.push("Rods")
        }
        if (data?.gears && data?.gears?.sunscreen === true) {
            gearArr.push("Sunscreen")
        }
    }
    return gearArr
}

export const getGuideFeatures = (data) => {
    const gearArr = []
    if (Object.keys(data).length > 0) {
        if (data.gps === true) {
            gearArr.push("GPS")
        }
        if (data.fish_finder === true) {
            gearArr.push("Fish Finder")
        }
        if (data.vhf_radio === true) {
            gearArr.push("Fighting Chair")
        }
        if (data.fighting_chair === true) {
            gearArr.push("VHS Radio")
        }
        if (data.radar === true) {
            gearArr.push("Radar")
        }
        if (data.flybridge === true) {
            gearArr.push("Flybridge")
        }
        if (data.toilet === true) {
            gearArr.push("Toilet")
        }
        if (data.shower === true) {
            gearArr.push("Shower")
        }
        if (data.kitchen === true) {
            gearArr.push("Kitchen")
        }
        if (data.bed === true) {
            gearArr.push("Bed")
        }
        if (data.wheel_chair_accessible === true) {
            gearArr.push("Wheelchair Accessible")
        }
        if (data.aircondition === true) {
            gearArr.push("Air Condition")
        }
        if (data.multimedia === true) {
            gearArr.push("Multimedia System")
        }
        if (data.tv === true) {
            gearArr.push("Television")
        }
        if (data.wireless_trolling_motor === true) {
            gearArr.push("Wireless Trolling Motor")
        }
        if (data.refrigerator === true) {
            gearArr.push("Refrigerator")
        }
        if (data.ice_box === true) {
            gearArr.push("Ice-Box")
        }
        if (data.fighting_chair === true) {
            gearArr.push("Fighting Chair")
        }
        if (data.firstmate === true) {
            gearArr.push("First Mate")
        }
        if (data.live_bait_tank === true) {
            gearArr.push("Livewell / Live Bait Tank")
        }
        if (data.spear_fishing_equipment === true) {
            gearArr.push("Spearfishing Equipment")
        }
        if (data.snor_kelling_equipment === true) {
            gearArr.push("Snorkelling Equipment")
        }
        if (data.outriggers === true) {
            gearArr.push("Outriggers")
        }
        if (data.down_riggers === true) {
            gearArr.push("Downriggers")
        }
        if (data.tuna_tubes === true) {
            gearArr.push("Tuna Tubes")
        }
    }

    return gearArr
}

export const getTimeFormat = (rec) => {
    let showTime = ""
    const time = rec.title != null && rec.title.split(" ")[0]
    const hr = moment(time, "h.mm").hours()
    const min = moment(time, "h.mm").minutes()
    if (min > 0) {
        showTime = hr === 1 ? `${hr} hr ${min} min` : `${hr} hrs ${min} min`
    } else {
        showTime = hr === 1 ? `${hr} hr` : `${hr} hrs`
    }
    return showTime
}
export const tripCost = (data) => {
    if (data.rate) {
        return Number(data.rate).toFixed(2)
    }
}

export const getDiff = (date) => {
    var a = moment(new Date());
    var b = moment(date);

    var years = a.diff(b, 'year');
    b.add(years, 'years');

    var months = a.diff(b, 'months');
    b.add(months, 'months');

    return years === 1 ? `${years} Year` : years > 1 ? `${years} Years` : years === 0 && months > 1 ? `${months} Months` : `${months} Month`
}

export const showPerson = (data, count1) => {
    let guest = parseInt(data.rcguest && data.rcguest.split(" ")[0])
    let count = parseInt(count1);
    let diff = count - guest
    const Total = data.rate
    if (count > guest) {
        if (data.additional_perperson_rate_type === "P" && diff >= 1) {
            return (`${((data.additional_perperson_rate / 100 * Total) * diff).toFixed(2)}`)
        } else if (data.additional_perperson_rate_type === "V" && diff >= 1) {
            return (`${(data.additional_perperson_rate * diff).toFixed(2)}`)
        }
    } else {
        return 0.00
    }
}
export const addOnMateFee = (data, fee, count) => {
    let tripcost = Number(tripCost(data))
    let addon = Number(showPerson(data, count))
    let Total = tripcost + addon
    if (fee.charge_percentage) {
        return ((Number(fee.charge_percentage) / 100 * Total).toFixed(2))
    } else {
        return (Number(fee.charge_value).toFixed(2))
    }
}
export const addPerson = (data, matesfee, count1, tip) => {
    const tipAmount = tip && tip !== 'uponCash' ? Number(tip) : 0
    let matefee = 0
    if (matesfee && matesfee.length > 0) {
        matesfee.map(item => {
            matefee = matefee + Number(addOnMateFee(data, item, count1))
        })
    }
    let tripcost = Number(tripCost(data))
    let addon = Number(showPerson(data, count1))
    let Total = tripcost + addon + matefee + tipAmount
    return (Number(Total).toFixed(2))
}
export const displayMoney = (data) => {
    var str
    if (Number.isInteger(data)) {
        str = Number(data).toLocaleString(undefined);
    } else {
        str = Number(data).toLocaleString(undefined, { minimumFractionDigits: 2 });
    }
    return str
}

export const isCrawler = () => {
    const isBrowser = typeof window !== `undefined`
    const isCrawler = isBrowser ? (window.navigator.userAgent.toLowerCase().includes('lighthouse') ||
        window.navigator.userAgent.toLowerCase().includes('pagespeed')) : true;

    return isCrawler
}

export const setPhoneNumberFormat = (number) => {
    if (number) {
        let tel = String(number).replace(/\D+/g, "").slice(-10)
        let match = tel.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + ' - ' + match[3];
        }
        return null;
    }
    return null;
}

export const states = [
    {
        "State": "Alabama",
        "Abbrev": "Ala.",
        "Code": "AL"
    },
    {
        "State": "Alaska",
        "Abbrev": "Alaska",
        "Code": "AK"
    },
    {
        "State": "Arizona",
        "Abbrev": "Ariz.",
        "Code": "AZ"
    },
    {
        "State": "Arkansas",
        "Abbrev": "Ark.",
        "Code": "AR"
    },
    {
        "State": "California",
        "Abbrev": "Calif.",
        "Code": "CA"
    },
    {
        "State": "Colorado",
        "Abbrev": "Colo.",
        "Code": "CO"
    },
    {
        "State": "Connecticut",
        "Abbrev": "Conn.",
        "Code": "CT"
    },
    {
        "State": "Delaware",
        "Abbrev": "Del.",
        "Code": "DE"
    },
    {
        "State": "District of Columbia",
        "Abbrev": "D.C.",
        "Code": "DC"
    },
    {
        "State": "Florida",
        "Abbrev": "Fla.",
        "Code": "FL"
    },
    {
        "State": "Georgia",
        "Abbrev": "Ga.",
        "Code": "GA"
    },
    {
        "State": "Hawaii",
        "Abbrev": "Hawaii",
        "Code": "HI"
    },
    {
        "State": "Idaho",
        "Abbrev": "Idaho",
        "Code": "ID"
    },
    {
        "State": "Illinois",
        "Abbrev": "Ill.",
        "Code": "IL"
    },
    {
        "State": "Indiana",
        "Abbrev": "Ind.",
        "Code": "IN"
    },
    {
        "State": "Iowa",
        "Abbrev": "Iowa",
        "Code": "IA"
    },
    {
        "State": "Kansas",
        "Abbrev": "Kans.",
        "Code": "KS"
    },
    {
        "State": "Kentucky",
        "Abbrev": "Ky.",
        "Code": "KY"
    },
    {
        "State": "Louisiana",
        "Abbrev": "La.",
        "Code": "LA"
    },
    {
        "State": "Maine",
        "Abbrev": "Maine",
        "Code": "ME"
    },
    {
        "State": "Maryland",
        "Abbrev": "Md.",
        "Code": "MD"
    },
    {
        "State": "Massachusetts",
        "Abbrev": "Mass.",
        "Code": "MA"
    },
    {
        "State": "Michigan",
        "Abbrev": "Mich.",
        "Code": "MI"
    },
    {
        "State": "Minnesota",
        "Abbrev": "Minn.",
        "Code": "MN"
    },
    {
        "State": "Mississippi",
        "Abbrev": "Miss.",
        "Code": "MS"
    },
    {
        "State": "Missouri",
        "Abbrev": "Mo.",
        "Code": "MO"
    },
    {
        "State": "Montana",
        "Abbrev": "Mont.",
        "Code": "MT"
    },
    {
        "State": "Nebraska",
        "Abbrev": "Nebr.",
        "Code": "NE"
    },
    {
        "State": "Nevada",
        "Abbrev": "Nev.",
        "Code": "NV"
    },
    {
        "State": "New Hampshire",
        "Abbrev": "N.H.",
        "Code": "NH"
    },
    {
        "State": "New Jersey",
        "Abbrev": "N.J.",
        "Code": "NJ"
    },
    {
        "State": "New Mexico",
        "Abbrev": "N.M.",
        "Code": "NM"
    },
    {
        "State": "New York",
        "Abbrev": "N.Y.",
        "Code": "NY"
    },
    {
        "State": "North Carolina",
        "Abbrev": "N.C.",
        "Code": "NC"
    },
    {
        "State": "North Dakota",
        "Abbrev": "N.D.",
        "Code": "ND"
    },
    {
        "State": "Ohio",
        "Abbrev": "Ohio",
        "Code": "OH"
    },
    {
        "State": "Oklahoma",
        "Abbrev": "Okla.",
        "Code": "OK"
    },
    {
        "State": "Oregon",
        "Abbrev": "Ore.",
        "Code": "OR"
    },
    {
        "State": "Pennsylvania",
        "Abbrev": "Pa.",
        "Code": "PA"
    },
    {
        "State": "Rhode Island",
        "Abbrev": "R.I.",
        "Code": "RI"
    },
    {
        "State": "South Carolina",
        "Abbrev": "S.C.",
        "Code": "SC"
    },
    {
        "State": "South Dakota",
        "Abbrev": "S.D.",
        "Code": "SD"
    },
    {
        "State": "Tennessee",
        "Abbrev": "Tenn.",
        "Code": "TN"
    },
    {
        "State": "Texas",
        "Abbrev": "Tex.",
        "Code": "TX"
    },
    {
        "State": "Utah",
        "Abbrev": "Utah",
        "Code": "UT"
    },
    {
        "State": "Vermont",
        "Abbrev": "Vt.",
        "Code": "VT"
    },
    {
        "State": "Virginia",
        "Abbrev": "Va.",
        "Code": "VA"
    },
    {
        "State": "Washington",
        "Abbrev": "Wash.",
        "Code": "WA"
    },
    {
        "State": "West Virginia",
        "Abbrev": "W.Va.",
        "Code": "WV"
    },
    {
        "State": "Wisconsin",
        "Abbrev": "Wis.",
        "Code": "WI"
    },
    {
        "State": "Wyoming",
        "Abbrev": "Wyo.",
        "Code": "WY"
    }
]

export const AnimalData = () => {

	return [
		{ "id": 1, "label": "Bear", "value": "Bear", "img": null, "checked": false },
		{ "id": 2, "label": "Blacktail Deer", "value": "Blacktail Deer", "img": null, "checked": false },
		{ "id": 3, "label": "Boar", "value": "Boar", "img": null, "checked": false },
		{ "id": 4, "label": "Cougar", "value": "Cougar", "img": null, "checked": false },
		{ "id": 5, "label": "Duck", "value": "Duck", "img": null, "checked": false },
		{ "id": 6, "label": "Elk", "value": "Elk", "img": null, "checked": false },
		{ "id": 7, "label": "Goose", "value": "Goose", "img": null, "checked": false },
		{ "id": 8, "label": "Moose", "value": "Moose", "img": null, "checked": false },
		{ "id": 9, "label": "Mule Deer", "value": "Mule Deer", "img": null, "checked": false },
		{ "id": 10, "label": "Red Deer", "value": "Red Deer", "img": null, "checked": false },
		{ "id": 11, "label": "Whitetail Deer", "value": "Whitetail Deer", "img": null, "checked": false }
	]
}

export const getNameInitials = (firstName, lastName, name, name_trimmed, name_len, guest_name) => {
	if (firstName || lastName) {
		const combineNameInitials = firstName.trim().charAt(0).toUpperCase() + lastName.trim().charAt(0).toUpperCase()
		return combineNameInitials
	} else {
        if(name) {
            return name_trimmed.split(/[\t\s]+/).map((n, i) => i == 0 || i == name_len ? n[0] : "").join("").toUpperCase()
        } else {
            const guest_name_trimmed = guest_name.trim();
            const guest_name_len = guest_name_trimmed.split(/[\t\s]+/).length - 1;
            return guest_name_trimmed.split(/[\t\s]+/).map((n, i) => i == 0 || i == guest_name_len ? n[0] : "").join("").toUpperCase()
        }
	}
}