export const getAdditionalPersonCharge = (selectedRates = { guest: 0 }, count, rate) => {
  const noPerson = count - selectedRates.guests;
  if (
    selectedRates.additional_perperson_rate_type === 'P' &&
    noPerson >= 1
  ) {
    return ((selectedRates.additional_perperson_rate / 100) * rate * noPerson);
  }
  if (
    selectedRates.additional_perperson_rate_type === 'V' &&
    noPerson >= 1
  ) {
    return selectedRates.additional_perperson_rate * noPerson;
  }

  return null;
};

export const getTotalCharge = (selectedRates, count, rate = 0) => {
  const noPerson = count - selectedRates.guests;
  if (noPerson >= 1) {
    if (selectedRates.additional_perperson_rate_type === 'P') {
      return (
        rate +
        (selectedRates.additional_perperson_rate / 100) *
          rate *
          noPerson
      );
    }
    return (
      rate +
      selectedRates.additional_perperson_rate * noPerson
    );
  }

  return rate;
};

export const displayMoney = (data) => {
	var str
	if(Number.isInteger(data)) {
		str = Number(data).toLocaleString(undefined);
	} else {
		str = Number(data).toLocaleString(undefined,{ minimumFractionDigits: 2 });
	}
	return str
}
export const displayMoney1 = (data) => {
	var str
	if(Number.isInteger(data)) {
		str = Number(data).toLocaleString(undefined);
	} else {
		str = Number(data).toLocaleString(undefined,{ minimumFractionDigits: 0 });
	}
	return str
}
