import React from 'react';
import ThemeProvider from '../theme';
import TripDetailsProvider from '../state/providers/TripDetailsProvider';
import LiveCalendar from './Level2';

export default (props) => (
  <ThemeProvider {...props}>
    <TripDetailsProvider>
      <LiveCalendar {...props} />
    </TripDetailsProvider>
  </ThemeProvider>
);
