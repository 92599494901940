import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TripDetailsContext, {
  initialState,
} from '../context/TripDetailsContext';

const TripDetailsProvider = (props) => {
  const [step, setStep] = useState(initialState.step);
  const [schrecords, setSchRecords] = useState({})
  /* guide details */
  const [month, setMonth] = useState(initialState.month);
  const [fromMonth, setFromMonth] = useState(initialState.fromMonth);
  const [modifiers, setModifiers] = useState(initialState.modifiers);
  const [availableMeals, setAvailableMeals] = useState(
    initialState.availableMeals,
  );
  const [
    availableMeetingPoints,
    setAvailableMeetingPoints,
  ] = useState(initialState.availableMeetingPoints);
  const [availableWaterBodies, setAvailableWaterBodies] = useState(
    initialState.availableWaterBodies,
  );
  const [rateCards, setRateCards] = useState(initialState.rateCards);

  /* trip details */
  const [selectedDay, setSelectedDay] = useState(
    initialState.selectedDay,
  );
  const [firstName, setFirstName] = useState(initialState.firstName);
  const [lastName, setLastName] = useState(initialState.lastName);
  const [phoneNumber, setPhoneNumber] = useState(
    initialState.phoneNumber,
  );
  const [email, setEmail] = useState(initialState.email);
  const [meals, setMeals] = useState(initialState.meals);
  const [preferedWaterbody, setPreferedWaterbody] = useState(
    initialState.preferedWaterbody,
  );
  const [
    secondFavoriteWaterbody,
    setSecondFavoriteWaterbody,
  ] = useState(initialState.secondFavoriteWaterbody);
  const [meeetingPoint, setMeeetingPoint] = useState(
    initialState.meeetingPoint,
  );
  const [guidesNotes, setGuidesNotes] = useState(
    initialState.guidesNotes,
  );
  const [preferSelection, setPreferSelection] = useState(
    initialState.preferSelection,
  );
  const [needEquipments, setNeedEquipments] = useState(
    initialState.needEquipments,
  );
  const [error, setError] = useState(initialState.error);
  const [selectedRateCard, setSelectedRateCard] = useState(
    initialState.selectedRateCard,
  );
  const [selectedHour, setSelectedHour] = useState(
    initialState.selectedHour,
  );
  const [selectedTripType, setSelectedTripType] = useState(
    initialState.selectedTripType,
  );
  const [selectedTripHours, setSelectedTripHours] = useState(
    initialState.selectedTripHours,
  );
  const [selectedTripTime, setSelectedTripTime] = useState(
    initialState.selectedTripTime,
  );
  const [guestCount, setGuestCount] = useState(
    initialState.guestCount,
  );
  const [addOnCheck, setAddonCheck] = useState({})
	const [consumerAddOn, setConsumerAddOn] = useState([])
	const [addonAmt, setAddonAmt] = useState(0.00)
  const [selectionType, setSelectionType] = useState(initialState.selectionType);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [guideName, setGuideName] = useState(initialState.guideName);
  const [availableRateCards, setAvailableRateCards] = useState(initialState.availableRateCards);
  const [availableSchedules, setAvailableSchedules] = useState(initialState.availableSchedules);

  const [addOnFees, setAddOnFees] = useState(initialState.addOnFees)
  const [tipamount, setTipamount] = useState(initialState.tipamount)
  const [tipType, setTipType] = useState(initialState.tipType)
  const [bookingID, setBookingID] = useState()
  const [isGuestCountMaxed, setIsGuestCountMaxed] = useState(initialState.isGuestCountMaxed)
  const [paymentCardDetails, setPaymentCardDetails] = useState({})

  const [tippercentage, setTippercentage] = useState('20%')
  const [guideDetails, setGuideDetails] = useState({})
  const [depositAmt, setDepositAmt]=useState(null)
  const [bsAvailability, setBSAvailability]=useState([])
  const [referralGuideName, setReferralGuideName] = useState([]);
  const [referralMainId, setReferralMainId] = useState('');
  const [confirmationRate, setConfirmationRate] = useState([]);
  const [referralCompany, setReferralCompany] = useState('');
  const [referralLocation, setReferralLocation] = useState('');
  const [referralReview, setReferralReview] = useState('');
  const [referralConfirmationName, setReferralConfirmationName] = useState('');
  const [cancellationdays, setCancelationDays]=useState(null)
  const [depositAmtReferral, setDepositAmtReferral]=useState(null)
  const [bsDepositAmtReferral, setBsDepositAmtReferral] = useState(null)
  const [referralPercent, setReferralPercent]=useState('')

  const [availableSpecies, setAvailableSpecies] = useState([])
  const [requireFn, setRequiredFn] = useState(false);
	const [requireLn, setRequiredLn] = useState(false);
	const [requireEmail, setRequiredEmail] = useState(false);
	const [requirePhone, setRequiredPhone] = useState(false);
	const [source, setSource] = useState(null);
  const [sharedTripRateCapacity, setSharedTripRateCapacity] = useState(1)
  const [waiver, setWaiver] = useState(null)
  const [tripguid, setTripguid] = useState(null)
  const [userToken, setUserToken] = useState(null)
  const [userData, setUserData] = useState({})


  return (
    <TripDetailsContext.Provider
      value={{
        ...initialState,
        month,
        setMonth,
        modifiers,
        setModifiers,
        selectedDay,
        setSelectedDay,
        availableMeals,
        setAvailableMeals,
        availableMeetingPoints,
        setAvailableMeetingPoints,
        availableWaterBodies,
        setAvailableWaterBodies,
        rateCards,
        setRateCards,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        preferedWaterbody,
        setPreferedWaterbody,
        secondFavoriteWaterbody,
        setSecondFavoriteWaterbody,
        meals,
        setMeals,
        meeetingPoint,
        setMeeetingPoint,
        guidesNotes,
        setGuidesNotes,
        preferSelection,
        setPreferSelection,
        needEquipments,
        setNeedEquipments,
        error,
        setError,
        selectedHour,
        setSelectedHour,
        selectedRateCard,
        setSelectedRateCard,
        selectedTripHours,
        setSelectedTripHours,
        selectedTripType,
        setSelectedTripType,
        guestCount,
        setGuestCount,
        guideName,
        setGuideName,
        availableRateCards,
        setAvailableRateCards,
        availableSchedules,
        setAvailableSchedules,
        step,
        setStep,
        setSelectedTripTime,
        selectedTripTime,
        fromMonth,
        setFromMonth,
        addOnFees,
        setAddOnFees,
        tipamount,
        tipType,
        setTipType,
        setTipamount,
        bookingID,
        setBookingID,
        isGuestCountMaxed,
        setIsGuestCountMaxed,
        paymentCardDetails,
        setPaymentCardDetails,
        setTippercentage,
        tippercentage,
        guideDetails,
        setGuideDetails,
        selectionType, setSelectionType,
        depositAmt, setDepositAmt,
        bsAvailability, setBSAvailability,
        availableSpecies, setAvailableSpecies,
        referralGuideName, setReferralGuideName,
        referralMainId, setReferralMainId,
        confirmationRate, setConfirmationRate,
        depositAmtReferral, setDepositAmtReferral,
        bsDepositAmtReferral, setBsDepositAmtReferral,
        referralPercent, setReferralPercent,
        cancellationdays, setCancelationDays,
        schrecords, setSchRecords,
        requireFn, setRequiredFn,
        requireLn, setRequiredLn,
        requireEmail, setRequiredEmail,
        requirePhone, setRequiredPhone,
        source, setSource,
        sharedTripRateCapacity, setSharedTripRateCapacity,
        waiver, setWaiver,
        tripguid, setTripguid,
        userToken, setUserToken,
        companyDetails, setCompanyDetails,
        addOnCheck, setAddonCheck,
        consumerAddOn, setConsumerAddOn,
        addonAmt, setAddonAmt,
        userData, setUserData,
        referralCompany, setReferralCompany,
        referralLocation,setReferralLocation,
        referralReview, setReferralReview,
        referralConfirmationName, setReferralConfirmationName,
      }}
    >
      {props.children}
    </TripDetailsContext.Provider>
  );
};

TripDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TripDetailsProvider;
