const isBrowser = typeof window !== `undefined`
const getWeather = () =>
window.localStorage.cachedWeather
    ? JSON.parse(window.localStorage.cachedWeather)
    : null


export const setCacheWeather = weather => (window.localStorage.cachedWeather = JSON.stringify(weather))

export const getCachedWeather = () => isBrowser && getWeather()
export const WeatherStore = (data, lat, lng, city) => {
	let weatherDataClone = {}
	
	weatherDataClone.weather_days = data.days.slice(0, 8); //new name
	weatherDataClone.Expiry = new Date()
	weatherDataClone.city = city
	weatherDataClone.lat = lat
	weatherDataClone.lng = lng
	return weatherDataClone
}