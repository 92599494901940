import React from 'react'
import ThemeProvider from '../../widgets/theme';
import Reviews from './reviews'

const ReviewContainer = (props) =>
  <ThemeProvider {...props} >
    <Reviews {...props} />
  </ThemeProvider>

export default ReviewContainer
