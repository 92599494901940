const today = new Date(new Date().setHours(0, 0, 0, 0));
const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));

export const fromMonth = tomorrow;
export const toMonth = new Date(
    new Date(
        new Date().setFullYear(new Date().getFullYear() + 1),
    ).setHours(0, 0, 0, 0),
);
export const isBrowser = typeof window !== "undefined"

export const themeColors = {
    'dark_blue' : '',
    'soft_blue' : '',
    'matte_red' : '',
    'matte_green' : '',
    'black_white' : ''
}

export const SvgComponent = () => {
    const image = '<svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" width="47.4" height="40.65" viewBox="21 18.5 158 135.5"><path d="M25,50 l150,0 0,100 -150,0 z" stroke-width="4" stroke="black" fill="red" fill-opacity="1" ></path><path d="M25,50 L175,150 M25,150 L175,50" stroke-width="4" stroke="black" fill="black" ></path><g transform="translate(0,0)" stroke-width="4" stroke="black" fill="none" ><circle cx="100" cy="30" r="7.5" fill="black" ></circle><circle cx="70" cy="30" r="7.5" fill="black" ></circle><circle cx="130" cy="30" r="7.5" fill="black" ></circle></g></svg>';
    var path = `data:image/svg+xml;utf8,${image}`
    return path
}