import moment from 'moment'
export const convertTo12Hour = (time = '') => {
  return moment(time, 'hh:mm:ss').format('h:mm A')
};

export const getTimeFormat = (rec = '') => {
  let showTime = ""
  const time = rec?.split(" ")[0]
  const hr = moment(time, "h.mm").hours()
  const min = moment(time, "h.mm").minutes()
  if (min > 0 && !isNaN(min)) {
    showTime = hr === 1 ? `${hr} hr ${min} min` : `${hr} hrs ${min} min`
  } else if (!isNaN(hr)) {
    showTime = hr === 1 ? `${hr} hr` : `${hr} hrs`
  } else {
    showTime = `${time} hrs`
  }
  return showTime
}

export default convertTo12Hour;

export const getDurationAndPrice = (data) => {
  let value = ""
  const day = parseInt(data.duration_days) || 0
  if (day <= 1 && (!data.duration_hours || data.duration_hours === 0)) {
    value = `${day} day at ${convertTo12Hour(data.start_time)} - $${data.rate}`
  } else if (day > 1 && (!data.duration_hours || data.duration_hours === 0)) {
    value = `${day} days at ${convertTo12Hour(data.start_time)} - $${data.rate}`
  } else if (data.duration_hours != 0) {
    const hr = String(data.duration_hours)?.split(".")[0]
    const min = String(data.duration_hours)?.split(".")[1]
    const hrs = hr <= 1 ? `${hr} hr ${min} mins` : `${hr} hrs ${min} mins`
    const h = hr <= 1 ? `${hr} hr` : `${hr} hrs`
    value = `${min > 0 ? hrs : h} at ${convertTo12Hour(data.start_time)} - $${data.rate}`
  }
  return value
}

export const getDuration = (data) => {
  let value = ""
  const day = parseInt(data.duration_days) || 0
  if (day <= 1 && (!data.duration_hours || data.duration_hours === "0.00" || data.duration_hours === 0)) {
    value = `${day} day`
  } else if (day > 1 && (!data.duration_hours || data.duration_hours === "0.00" || data.duration_hours === 0)) {
    value = `${day} days`
  } else if (data.duration_hours != 0) {
    const hr = String(data.duration_hours)?.split(".")[0]
    const min = String(data.duration_hours)?.split(".")[1]
    const hrs = hr <= 1 ? `${hr} hr ${min} mins` : `${hr} hrs ${min} mins`
    const h = hr <= 1 ? `${hr} hr` : `${hr} hrs`
    value = `${min > 0 ? hrs : h}`
  }
  return value
}
