import React, { useEffect, useRef, useState, useReducer } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import LiveCalendarLevel1 from './widgets/live-calendar-level-1';
import LiveCalendarLevel2 from './widgets/live-calendar-level-2';
import RateCards from './widgets/rate-cards-widget'
import BookNow from './widgets/book-now'
import Reviews from './seo-widgets/reviews'
import Reports from './seo-widgets/reports'
import PhotoGallery from './seo-widgets/photo-gallery'
import FishSpecies from './seo-widgets/fish-species'
import WeatherCalender from './seo-widgets/weather-calender'
import useStyles from './useStyles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
/* import Feed from './widgets/feed' */

const App = () => {
  const [guide, setGuide] = useState('712');
  const [xAppID, setXAppID] = useState('788e72ee-dcef-4600-adfa-f7e5a6b0a880')
  const [companyGuid, setCompanyGuid] = useState('cfd97e96-fa24-47f6-828e-30423160e0f4')
  const [isMultiTenantGuide, setIsMultiTenantGuide] = useState(true)
  const [isMarketPlaceGuide, setIsMarketPlaceGuide] = useState(false)
  const classes = useStyles()

  return (
    <Router>
      <div>
        <TextField
          id="standard-basic"
          label="Guide ID"
          className={classes.margin}
          onChange={(e) => setGuide(e.target.value)}
          inputProps={{ type: 'number', value: guide }}
        />
        <TextField
          id="standard-basic"
          label="xAppID"
          className={classes.margin}
          onChange={(e) => setXAppID(e.target.value)}
          inputProps={{ type: 'text', value: xAppID }}
        />
        <FormControl className={classes.margin} style={{width:"100px"}}>
          <InputLabel id="demo-simple-select-label">isMultiTenantGuide</InputLabel>
          <Select
            labelId="isMultiTenantGuide"
            id="isMultiTenantGuidet"
            value={isMultiTenantGuide}
            label="isMultiTenantGuide"
            onChange={event => setIsMultiTenantGuide(event.target.value)}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="standard-basic"
          label="Company-Guid"
          className={classes.margin}
          onChange={(e) => setCompanyGuid(e.target.value)}
          inputProps={{ type: 'text', value: companyGuid }}
        />
        <ul>
          <li>
            <Link to={`/ratecard/${guide}`}>Rate Card </Link>
          </li>
          <li>
            <Link to={`/live-calendar-level-1/${guide}`}>Live Calendar Level 1 </Link>
          </li>
          <li>
            <Link to={`/live-calendar-level-2/${guide}`}>Live Calendar Level 2 </Link>
          </li>
          <li>
            <Link to={`/reviews/${guide}`}>Reviews </Link>
          </li>
          <li>
            <Link to={`/reports/${guide}`}>Reports </Link>
          </li>
          <li>
            <Link to={`/photo-gallery/${guide}`}>Photo Gallery </Link>
          </li>
          <li>
            <Link to={`/fish-species/${guide}`}>Fish Species </Link>
          </li>
          <li>
            <Link to={`/weather-calender/${guide}`}>Weather Calender</Link>
          </li>
        </ul>
        <BookNow to={`/bookings/${guide}`} />
        <hr />
        <Switch>
          <Route exact path="/ratecard/:id">
            <RateCards id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} companyGuid={companyGuid} />
          </Route>
          <Route exact path="/live-calendar-level-1/:id">
            <LiveCalendarLevel1 id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
          </Route>
          <Route exact path="/live-calendar-level-2/:id">
            <LiveCalendarLevel2 id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
          </Route>
          <Route exact path="/reviews/:id">
            <div className={classes.reviewsWidth}>
              <Reviews id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
            </div>
          </Route>
          <Route exact path="/reports/:id">
            <div className={classes.reportsWidth}>
              <Reports id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
            </div>
          </Route>
          <Route exact path="/photo-gallery/:id">
            <PhotoGallery id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} companyGuid={companyGuid}/>
          </Route>
          <Route exact path="/fish-species/:id">
            <div className={classes.reviewsWidth}>
              <FishSpecies id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
            </div>
          </Route>
          <Route exact path="/weather-calender/:id">
            <div className={classes.reviewsWidth}>
              <WeatherCalender id={guide} xAppID={xAppID} isMarketPlaceGuide={isMarketPlaceGuide} isMultiTenantGuide={isMultiTenantGuide} />
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
