import React, { useState } from "react";
import ReactStars from "react-stars";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getOS, getCorrectedImagePath } from "./data";
import Style from "./reviews_widget.module.scss";
import greatGuide from "./reviews/great-guide.svg";
import bestSpots from "./reviews/best-spots.svg"; 
import topAnglers from "./reviews/great-for-top-anglers.svg";
import bestBoat from "./reviews/best-boat.svg";
import novices from "./reviews/great-for-novices.svg";
import goodwithKids from "./reviews/great-with-kids.svg";
// import Image from "next/image";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Icon from "react-icons-kit"
import { star } from 'react-icons-kit/fa/star'
import { ic_fiber_manual_record } from 'react-icons-kit/md/ic_fiber_manual_record'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const BestVehicle = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/best-vehicle-01.png';
const GreatForTopHunters = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/top-hunter_2-01.png';
const BreathtakingExp = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/tours-breathtaking-experiences.png';
const Exploration = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/tours-exploration.png';
const FloraFauna = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/tours-flora-fauna-v2.png';
const GreatForFamily = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/tours-great-for-family.png';
const GreatViews = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/tours-great-views.png';
const TopStoryTeller = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/tours-storyteller-v2.png';
const WildlifeKnowledge = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/tours-wildlife-knowledge.png';
const BestBoat1 = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/best_boat_1.png';
const GreatForNovices1 = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/great_for_novices_1.png';
const TopAnglers1 = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/top_anglers_1.png';
const GreatGuide1 = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/great_guide_1.png';
const GreatWithKids1 = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/great_with_kids_1.png';
const BestSpots1 = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/best_spots_1.png';
const GreatTrail = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/snow-sports-great-trail-knowledge.png';
const AceOnSlopes = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/snow-sports-ace-on-slopes.png';
const NeedForSpeed = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/snow-sports-need-for-speed.png';
const BlackDiamondSavant = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/snow-sports-black-diamonds.png';
const ProClimber = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/snow-sports-pro-climber.png';
const SafetyFirst = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/snow-sports-safety-first.png';
const LifeOfTheParty = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/snow-sports-life-of-party.png';
const GreatStaff = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/cruise-party-boats-great-staff.png';
const QualityService = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/cruise-party-boats-quality-service.png';
const KillerTrails = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/off-roading-killer-trails-badge.png';
const MustHave = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/off-roading-must-have-experience-badges.png';
const SafetyFir = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/off-roading-safety-first-badge.png';
const BeautifulHorses = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/horseback-riding-beautiful-horses-badge.png';
const EnglishExcellence = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/horseback-riding-english-excellence-badges.png';
const QualityGear = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/horseback-riding-quality-gear-badges.png';
const WesternWisdom = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/horseback-riding-western-wisdom-badges.png';
const RareExperience = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/horseback-riding-rare-experience-badges.png';
const GroupGreatness = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/horseback-riding-group-greatness-badges.png';
const LocalWildlife = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/water-sports-local-wildlife-badge.png';
const SafetyFirstWater = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/water-sports-safety-first-badge.png';
const AdrenalineGrabber = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/water-sports-adrenaline-grabber-badge.png';
const Knowledgeable = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/water-sports-knowledgeable-badge.png';
const BestViewsWater = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/water-sports-best-views-badge.png';
const AdventureSeeker = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/water-sports-adventure-seeker-badge.png';

export default function CustomReviews({ reviews, ratingAvg, allReviews }) {
  const percentage = Number(ratingAvg).toFixed(2) * 20;
  const percentValue = Number(ratingAvg).toFixed(2);
  const recomendpercentage = 100;
  const isBrowser = typeof window !== `undefined`;
  const [isMobile, setIsMobile] = useState(
    isBrowser && window.innerWidth <= 1199
  );
  const paginationCount = isMobile ? 10 : 15;
  const isNarrow =
    isBrowser &&
    (/Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) ||
      window.innerWidth <= 768 ||
      ((window.screen || {}).width || 0) <= 768);
  const [pagination, setPagination] = useState({
    limit: paginationCount,
    active: 1,
    numPages: Math.ceil(reviews.length / paginationCount),
  });

  var comment = [];
  allReviews &&
  allReviews.map((item) => {
      if (item.guide_acknowledgements) {
        comment.push(item.guide_acknowledgements);
      }
    });

  let avgReview = [];
  let averageValue;
  allReviews &&
  allReviews.map((item) => {
      if (item.overall) {
        avgReview.push(Number(item.overall));
      }
    });
  const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
  averageValue = average(avgReview).toFixed(1);

  var d = comment
    .reduce((acc, curr) => [...acc, ...curr], [])
    .map((data) => data.title);
  var comments = Array.from(new Set(d.map(JSON.stringify))).map(JSON.parse);

  let five = [],
    four = [],
    three = [],
    two = [],
    one = [];
    allReviews.map((item) => {
    if (+item.overall === 5) {
      five.push(+item.overall);
    } else if (+item.overall < 5 && +item.overall >= 4) {
      four.push(+item.overall);
    } else if (+item.overall < 4 && +item.overall >= 3) {
      three.push(+item.overall);
    } else if (+item.overall < 3 && +item.overall >= 2) {
      two.push(+item.overall);
    } else if (+item.overall < 2 && +item.overall >= 1) {
      one.push(+item.overall);
    }
  });

  let recommend = 0;
  allReviews.map((item) => {
    if (item.recommends_trip) {
      recommend = recommend + 1;
    }
  });

  const calculatePercentage = (total) => {
    return Math.round((100 * total.length) / allReviews.length);
  };

  const indexOfLastImage = pagination.active * pagination.limit;
  const indexOfFirstImage = indexOfLastImage - pagination.limit;
  const getIcon = (item) => {
    switch (item) {
      case "Best Spots":
        return BestSpots1;
      case "Great For Top Anglers":
        return TopAnglers1;
      case "Best Boat":
        return BestBoat1;
      case "Good with Kids":
        return GreatWithKids1;
      case "Great For Novices":
        return GreatForNovices1;
      case "Flora & Fauna":
        return FloraFauna;
      case "Best StoryTeller":
        return TopStoryTeller;
      case "Breathtaking Experience":
        return BreathtakingExp;
      case "Great For The Family":
          return GreatForFamily;
      case "Key Wildlife Knowledge":
        return WildlifeKnowledge;
      case "Top Exploration":
        return Exploration;
      case "Perfect Views":
        return GreatViews;
      case "Great For Top Hunters":
        return GreatForTopHunters;
      case "Best Vehicle":
        return BestVehicle;
      case "Great Guide":
          return GreatGuide1;
          case "Black Diamond Savant":
					return BlackDiamondSavant;
				case "Great Trail Knowledge":
					return GreatTrail;
				case "Ace On Slopes":
					return AceOnSlopes;
				case "Need For Speed":
					return NeedForSpeed;
				case "Pro Climber":
					return ProClimber;
				case "Safety First":
					return SafetyFirst;
				case "Life Of The Party":
					return LifeOfTheParty;
          case "Great Staff":
					return GreatStaff;
				case "Safety First On Road":
					return SafetyFir;
				case "Must Have Experience":
					return MustHave;
				case "Killer Trails":
					return KillerTrails;
          case "English Excellence":
					return EnglishExcellence;
				case "Group Greatness":
					return GroupGreatness;
				case "Rare Experience":
					return RareExperience;
				case "Western Wisdom":
					return WesternWisdom;
				case "Beautiful Horses":
					return BeautifulHorses;
				case "Quality Gear":
					return QualityGear;
          case "Local Wildlife":
					return LocalWildlife;
				case "Best Views":
					return BestViewsWater;
				case "Safety First On Water":
					return SafetyFirstWater;
				case "Adrenaline Grabber":
					return AdrenalineGrabber;
				case "Adventure Seeker":
					return AdventureSeeker;
				case "Knowledgeable":
					return Knowledgeable;

        default:
        null;
    }
  }

  return (
    <>
      <div className={Style.mainReviewContainer}>
        <div className={Style.rowreview}>
          <Typography variant="h2" className={Style.reviewTitle}>
            Ratings & Reviews Summary  {isNarrow ? "" : "-"}
          </Typography>
          {
            reviews && reviews?.length > 0 &&
            <span className={Style.reviewRating} style={{ fontSize: 24 }}><Icon icon={star} size={18} style={{ marginRight: "5px" }} />{Number(ratingAvg).toFixed(2)}<Icon icon={ic_fiber_manual_record} size={8} style={{ padding: 5 }} />{`${allReviews.length} reviews`}</span>
          }
        </div>
        <div className={Style.ratingOverview}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={Style.ratingOverviewMob}
            >
              <div className={Style.progressContainer}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: 5 }} >
                    <div className={Style.firstProgress}>
                      <CircularProgressbarWithChildren
                        value={percentage}
                        text={`${percentValue}`}
                        strokeWidth={6}
                        styles={buildStyles({
                          textColor: "black",
                          pathColor: "#4b7902",
                          trailColor: "#D3D3D3",
                          textSize: "18px",
                        })}
                      >
                        <div className={Style.starDiv}>
                          <ReactStars
                            count={1}
                            size={isNarrow ? (getOS() ? 18 : 21) : 35}
                            edit={false}
                            value={1}
                            color2={"rgb(66, 100, 47)"}
                            char={"★"}
                          />
                        </div>

                        <div className={Style.firstProgressText}>out of 5</div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className={Style.progressRtaing}>
                      <Typography variant="span">
                        {`Based on ${allReviews.length} ratings`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: 5 }} >
                    <div className={Style.firstProgress}>
                      <CircularProgressbarWithChildren
                        value={recomendpercentage}
                        text={`${recomendpercentage}%`}
                        strokeWidth={6}
                        styles={buildStyles({
                          textColor: "black",
                          pathColor: "#4b7902",
                          trailColor: "#D3D3D3",
                          textSize: "18px",
                        })}
                      >
                        {/*isNarrow ? (getOS() ? "": "") : ""*/}
                        <div className={Style.thumbDiv}>
                          <ThumbUpOffAltIcon fontSize={isNarrow ? "medium" : "large"} />
                        </div>

                        <div className={Style.secondProgressText}>recommended</div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className={Style.progressRtaing}>
                      <Typography variant="span">
                        {`Based on ${allReviews.length} recommendations`}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* <div className={Style.progressText}>
                
                
              </div> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={Style.summaryGraph}>
                <div className={Style.progressbarGrp}>
                  <div className={Style.displayFlex}>
                    <span>5</span>
                    <div className={Style.progressbar}>
                      <div
                        style={{ width: calculatePercentage(five) + "%" }}
                      ></div>
                    </div>
                    <span>{five.length > 0 ? five.length : ""}</span>
                  </div>
                  <div className={Style.displayFlex}>
                    <span>4</span>
                    <div className={Style.progressbar}>
                      <div
                        style={{ width: calculatePercentage(four) + "%" }}
                      ></div>
                    </div>
                    <span>{four.length > 0 ? four.length : ""}</span>
                  </div>
                  <div className={Style.displayFlex}>
                    <span>3</span>
                    <div className={Style.progressbar}>
                      <div
                        style={{ width: calculatePercentage(three) + "%" }}
                      ></div>
                    </div>
                    <span>{three.length > 0 ? three.length : ""}</span>
                  </div>
                  <div className={Style.displayFlex}>
                    <span>2</span>
                    <div className={Style.progressbar}>
                      <div
                        style={{ width: calculatePercentage(two) + "%" }}
                      ></div>
                    </div>
                    <span>{two.length > 0 ? two.length : ""}</span>
                  </div>
                  <div className={Style.displayFlex}>
                    <span>1</span>
                    <div className={Style.progressbar}>
                      <div
                        style={{ width: calculatePercentage(one) + "%" }}
                      ></div>
                    </div>
                    <span>{one.length > 0 ? one.length : ""}</span>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={Style.reviewTopBorder}></div>
          <div>
            <div>
              {comments.length >= 1 && (
                <Typography
                  variant="span"
                  className={Style.averageContainer}
                  style={{ display: "block", marginTop: "30px" }}
                >
                  Our Guide is loved for...
                </Typography>
              )}
            </div>
            <div className={Style.scrollingwrapper}>
              {comments.filter(a => (a !== " Best Vehicle" && a !== "Great for Top Hunters" && a !== "Great for Top Anglers" && a !== 'Great for Novices' && a!== 'Great for the Family')).map((item) => {
                return (
                  <div className={Style.imgRating}>
                    <img
                      src={getIcon(item)}
                      width={isNarrow ? 100 : 140}
                      height={isNarrow ? 100 : 140}
                      layout={"fixed"}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
