import React from 'react';
import ReactDOM from 'react-dom';

import Widgets from './Widgets';

ReactDOM.render(
  <Widgets />,
  document.getElementById('app'),
);

module.hot.accept();
