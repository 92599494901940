import React, { useState, useMemo, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import { post } from '../../utils/axiosHelper';
import TripDetailsContext from '../../state/context/TripDetailsContext';
import { getTotalCharge,getAdditionalPersonCharge, displayMoney } from '../../utils/payment';
import { parsePhoneNumber } from 'react-phone-number-input'
import TripSummaryView from './TripSummaryView'
import './index.scss'
import getSymbolFromCurrency from 'currency-symbol-map'
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import convertTo12Hour, { getDuration, getTimeFormat } from '../../utils/time';

const useOptions = () => {
  const fontSize = '16px';
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          // fontFamily: "Source Code Pro, monospace",
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          iconColor: '#ffc7ee',
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );

  return options;
};

const PaymentDetails = ({ previous, next, xAppID, origin }) => {
  const classes = useStyles();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const customTip = useRef(null);
  const [zip,setZip] = useState();
  const [paymentStarted, setPaymentStarted] = useState(false);
  const tripDetailsContext = useContext(TripDetailsContext);
  const { 
    selectedHour,
    selectedRateCard,
    guestCount,
    addOnFees,
    setAddOnFees,
    tipamount
  } = tripDetailsContext;

  const total1 = getTotalCharge(
    tripDetailsContext.selectedRateCard,
    tripDetailsContext.guestCount,
    tripDetailsContext.selectedHour.rate,
  );

  const additionalCharge = getAdditionalPersonCharge(
    selectedRateCard,
    guestCount,
    selectedHour.rate ||
    (((selectedRateCard || {}).rates || [])[0] || {}).rate,
  );

  let fees1 = tripDetailsContext.addOnFees.map(item => isNaN(item.charge_price) ? 0 : item.charge_price);
  fees1 = fees1.reduce((a, b) => a + b, 0)

  let totalTripCost = selectedHour.rate + fees1 + additionalCharge;
  
  const handlePredefined = (data) => {
    tripDetailsContext.setTipamount((totalTripCost*data).toFixed(2))
    tripDetailsContext.setTipType("Predefined")
	}
  const includingTip = total1 + fees1 + tripDetailsContext.tipamount

  useEffect(()=>{
    handlePredefined(0.20)
  },[])

  const processPaymentToken = async (paymentToken, paymentMethod) => {
    const formData = {};

    const {
      data: { token },
      status,
    } = await post(
      'auth/loginguest',
      {
        email: tripDetailsContext.email,
        firstname: tripDetailsContext.firstName,
        lastname: tripDetailsContext.lastName,
        phone: tripDetailsContext.phoneNumber,
      },
      {
        headers: {
          'x-app-id': xAppID,
        },
      },
    );

    const vals = {
      guideguid: tripDetailsContext.selectedRateCard.guideguid,
      date: moment(new Date()).format('YYYY-MM-DD'),
      tripdate: moment(
        new Date(tripDetailsContext.selectedDay),
      ).format('YYYY-MM-DD'),
      starttime: moment(tripDetailsContext.selectedHour.start_time, ["HH:mm:ss"]).format("h:mm a"),
      triptype: tripDetailsContext.selectedRateCard.ratetype,
      tripcost: tripDetailsContext.selectedHour.rate,
      bookingrate: `${tripDetailsContext.selectedHour.rate}`,
      guidegear: true,
      notes: tripDetailsContext.guideNotes,
      currency: tripDetailsContext?.userData?.currency?.toString()?.toLowerCase() || 'usd',
      secondperson: tripDetailsContext.guestCount > 1,
      river1: tripDetailsContext.preferedWaterbody?.riverid,
      river1name: tripDetailsContext.preferedWaterbody?.rivername,
      river2: tripDetailsContext.secondFavoriteWaterbody?.riverid,
      river2name:
        tripDetailsContext.secondFavoriteWaterbody?.rivername,
      customer_firstname: tripDetailsContext.firstName,
      customer_lastname: tripDetailsContext.lastName,
      customer_phone: String(tripDetailsContext.phoneNumber).replace(/\D+/g, "").slice(-10),
			customer_country_phone_code: '+' + parsePhoneNumber(String(tripDetailsContext.phoneNumber)).countryCallingCode,
			customer_country_iso_code: parsePhoneNumber(String(tripDetailsContext.phoneNumber))?.country,
      customer_email: tripDetailsContext.email,
      customer_zip: zip,
      guestcount: tripDetailsContext.guestCount,
      mealguid: tripDetailsContext.meals.guid === 'select' ? null : tripDetailsContext.meals.guid,
      poiguid: (tripDetailsContext.meeetingPoint || {}).poiguid,
      meal: tripDetailsContext.meals.guid === 'select' ? null : tripDetailsContext.meals.name,
      meetingPoint: (tripDetailsContext.meeetingPoint || {}).name,
      fishingType: tripDetailsContext.selectedRateCard.rateType,
      rateCardId: tripDetailsContext.selectedRateCard.id,
      hourCardId: tripDetailsContext.selectedHour.id,
      scheduleId: tripDetailsContext.selectedRateCard.scheduleId,
      origin,
      guide_addon_fees: tripDetailsContext.addOnFees,
      cash_tip_upon_trip: tripDetailsContext.tipamount && tripDetailsContext.tipamount === 'uponCash',
      tip_amount: tripDetailsContext.tipamount && tripDetailsContext.tipamount !== 'uponCash' ? (Number(tripDetailsContext.tipamount)).toFixed(2) : 0,
      tip_type: tripDetailsContext.tipType != "" ? tripDetailsContext.tipType : null
    };

    const tipAmount = tipamount && tipamount !== 'uponCash' ? Number(tipamount) : 0
    const total = getTotalCharge(
      tripDetailsContext.selectedRateCard,
      tripDetailsContext.guestCount,
      tripDetailsContext.selectedHour.rate,
    );

    let fees = tripDetailsContext.addOnFees.map(item => isNaN(item.charge_price) ? 0 : item.charge_price);
    fees = fees.reduce((a, b) => a + b, 0)

    const totalIncludingMatesFees = total + fees + tipAmount

    const chargeToday = ((total + fees) * 0.20).toFixed(2);
    formData.cash_tip_upon_trip = tripDetailsContext.tipamount && tripDetailsContext.tipamount === 'uponCash'
    formData.tip_amount = tripDetailsContext.tipamount && tripDetailsContext.tipamount !== 'uponCash' ? (Number(tripDetailsContext.tipamount)).toFixed(2) : 0;
    formData.paymentToken = paymentToken;
    formData.payment_method = paymentMethod;
    formData.charge_amount = chargeToday;
    formData.total_amount = totalIncludingMatesFees;
    formData.trip = vals;

    if (status === 200) {
      post('payment/charge_token_v2', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((responseData) => {
          if (responseData.data.trip_guid) {
            next();
            tripDetailsContext.setBookingID(responseData.data.booking_ref)
            setLoading(false);
          } else {
            setError({
              ...error,
              paymentError: 'Something went wrong. Please try again',
            });
            setLoading(false);
          }
          setPaymentStarted(false);
        })
        .catch((err) => {
          setError({
            ...error,
            paymentError:
              'Something went wrong. Please try again',
          });
          setLoading(false);
          setPaymentStarted(false);
        });
    } else {
      setError({
        ...error,
        paymentError: 'Something went wrong. Please try again',
      });
      setLoading(false);
      setPaymentStarted(false);
    }
  };

  const validateZipCode =(event) => {setZip(event.target.value)}

  const handlePayment = async () => {
    const cardElement = elements.getElement(CardNumberElement);
    setError({ ...error, paymentError: '' });
    setLoading(true);
    setPaymentStarted(true);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (payload.error !== undefined) {
      /* setErrorTitle(`${payload.error.code}`); */
      setError({ ...error, paymentError: payload.error.message });
      setLoading(false);
      setPaymentStarted(false);
    } else {
      const cardToken = await stripe.createToken(cardElement);
      await processPaymentToken(cardToken.token, 'CARD');
    }
  };

  var activateBtn = paymentStarted || error.cardNumber !== "" || error.cvv !== "" || error.cardExpiry !== "" || zip === ""

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid classes={classes.paymentContainer} container>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body2" className={classes.paymentDetails}>
              Pre-tip
            </Typography>
					</Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper elevation={3} className={classes.padding1}>
              <Typography style={{ marginLeft: 10 }} className={`${classes.margin2}`}>
                Tip your guide
              </Typography>
                <Typography component="div" className={`${classes.tippingContainer}`}>
                  <Grid item xs={4} md={4} lg={4} sm={4} className={`${classes.tipping} ${tripDetailsContext.tipamount === (totalTripCost * 0.18).toFixed(2) && classes.activeTip} `} onClick={() => handlePredefined(0.18)}>
                      <Typography className={`${classes.tipPercentage}`} component="div">
                         <span>18%</span>
                      </Typography>
                      <Typography className={`${classes.tipAmount}`} component="div">
                        {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney((totalTripCost * 0.18).toFixed(2))}`}
                      </Typography>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4} sm={4} className={`${classes.tipping} ${tripDetailsContext.tipamount === (totalTripCost * 0.20).toFixed(2) && classes.activeTip} `} onClick={() => handlePredefined(0.20)}>
                      <Typography className={`${classes.tipPercentage}`} component="div">
                        <span>20%</span>
                      </Typography>
                      <Typography className={`${classes.tipAmount}`} component="div">
                        {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney((totalTripCost * 0.20).toFixed(2))}`}
                      </Typography>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4} sm={4} className={`${classes.tipping} ${tripDetailsContext.tipamount === (totalTripCost * 0.25).toFixed(2) && classes.activeTip} `} onClick={() => handlePredefined(0.25)}>
                      <Typography className={`${classes.tipPercentage}`} component="div">
                        <span>25%</span>
                      </Typography>
                      <Typography className={`${classes.tipAmount}`} component="div">
                        {`${(getSymbolFromCurrency(tripDetailsContext?.userData?.currency) || '$')}${displayMoney((totalTripCost * 0.25).toFixed(2))}`}
                      </Typography>
                  </Grid>
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography  onClick={() => {
												customTip.current.focus() 
                        tripDetailsContext.setTipType("Custom")
                      }}
											variant="body2" className={`${classes.customTip} ${(tripDetailsContext.tipamount !== (totalTripCost * 0.18).toFixed(2) && tripDetailsContext.tipamount !== (totalTripCost * 0.20).toFixed(2) && tripDetailsContext.tipamount !== (totalTripCost * 0.25).toFixed(2) && tripDetailsContext.tipamount !== 'uponCash' && tripDetailsContext.tipamount !== '') && classes.activeTip}`}>
                    <input ref={customTip} className={`${classes.customTipValue} `} onChange={(e) => {tripDetailsContext.setTipamount(e.target.value > 0 ? e.target.value : null) 
                    }} type="number" placeholder={"Custom Tip Amount"} />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} onClick={() => {
												tripDetailsContext.setTipamount('uponCash')
                        tripDetailsContext.setTipType("Cash Tip Upon Trip")
                      }} className={`${classes.cashtip} ${tripDetailsContext.tipamount === 'uponCash' && classes.activeTip}`}>
                  <Typography variant="body2">
                      Cash Tip Upon Trip
                  </Typography>
                </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body2" className={classes.paymentDetails}>
              Payment Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper elevation={3} className={classes.padding1}>
              <Typography style={{ marginLeft: 20 }} className={`${classes.margin2}`}>
                Transaction will be debited instantly after you select 'Confirm Payment'.
              </Typography>
              <Grid container>
                <Grid item xs={12} className={`${classes.margin2} ${classes.border}`}>
                  <Grid container>
                    <Grid item xs={3} md={3} lg={3} sm={3}>
                      <Typography className={classes.label} htmlFor="card-number">Card Number</Typography>
                    </Grid>
                    <Grid item xs={9} md={9} lg={9} sm={9}>
                      <FormControl
                        className={classes.form}
                        error={Boolean(error.cardNumber)}
                      >
                        <CardNumberElement
                          options={options}
                          onChange={(event) => {
                            if (event.error) {
                              setError({
                                ...error,
                                cardNumber: event.error.message,
                              });
                            } else {
                              setError({ ...error, cardNumber: '' });
                            }
                          }}
                          className={classes.stripeElement}
                        />
                        {error.cardNumber ? (
                          <FormHelperText
                            id="card-number"
                          >
                            {error.cardNumber}
                          </FormHelperText>
                        ) : (
                          <FormHelperText />
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={`${classes.margin2} ${classes.border}`}>
                  <Grid container>
                    <Grid item xs={3} md={3} lg={3} sm={3}>
                      <Typography className={classes.label} htmlFor="card-number">Expiration date</Typography>
                    </Grid>
                    <Grid item xs={9} md={9} lg={9} sm={9}>
                      <FormControl
                        className={classes.form}
                        error={Boolean(error.cardExpiry)}
                      >
                        <CardExpiryElement
                          options={options}
                          onChange={(event) => {
                            if (event.error) {
                              setError({
                                ...error,
                                cardExpiry: event.error.message,
                              });
                            } else {
                              setError({ ...error, cardExpiry: '' });
                            }
                          }}
                          className={classes.stripeElement}
                        />
                        {error.cardExpiry ? (
                          <FormHelperText
                            id="expiration-date"
                          >
                            {error.cardExpiry}
                          </FormHelperText>
                        ) : (
                          <FormHelperText />
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={`${classes.margin2} ${classes.border}`}>
                  <Grid container>
                    <Grid item xs={3} md={3} lg={3} sm={3}>
                      <Typography className={classes.label} htmlFor="cvv">CVV</Typography>
                    </Grid>
                    <Grid item xs={9} md={9} lg={9} sm={9}>
                      <FormControl className={classes.form} error={Boolean(error.cvv)}>
                        <CardCvcElement
                          options={options}
                          onChange={(event) => {
                            if (event.error) {
                              setError({ ...error, cvv: event.error.message });
                            } else {
                              setError({ ...error, cvv: '' });
                            }
                          }}
                          className={classes.stripeElement}
                        />
                        {error.cvv ? (
                          <FormHelperText id="cvv">
                            {error.cvv}
                          </FormHelperText>
                        ) : (
                          <FormHelperText />
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={`${classes.margin2} ${classes.border}`}>
                    <Grid container >
                    <Grid item xs={3} md={3} lg={3} sm={3}>
                        <Typography className={classes.label} htmlFor="zip-code">Billing ZipCode</Typography>
                      </Grid>
                     <Grid item xs={9} md={9} lg={9} sm={9}>
                    <FormControl className={classes.form} error={Boolean(error.zip)}>
                      <input
                        variant="outlined"
                        required
                        id="zip-code"
                        aria-describedby="zip-code"
                        value={zip}
                        onChange={validateZipCode}
                        style = {{ width: "35%", lineHeight: "38px", borderRadius: '5px'}}
                      />
                      {error.zip ? (
                            <FormHelperText id="zip-code">
                              {err.zip}
                            </FormHelperText>
                          ) : (
                            <FormHelperText />
			                         )}
                    </FormControl>
                    </Grid>
                    </Grid>
                  </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={`${classes.margin2}`}>
                  <FormControl
                    className={classes.form}
                    error={Boolean(error.paymentError)}
                  >
                    {error.paymentError ? (
                      <FormHelperText id="payment-error">
                        {error.paymentError}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Typography component="div" style={{ marginTop: 0, width: '20%' }} className={classes.paymentCont}>
                  <p className={classes.creditcards}>We accept the following payment methods.</p>
                  <img src='https://guidesly-staging.web.app/static/credit-cards-96e21d45d18b27a3e498a65644a4f2a5.png' style={{ width: "100%" }} />
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container className={'personalCont'}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div" >
              <div>
                <div style={{ display: "flex" }}>
                  <img src={'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/user-details.png'} className={'Icon1'} />
                  <p className={'PerTitle'}>Personal Details</p>
                </div>
                <div className={'PersonalDetail'}>
                  <div className={'textCont'}>
                    <p className={'content'}>First Name:</p>
                    <p className={'contentValue'}>{tripDetailsContext.firstName}</p>
                  </div>
                  <div className={'textCont'}>
                    <p className={'content'}>Last Name:</p>
                    <p className={'contentValue'}>{tripDetailsContext.lastName}</p>
                  </div>
                  <div className={'textCont'}>
                    <p className={'content'}>Email:</p>
                    <p className={'contentValue'}>{tripDetailsContext.email}</p>
                  </div>
                  <div className={'textCont'}>
                    <p className={'content'}>Phone:</p>
                    <p className={'contentValue'}>{tripDetailsContext.phoneNumber}</p>
                  </div>
                </div>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div" >

              <div>
                <div style={{ display: "flex" }}>
                  <img src={'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/fisher.png'} className={'Icon1'} />
                  <p className={'PerTitle'}>Guest Preferences</p>
                </div>
                <div className={'PersonalDetail'} style={{ border: "none" }}>
                  {tripDetailsContext.preferedWaterbody?.rivername && <div className={'textCont'}>
                    <p className={'content'}>Preferred Waterbody:</p>
                    <p className={'contentValue'}>
                      {tripDetailsContext.preferedWaterbody?.rivername}
                    </p>
                  </div>}
                  {tripDetailsContext.secondFavoriteWaterbody?.rivername && <div className={'textCont'}>
                    <p className={'content'}>2nd Favorite River/Waterbody:</p>
                    <p className={'contentValue'}>
                      {tripDetailsContext.secondFavoriteWaterbody?.rivername}
                    </p>
                  </div>}
                  {tripDetailsContext.meals && tripDetailsContext.meals.name && tripDetailsContext.meals.name != "Select" && (
                    <div className={'textCont'}>
                      <p className={'content'}>Meal Preferences:</p>
                      <p className={'contentValue'}>
                        {tripDetailsContext.meals.name}
                      </p>
                    </div>
                  )}
                  <div className={'textCont'}>
                    <p className={'content'}>Meeting Point:</p>
                    <p className={'contentValue'}>{tripDetailsContext.meeetingPoint ? tripDetailsContext.meeetingPoint.name : "NA"}</p>
                  </div>
                  <div className={'textCont'}>
                    <p className={'content'}>Trip Time and Length:</p>
                    <p className={'contentValue'}>
                      {getDuration(tripDetailsContext.selectedHour)} at {convertTo12Hour(tripDetailsContext.selectedHour.start_time)}
                    </p>
                  </div>
                  {tripDetailsContext.guideNotes && tripDetailsContext.guideNotes != "" && (
                    <div className={'textCont'}>
                      <p className={'content'}>Notes / Requests:</p>
                      <p className={'contentValue'}>
                        {tripDetailsContext.guideNotes}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography component="div" className={'borderContainer'} style={{ borderColor: "#eaeaea" }}>
              <p className={'bodyHeader1'}>{props.title}</p>
              <div className={'bodyCont'}>
                {tripDetailsContext.guidepic && (<img className={'profilePic'} src={`${GuideslyVariables.GUIDESLY_GUIDEPIC_IP}/${getCorrectedImagePath(tripDetailsContext.guidepic)}`} alt={tripDetailsContext.guidename} style={{ margin: "0px", borderRadius: 25 }} />)}
                <div style={{ padding: "5px 0px" }}>
                  <h1 className={'boldFont'} >{tripDetailsContext.guidename}</h1>
                  {tripDetailsContext && tripDetailsContext.super_guide && <><p className={superguideBadge}>Superguide </p></>}
                  {tripDetailsContext.company &&
                    <div className={'bodyCont'}>
                      <img className={'companypic'} src={company} />
                      <h2 className={'organizationFont'}>{tripDetailsContext.company}</h2>
                    </div>
                  }
                  {
                    tripDetailsContext && tripDetailsContext.city !== null && tripDetailsContext.state !== null ?
                      <div className={'bodyCont'} >
                        <img style={{ width: "16px", height: "16px", margin: "0px 4px " }} src={Locimg} alt="address" />
                        <p className={'organizationFont'} style={{ marginBottom: "0px" }}>
                          {tripDetailsContext.city === null && tripDetailsContext.state === null ? "" : tripDetailsContext.city !== null && tripDetailsContext.state === null ? `${tripDetailsContext.city}` : tripDetailsContext.city === null && tripDetailsContext.state !== null ? `${tripDetailsContext.state}` : `${tripDetailsContext.city.trim()}, ${tripDetailsContext.state}`}
                        </p>
                      </div> : <div></div>
                  }
                  <div className={'descriptionSectiion'} style={{ padding: "0px", borderBottom: "none" }}>
                    {tripDetailsContext && tripDetailsContext.short_desc && (
                      <>
                        <p className={'descContents'} dangerouslySetInnerHTML={{ __html: tripDetailsContext.short_desc }}></p>

                      </>
                    )}
                  </div>
                </div>
              </div>
            </Typography>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <TripSummaryView activateBtn={activateBtn} handlePayment={handlePayment} loading={loading} previous={previous} />
      </Grid>
    </Grid>
  );
};

PaymentDetails.propTypes = {
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  xAppID: PropTypes.string.isRequired,
};

export default PaymentDetails;
