import moment from 'moment';
import { getBookingAcceptanceDay } from '../../../utils';

export const getAvailability = (data) => {
  const scheduleDisable = [];
  const availability = [];
  data?.forEach((item) => {
    const z = item.date.replace(/-/g, ', ');
    const tmpDate = moment(z, 'YYYY,MM,DD').toDate();
    const tomorrow = moment().add(getBookingAcceptanceDay(), "days").format('YYYY/MM/DD')
    /* if (item.reasoncode === 'ALL') {
      availability.push(tmpDate);
    } */
    if (moment(tmpDate).isSameOrAfter(tomorrow) && item.available_rates.length > 0) {
      availability.push(tmpDate);
    } else {
      scheduleDisable.push(tmpDate)
    }
  });
  return { availability, scheduleDisable };
};

export const getMaxCapacity = (data) => {
  let maxOccurrence = 1;
      for (const item of data?.rates) {
          if (item.max_occurrence > maxOccurrence) {
              maxOccurrence = item.max_occurrence;
          }
      }
  return data?.is_shared_trip ? maxOccurrence : data?.maximum
}

export const handleDateSelect = (data, schedule, hourcard) => {
  const availability = []
  const tomorrow = moment().add(getBookingAcceptanceDay(), "days").format('YYYY/MM/DD')
  for (let item of schedule) {
    let z = item.date.replace(/-/g, ", ")
    let tmpDate = moment(z, "YYYY,MM,DD").toDate()
    var itemDate = moment(z, 'YYYY,MM,DD').format('YYYY/MM/DD')
    let found
    if (hourcard) {
      found = item.available_rates.some(rec => rec.id === Number(hourcard.id) && moment(itemDate).isSameOrAfter(tomorrow))
    } else {
      found = item.available_rates.some(rec => rec.guide_ratecard_id === Number(data.id) && moment(itemDate).isSameOrAfter(tomorrow))
    }
    if (found) {
      availability.push(tmpDate)
    }
  }
  return { availability }
}

export const getAvailabilityBasedOnSelectedRateCard = ({ availableSchedules, hourCard, rateCard }) => {
  const scheduleDisable = [];
  const availability = [];
  const tomorrow = moment().add(getBookingAcceptanceDay(), "days").format('YYYY/MM/DD')
  availableSchedules.forEach((item) => {
    const z = item.date.replace(/-/g, ', ');
    const tmpDate = moment(z, 'YYYY,MM,DD').toDate();
    var itemDate = moment(z, 'YYYY,MM,DD').format('YYYY/MM/DD')
    /* if (item.reasoncode === 'ALL') {
      availability.push(tmpDate);
    } */
    if (item.available_rates.length > 0) {
      const found = item.available_rates.filter(rate => {
        if (!rateCard) {
          if (hourCard.guide_ratecard_id === rate?.guide_ratecard_id && rate.id === hourCard.id && moment(itemDate).isSameOrAfter(tomorrow)) {
            return true
          }
        }else if(hourCard&&Object.keys(hourCard).length>0 && rateCard){
          if (hourCard.guide_ratecard_id === rate?.guide_ratecard_id && rate.id === hourCard.id && moment(itemDate).isSameOrAfter(tomorrow)) {
            return true
          }
        } else {
          if (Number(rateCard.id) === rate?.guide_ratecard_id && moment(itemDate).isSameOrAfter(tomorrow)) {
            return true
          }
        }
        return false
      })
      if (found.length > 0) {
        availability.push(tmpDate);
      } else {
        scheduleDisable.push(tmpDate)
      }
    }else{
      scheduleDisable.push(tmpDate)
    }
  });
  return { availability, scheduleDisable };
}

export const getRateCards = (allRateCards, schedule, type, hour) => {
  const availableRateCards = allRateCards.filter((rateCard) => {
    if (rateCard.is_seasonal) {
      return moment(schedule.date).isBetween(rateCard.startdate, rateCard.enddate);
    }
    return rateCard;
  })
  const rateCardIds = (schedule.available_rates || []).map(
    ({ guide_ratecard_id }) => guide_ratecard_id,
  );
  const uniqueRateCardIds = rateCardIds.filter(
    (v, i, a) => a.indexOf(v) === i,
  );
  const scheduleAvailableRateCards = uniqueRateCardIds.map((id) => {
    let rates = (schedule.available_rates || []).filter(
      (item) => {
        let rate = parseInt(item.guide_ratecard_id, 10) === parseInt(id, 10);
        if (hour) {
          rate = parseInt(item.guide_ratecard_id, 10) === parseInt(id, 10)
            && item.duration_hours === parseInt(hour, 10);
        }
        if (hour === 'custom') {
          rate = parseInt(item.guide_ratecard_id, 10) === parseInt(id, 10)
            && item.is_custom_rate;
        }
        return rate;
      },
    );
    rates = rates.sort((a, b) => a.rate - b.rate);
    return {
      id,
      rates,
    };
  });
  let rateCards = scheduleAvailableRateCards.map(
    ({ id, rates }) => {
      const rateCard = availableRateCards.find(
        (availableRateCard) => parseInt(availableRateCard.id, 10) === parseInt(id, 10),
      );
      if (rateCard && rateCard.rates) {
        delete rateCard.rates;
      }
      return { ...rateCard, rates, scheduleId: schedule.scheduleid };
    },
  );
  if (type) {
    rateCards = rateCards.filter(({ ratetype }) => ratetype.toLowerCase() === type.toLowerCase());
  }
  rateCards = rateCards.filter(({ rates, id }) => rates.length > 0 && id);
   
  return rateCards;
};

const getMaximumSeats = (data) => {
  for (const rateCard of data) {
    if(rateCard?.is_shared_trip) {
      let maxOccurrence = 0;
      for (const rate of rateCard.rates) {
        if (rate.max_occurrence > maxOccurrence) {
          maxOccurrence = rate.max_occurrence;
        }
      }
      rateCard.maximum = maxOccurrence;
    }
  }
  return data
}

export const getInitialRateCards = (allRateCards, schedule = {}, live) => {
  const rateCards = live ? getRateCards(allRateCards, schedule) : getAllRateCardsWithDisabled(allRateCards, schedule)
  if (rateCards.length > 0) {
    return getMaximumSeats(rateCards)
  } else {
    return getMaximumSeats(allRateCards.map(rateCard => ({ ...rateCard, disabled: true })))
  }
};

export const getExpCapacity = (availableSchedules, selectedHour, selectedDay) => {
  const daysTrip = availableSchedules && availableSchedules.find(itm => (moment(itm.date).format("YYYY-MM-DD") === moment(selectedDay).format("YYYY-MM-DD")))
  return daysTrip?.available_rates && daysTrip?.available_rates.find(item => item.id === selectedHour?.id)?.maximum
};

const getMaximumAvailableSeats = (data, list) => {
  let seats = list?.find(item => item.id === data.id)
  return seats?.maximum
}

export const getAllRateCardsWithDisabled = (allRateCards, schedule = {}, type, hour) => {
  const rateCardIds = (schedule?.available_rates || [])?.map(
    ({ id }) => id,
  );
  const uniqueRateCardIds = rateCardIds.filter(
    (v, i, a) => a.indexOf(v) === i,
  );
  const uniqueRateCards = schedule?.available_rates && schedule?.available_rates.filter(
    (v, i, a) => a.indexOf(v) === i,
  );
  let rateCards = allRateCards.map((rateCard) => {
    rateCard?.rates.map((rate) => {
      rate.disabled = !(uniqueRateCardIds.includes(rate.id));
      rate.maximum = getMaximumAvailableSeats(rate, uniqueRateCards);
      return rate;
    });
    rateCard.rates = rateCard.rates.sort((a, b) => a.rate - b.rate);
    const disabledRates = rateCard.rates.filter(a => a.disabled);
    const notDisabledRates = rateCard.rates.filter(a => !a.disabled);
    rateCard.rates = [...notDisabledRates, ...disabledRates];
    rateCard.scheduleId = schedule.scheduleid;
    return rateCard;
  });
  if (type) {
    rateCards = rateCards.filter(({ ratetype }) => ratetype.toLowerCase() === type.toLowerCase());
  }
  rateCards = rateCards.map((rateCard) => {
    rateCard.disabled = rateCard.rates.every(rate => rate.disabled);
    // if (rateCard.is_seasonal) {
    //   rateCard.disabled = !(moment(schedule.date).isBetween(moment(rateCard.startdate).format("YYYY-MM-DD"), moment(rateCard.enddate).format("YYYY-MM-DD"), null, '[]')) || rateCard.disabled;
    // }
    return rateCard;
  })
  rateCards = rateCards.filter(({ rates, id }) => rates.length > 0 && id);
  return rateCards;
};

export const TripType = () => {
  return [
      {
          key: "Shore",
          value: "Shore",
          label: "Shore Fishing",
          description:
              "Surf casting, wade fishing, or any fishing on land without a boat"
      },
      {
          key: "Inshore",
          value: "Inshore",
          label: "Inshore Fishing",
          description:
              "Fishing in inshore or nearshore waters, within a few miles from land"
      },
      {
          key: "Offshore",
          value: "Offshore",
          label: "Offshore Fishing",
          description:
              "Fishing the open ocean out of sight of land.  From around ten to hundreds of miles offshore."
      },
      {
          key: "Wrecks",
          value: "Wrecks",
          label: "Wrecks/Reefs Fishing",
          description:
              "Fishing the structure provided by wrecks for bottom fish or pelagics"
      },
      {
          key: "Flats",
          value: "Flats",
          label: "Flats/Shallows Fishing",
          description:
              "Fishing the shallow flats in as little as a foot of water, usually in a skiff."
      },
      {
          key: "BackCountry",
          value: "BackCountry",
          label: "Backcountry Fishing",
          description: "Fishing inshore islands, saltwater creeks, and flats"
      },
      {
          key: "Lake",
          value: "Lake",
          label: "Lakes/Reservoirs/Ponds Fishing",
          description: "Fishing on a lake"
      },
      {
          key: "River",
          value: "River",
          label: "River Fishing",
          description: "Fishing on a river"
      },
      {
          key: "bird hunting",
          value: "Bird hunting",
          label: "Bird hunting"
      },
      {
          key: "big game hunting",
          value: "Big game hunting",
          label: "Big game hunting"
      },
      {
          key: "small game hunting",
          value: "Small game hunting",
          label: "Small game hunting"
      },
      {
          key: "wild boar hunting",
          value: "Wild boar hunting",
          label: "Wild boar hunting"
      },
      {
          key: "brush hunting trip",
          value: "Brush Hunting Trip",
          label: "Brush Hunting Trip"
      },
      {
          key: "hunting from blinds",
          value: "Hunting from Blinds",
          label: "Hunting from Blinds"
      },
      {
          key: "airboat hunts",
          value: "Airboat Hunts",
          label: "Airboat Hunts"
      },
      {
          key: "Camouflage_hunting",
          value: "Camouflage hunting",
          label: "Camouflage hunting"
      },
      {
          key: "Dog_handlers",
          value: "Dog handlers",
          label: "Dog handlers"
      },
      {
          key: "Bait_hunting",
          value: "Bait hunting",
          label: "Bait hunting"
      },
      {
          key: "active hunting",
          value: "Active hunting",
          label: "Active hunting"
      },
      {
          key: "safari",
          value: "Safari",
          label: "Safari"
      }
  ];
};

export default getAvailability;
