import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paymentContainer: {
    backgroundColor: '#f5f5f5',
    border: '1px solid #c7cbce',
    marginBottom: 5,
    color: 'black !important'
  },
  paymentDetails: {
    backgroundColor: '#6c757e',
    color: '#fff',
    fontSize: 16,
    padding: '10px 20px'
  },
  next: {
    color: 'white',
    backgroundColor: '#1a04ec!important',
    border: '4px solid #1a04ec!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: 'white',
      background: '#20b4e4',
      backgroundColor: '#1a04ec!important',
      border: '4px solid #1a04ec!important',
    },
    marginLeft: 10,
    marginTop: 10,
    float: 'right',
    minWidth: 170,
  },
  previous: {
    float: 'right',
    color: '#20b4e4',
    border: '1px solid #20b4e4!important',
    boxShadow: '0 6px 10px -6px #000',
    backgroundColor: '#fff !important',
    padding: 9,
    width: 100,
    '&:hover': {
      color: '#20b4e4',
      border: '1px solid #20b4e4!important',
      boxShadow: '0 6px 10px -6px #000',
    },
    marginTop: 10,
  },
  separator: {
    padding: '10px 20px',
  },
  form: {
    width: '100%',
  },
  title: {
    color: '#42642f',
    paddingTop: '10px',
  },
  margin: {
    margin: '10px 0px',
  },
  paddingLeft: {
    paddingLeft: '10px',
  },
  margin1: {
    marginTop: '20px',
  },
  margin2: {
    marginTop: '20px',
  },
  tippingContainer : {
    display: 'flex'
  },
  tipPercentage : {
    textAlign: 'center',
    color: "#1a04ec",
    fontWeight: 'bold'
  },
  tipAmount : {
    textAlign: 'center',
    color: "#42642f",
    fontWeight: 'bold'
  },
  tipping : {
    border: '1px solid #c7cbce',
    margin: '10px',
    padding: '15px 10px',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  cashtip : {
    padding: '15px',
    border: '1px solid #c7cbce',
    borderRadius: '5px',
    cursor: 'pointer',
    width: 'inherit',
    textAlign: 'center',
    color: '#1a04ec',
    margin : '10px',
    "& p": {
      fontWeight: 'bold'
    }
  },
  customTip : {
    width: 'inherit',
    border: '1px solid #c7cbce',
    borderRadius: '5px',
    textAlign: 'center',
    padding: '5px 15px',
    margin: '10px',
    fontWeight: 'bold',
  },
  customTipValue : {
    border: 'none',
    outline: 'none',
    textAlign: 'center',
    height: '40px',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
         margin: '0'
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
         margin: '0'
    },
    '&::placeholder' : {
      color: '#1a04ec',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    '-moz-appearance': 'textfield',
  },
  activeTip : {
    'background': '#a9a9a9',
    "& input": {
      background: '#a9a9a9'
    }
  },
  buttonContainer: {
    // marginTop: '10px',
  },
  padding1: {
    padding: '5px',
    position: 'relative'
  },
  stripeElement: {
    'border': '1px solid',
    'padding': '10px',
    'max-width': 300,
    borderRadius: 5
  },
  label: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: 10,
  },
  container: {
  },
  paymentCont: {
    boxShadow: '0 0 3px 1px #c7cbce',
    padding: 10,
    margin: 10,
    borderRadius: 5,
    position: 'absolute',
    right: 20,
    bottom: 34,
    '@media screen and (max-width: 568px)': {
      position: 'relative',
      right: 0,
      bottom: 0
    },
  },
  creditcards: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#6b747e'
  },
  disabled: {
    opacity: 0.65,
    color: 'white !important'
  },
}));
