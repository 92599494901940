import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    root: {
        color: 'blue',
        '& h5': {
            color: '#000',
            marginBottom: '20px'
        },
        '& p': {
            color: '#333',
        },
        '& span': {
            color: '#000',
            fontSize: '16px',
            '@media screen and (max-width: 700px)': {
                fontSize: '14px'
            },
        },
        '& li': {
            color: '#000'
        },
        'ul': {
            padding: '0px'
        }
    },
    fishMonth :{
        padding: '10px 0px 10px',
        fontSize: '16px',
        fontWeight: 500,
    },
    fishArea :{
        padding: '10px 0px 10px',
        fontSize: '16px',
        fontWeight: 'normal',
    },
    fishTitle :{ 
        textAlign: 'end',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontSize: '14px',
        color: '#333',
        padding: '0px 15px',
    },
    desc: {
        marginTop: '20px'
    },
    fishName : {
        fontSize: '14px',
        '@media screen and (max-width: 700px)': {
            fontSize: '11px'
        },
    },
    dpStyles : {
        height: '30px',
		padding: '12px',
		fontSize: '13px',
		textAlign: 'end',
		cursor: 'pointer',
		paddingRight: '120px'
    },
    displayFishImage : {
        width: '100px',
        height: '60px',
        marginRight:'10px'
    },
    chartTitle: {
        paddingBottom: '20px',
        paddingTop: '20px'
    },
    chartTitleContent: {
        fontSize: '14px',
		fontWeight: 500,
		color: '#333333',
        display: 'inline-block',
        position: 'relative',
        top: '-30px',
        '@media screen and (max-width: 700px)': {
            top: '0px',
        },
    },
    ChartTitle: {
        paddingBottom: '10px',
        color: '#333',
        fontWeight: 'bold'
    },
    nodata :{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '10px',
        padding: '15px',
        fontSize: '12px'
    },
    status :{
        paddingRight: '10px',
        fontSize: '12px'
    }
}));
