import React from 'react';
import PropTypes from 'prop-types';
import {
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

const primary = createTheme({
  palette: {
    text: {
      primary: '#000',
      subtext: '#6b747e',
      secondary: 'white',
    },
    button: {
      primary: '#1a04ec',
      secondary: 'white'
    },
    border: {
      primary: '#42642f',
      secondary: '#ced6ca'
    },
    background: {
      primary: '',
      secondary: '#bbb7b7'
    },
    hyperlink: {
      primary: '#20b4e4'
    }
  },
  font: {
    size: '',
    weight: '',
    family: '',
  },
  typography: {
    fontFamily: 'HelveticaNeue',
  },
});

export default function GuideslyThemeProvider({ children, theme }) {
  const guideTheme = {
    ...primary,
  
    palette: {
      ...primary.palette,
      text: {
        ...primary.palette.text,
        ...theme.palette.text
      },
      button: {
        ...primary.palette.button,
        ...theme.palette.button
      },
      border: {
        ...primary.palette.border,
        ...theme.palette.border
      }
    },
    font: {
      ...primary.font,
      ...theme.font
    }
  }
  return <ThemeProvider theme={guideTheme}>{children}</ThemeProvider>;
}

GuideslyThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
};

GuideslyThemeProvider.defaultProps = {
  theme: { palette: {} },
};
