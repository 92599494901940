import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  rateCardContainer: {
    border: '1px solid #c7cbce',
    backgroundColor: 'white'
  },
  container: {
    width: 'auto',
  },
  separator: {
    padding: '10px 20px',
  },
  media: {
    height: '100%',
    borderRadius: 5
  },
  title: {
    color: '#42642f',
    paddingTop: '10px',
    fontWeight: '700',
    textAlign: 'center',
  },
  bold: {
    fontWeight: '700',
    color: '#42642f',
  },
  margin: {
    margin: '10px 0px',
  },
  paddingLeft: {
    paddingLeft: '10px',
  },
  icon: {
    textAlign: 'center',
  },
  confirmationIcon: {
    color: '#42642f',
    width: '50px',
    '@media screen and (min-width: 1024px)': {
      width: '140px',
      height: '100px',
    },
  },
  input: {
    '> input': {
      height: 40
    }
  },
  'tagItem': {
    'background-color': '#c7d9be',
    display: 'inline-block',
    fontSize: 14,
    borderRadius: 30,
    height: 40,
    padding: '0 4px 0 1rem',
    display: 'inline-flex',
    'align-items': 'center',
    margin: '0 0.3rem 0.3rem 0',
    color: '#42642f',
    overflow: 'hidden',
    'overflow-wrap': 'anywhere',
  },
  deletebtn: {
    'background-color': 'white',
    width: 22,
    height: 22,
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',
    font: 'inherit',
    marginLeft: 10,
    fontWeight: 'bold',
    padding: 0,
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  details : {
    paddingTop: '20px'
  },
  cardName : {
    display: 'inline-block',
    position: 'relative',
    top: '-5px',
    textTransform: 'capitalize'
  }
}));
