export const daypickerStyles = `
    .DayPicker{
        font-size: 13px;
        outline: none;
        padding-bottom: 10px;
        width:100% !important;
    }
    .DayPicker-Month  {
        outline: none;
        border-collapse: inherit !important;
        border-spacing: 0px 0px !important;
    }
    .DayPicker-wrapper{
        padding-bottom:0px !important;
        outline: none;
    }
    .DayPicker-Day  {
        width: 40px !important;
        padding: 0.3em;
        outline:none !important;
        vertical-align:baseline !important;
    }
    .DayPicker-Day--selected  {
        background-color: orange !important;
        border-radius:50% !important;
    }
    .DayPicker-Day--unavailablity  {
        color: lightgrey;
    }
    @media only screen and (min-width: 500px) and (max-width: 700px) {
        .DayPicker-NavButton {
            position: absolute;
            top: 1em;
            right: 0em  !important;
            left: auto;
            display: inline-block;
            margin-top: 2px;
            width: 1.25em;
            height: 1.25em;
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
            color: #8B9898;
            cursor: pointer;
        }
        .DayPicker-NavButton--interactionDisabled {
            display: none;
        }
    }
`;

export default daypickerStyles;
