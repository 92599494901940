export const getTechnique = (type) => {
    console.log('type', type)
    const tech = {
        spey: 'A style of fly fishing used to create longer casts without needing as much space behind the angler as a typical fly cast.',
        fly: 'Fly fishing today is used to target almost every fish species and can be done in fresh or salt water.',
        heavy: 'Big reels, strongest of strong lines, and heavy-duty rods. Which means big hooks and rigs as well hopefully resulting in BIG fish.',
        light: 'Allows you to present lighter, smaller bait imitations, avoid detection where fish are wary like low, clear water conditions.',
        bow: 'Fish are shot with a barbed arrow that is attached with special line through a hole in the arrow to a reel mounted on the bow.',
        kite: 'Using up to three live baits spread over a great distance, at varying depths just below the surface for predator game fish.',
        spear: 'Fish from shore, wading, boat, snorkeling, diving, & tools can vary from traditional hand-held spears to elastic propelled spears.',
        drift: 'Fishing from a drift boat allows you to cover more water, access difficult to reach spots and extend your drift per cast.',
        river: 'Fishing a river can be done from the shore, wading or a variety of boats depending on the size of the river.',
        backcountry: 'Backcountry fishing in salt water is often great due to wide array of food sources, extensive cover & varying depths.',
        ice: 'A technique using lines and fish hooks with bait or lures through a hole drilled or cut in the ice on a frozen body of water.',
        flat: 'Saltwater flats are often highly productive as smaller bait fish travel throughout an area or predatory fish cruise for crab, shrimp, sand eels and more. ',
        lake: 'These waterbodies can be found from bustling urban areas to remote locations where a visitor is a rare sight and everywhere in between.',
        pond: 'These waterbodies can be found from bustling urban areas to remote locations where a visitor is a rare sight and everywhere in between.',
        reservior: 'These waterbodies can be found from bustling urban areas to remote locations where a visitor is a rare sight and everywhere in between.'
    }
    const keys = Object.keys(tech)
    let technique = ''
    keys.forEach(item => {
        if (type.toLowerCase().includes(item)) {
            console.log('inside type', tech[item])
            technique = tech[item]
        }
    })
    return technique
}

export const TYPES = {
    shore: 'Surf casting, wade fishing, or any fishing on land without a boat',
    inshore: 'Fishing in inshore or nearshore waters, within a few miles from land',
    offshore: 'Fishing the open ocean out of sight of land.  From around ten to hundreds of miles offshore.',
    wrecks: 'Fishing the structure provided by wrecks for bottom fish or pelagics',
    flats: 'Fishing the shallow flats in as little as a foot of water, usually in a skiff.',
    backcountry: 'Fishing inshore islands, saltwater creeks, and flats',
    lake: 'Fishing on a lake',
    river: 'Fishing on a river',
    deep: 'Fishing deep waters over 100 feet for bottom fish or pelagics',
    bait: 'Fishing using a bait caster, a specialty reel made popular by bass fishermen',
    trolling: 'Fishing by dragging lures or bait behind the boat',
    jigging: 'Fishing by bouncing your lure up and down to entice a bite',
    surf: 'Fishing the ocean from the shore or near the shore'
}

export const AnimalData = () => {
	return [
		{ "id": 1, "label": "Bear", "value": "Bear", "img": null, "checked": false },
		{ "id": 2, "label": "Blacktail Deer", "value": "Blacktail Deer", "img": null, "checked": false },
		{ "id": 3, "label": "Boar", "value": "Boar", "img": null, "checked": false },
		{ "id": 4, "label": "Cougar", "value": "Cougar", "img": null, "checked": false },
		{ "id": 5, "label": "Duck", "value": "Duck", "img": null, "checked": false },
		{ "id": 6, "label": "Elk", "value": "Elk", "img": null, "checked": false },
		{ "id": 7, "label": "Goose", "value": "Goose", "img": null, "checked": false },
		{ "id": 8, "label": "Moose", "value": "Moose", "img": null, "checked": false },
		{ "id": 9, "label": "Mule Deer", "value": "Mule Deer", "img": null, "checked": false },
		{ "id": 10, "label": "Red Deer", "value": "Red Deer", "img": null, "checked": false },
		{ "id": 11, "label": "Whitetail Deer", "value": "Whitetail Deer", "img": null, "checked": false }
	]
}