import React, { useEffect, useContext, useState } from 'react';
import ThemeProvider from '../theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { get } from '../../widgets/utils/axiosHelper'
import TripDetailsProvider from '../state/providers/TripDetailsProvider';
import LargeRateCards from './components/large';
import NarrowRateCards from './components/narrow';

export default (props) => {
  const isNarrow = /Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 834 || ((screen || {}).width || 0) <= 834
  const [userData,setUserData] = useState({})
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (document.querySelector('.wsb-htmlsnippet-element')) {
        document.querySelector('.wsb-htmlsnippet-element').style.overflow = 'scroll'
      }
    }
  }, [])
  useEffect(() => {
    const url = `guide/details?id=${props?.id}`;
    get(url)
        .then((res = {}) => {
            const { status, data = {} } = res
            if (status === 200 && data) {
              setUserData(data)
            }
        }).catch((e) => {
            console.log('API Error: Feed', e)
        });
}, []);

  // console.log('isMultiTenantGuide', props.isMultiTenantGuide)
  // console.log('isMarketPlaceGuide', props.isMarketPlaceGuide)
  const allProps = { ...props, userData };
  return (
    <ThemeProvider {...props}>
      <TripDetailsProvider>
        {isNarrow ? <NarrowRateCards {...allProps} /> : <LargeRateCards {...allProps} />}
      </TripDetailsProvider>
    </ThemeProvider>
  );
}
