import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  block: {
    display: 'block !important'
  },
  block1: {
    height: '86vh',
    overflow: 'scroll',
    display: 'block !important',
    backgroundColor: '#efefef'
  },
  heading: {
    fontWeight: 'bolder',
    display: 'block'
  },
  fullWidth: {
    width: '100%'
  },
  referral: {
    color: "#d3d3d3",
    padding: '0px 5px 10px 5px',
    paddingLeft: 0,
  },
  previous: {
    float: 'right',
    color: ' #20b4e4',
    border: '1px solid #20b4e4!important',
    boxShadow: '0 6px 10px -6px #000',
    backgroundColor: '#fff !important',
    padding: 9,
    width: 100,
    '&:hover': {
      color: ' #20b4e4',
      border: '1px solid #20b4e4!important',
      boxShadow: '0 6px 10px -6px #000',
    },
  },
  root: {
    padding: '6px 15px',
    color: 'black',
    backgroundColor: '#efefef'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
  subtitle1: {
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10
  },
  continue: {
    color: 'white !important',
    backgroundColor: '#1a04ec!important',
    border: ' 4px solid #1a04ec!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: 'white',
      background: '#20b4e4',
      backgroundColor: '#1a04ec!important',
      border: ' 4px solid #1a04ec!important',
    },
  },
  actionBar: {
    float: 'right',
  },
  selectedGuest: {
    border: '1px solid #497049',
    backgroundColor: '#c7d9be!important',
  },
  customInput: {},
  customText: {
    margin: 5,
    fontSize: 'inherit',
  },
  customGuest: {
    width: 50,
    margin: 5,
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:-webkit-inner-spin-button': {
      opacity: 1,
    },
    '&:-webkit-outer-spin-button': {
      opacity: 1,
    },
  },
  selectedCustomGuest: {
    width: 50,
    margin: 5,
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:-webkit-inner-spin-button': {
      opacity: 1,
    },
    '&:-webkit-outer-spin-button': {
      opacity: 1,
    },
  },
  bold: {
    fontWeight: 'bold',
    color: 'black'
  },
  boldtitle: {
    fontSize: 16,
    fontWeight: 'bolder'
  },
  rate: {
    textAlign: 'center',
    border: '1px solid black',
    borderRadius: 5,
    padding: 5,
    margin: 5
  },
  disabled: {
    backgroundColor: '#d3d3d3!important',
    color: 'black !important',
    opacity: 0.65,
    cursor: 'not-allowed'
  },
  selected: {
    backgroundColor: '#1a691a'
  },
  media: {
    height: 200,
  },
  rateCardContainer: {
    border: '1px solid #c7cbce',
    backgroundColor: 'white'
  },
  detailsContainer: {
    padding: '0px 15px'
  },
  description: {
    border: '1px solid',
    height: 50,
    overflowY: 'auto',
    padding: '0 8px',
    background: 'white',
  },
  instantBook: {
    fontWeight: 600,
    padding: '5px 20px',
    color: 'white !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: '#086edc !important',
    border: ' 4px solid #086edc !important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: 'white !important',
      backgroundColor: '#086edc !important',
      border: ' 4px solid #086edc !important',
    },
    textTransform: 'unset',
    marginBottom: 10,
    '&:disabled': {
      opacity: 0.65,
      cursor: 'not-allowed'
    },
    width: '100%'
  },
  disabledInstantBook: {
    fontWeight: 600,
    padding: '5px 20px',
    color: '#086edc !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
    border: ' 4px solid #086edc!important',
    boxShadow: '0 8px 10px -6px #000',
    '&:hover': {
      color: '#086edc !important',
      border: ' 4px solid #086edc !important',
    },
    textTransform: 'unset',
    marginBottom: 10,
    '&:disabled': {
      opacity: 0.65,
      cursor: 'not-allowed'
    },
    width: '100%'
  },
  instantBookContainer: {
    margin: '0 auto'
  },
  spacing: {
    padding: 5
  },
  spacing1: {
    padding: 0,
    paddingTop: 5,
    textAlign: 'center',
    margin: 'auto 0'
  },
  price: {
    fontSize: 28,
  },
  selected: {
  },
  separator: {
    marginTop: 10,
    borderTop: '1px solid',
    display: 'block'
  },
  flex: {
    display: 'flex'
  },
  boat: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  opacity: {
    opacity: 0.65
  },
  showMoreLess: {
    fontSize: 13,
    fontWeight: 600,
    cursor: 'pointer'
  },
  notAvailableText: {
    marginBottom: 10,
    fontSize: 14,
    color: '#fff',
    padding: '8px 20px',
    backgroundColor: '#6c757e',
    'border-bottom-left-radius': 10,
  },
  availableText: {
    backgroundColor: '#4a5a3f',
  },
  clamp: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
  },
  readMore: {
    'justify-content': 'flex-end',
    display: 'flex',
    fontSize: 13,
    fontWeight: 600,
    color: 'black',
    cursor: 'pointer'
  },
  disclaimer: {
    fontSize: 10,
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  colorBorder: {
    'border-top': '1px solid',
    'border-bottom': '1px solid',
    'background-color': '#a3eca3'
  },
  calendarModal: {
    width: 300,
    margin: '10% auto',
    borderRadius: 5,
    paddingBottom: 5,
    '&:focus': {
      outline: 'none'
    }
  },
  dayPicker: {
    height: 250
  },
  reverse: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  closeContainer: {
    padding: 10,
    borderBottom: `1px solid grey`
  },
  chooseTripDate: {
    color: '#1a04ec',
    fontSize: '1rem'
  },
  blue: {
    color: '#1a04ec',
  },
  tooltip: {
    fontSize: 16
  },
  redBoundary: {
    color: 'red',
    marginTop: 10,
    marginLeft: 10,
    borderRadius: 5,
    border: `1px solid red`,
    padding: 10,
    width: '100%'
  },
  tripcalculator: {
    width: '100%',
    left: 0,
    zIndex: 9,
    position: 'fixed',
    top: 60
  },
  btnClear: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

